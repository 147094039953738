import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TabContext from '@material-ui/lab/TabContext';
import React, { useState } from 'react';
import ShopDetailPropertyAppBar from './ShopDetailPropertyAppBar';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tabStyle: {
        color: 'black',
    },
}));

export default function ShopDetailPropertyInfo({ shopInfo }) {
    const classes = useStyles();
    const [value, setValue] = useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid item xs={12}>
                <div className={classes.rootTab}>
                    <TabContext value={value}>
                        <ShopDetailPropertyAppBar
                            shopInfo={shopInfo}
                            handleChange={handleChange}
                            classes={classes}
                        />

                        {/* <ShopDetailPropertyHome shopDetails={shopInfo} value="1" />
                        <ShopDetailPropertyReview value="2" />
                        <ShopDetailPropertyAbout classes={classes} value="3" /> */}
                    </TabContext>
                </div>
            </Grid>
        </>
    );
}
