import {
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const creatingUser = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_CUSTOMER_CREATE.replace(':shopId', params.shopId), params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};

export default function CustomerCreateModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const theme = useTheme();
    const history = useHistory();
    const { shopId } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    const handleValue = (event) => {
        if (event.target.name === 'name') {
            setName(event.target.value);
        }
        if (event.target.name === 'email') {
            setEmail(event.target.value);
        }
        if (event.target.name === 'contactNumber') {
            setContactNumber(event.target.value);
        }
        if (event.target.name === 'password') {
            setPassword(event.target.value);
        }
        if (event.target.name === 'rePassword') {
            setRePassword(event.target.value);
        }
    };
    const dispatch = useDispatch();

    const handleNextClick = () => {
        creatingUser({ name, email, contactNumber, password, rePassword, shopId })
            .then((response) => {
                // DataErrors(response, dispatch);
                handelSnackbarOpen(dispatch, response.data.message);

                if (response.data.error === 0) {
                    history.push(exiturl);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal
            {...props}
            className={['app-style', deviceClassName].join(' ')}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Create New Customer"
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Create a new customer
                        </Typography>

                        <TextField
                            required
                            label="Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the name"
                            value={name}
                            onChange={handleValue}
                            name="name"
                            style={{ marginTop: 10 }}
                        />

                        <TextField
                            required
                            label="Email"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the email"
                            value={email}
                            onChange={handleValue}
                            name="email"
                            style={{ marginTop: 10 }}
                        />

                        <TextField
                            required
                            label="Contact No"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the contact number"
                            value={contactNumber}
                            onChange={handleValue}
                            name="contactNumber"
                            style={{ marginTop: 10 }}
                        />

                        <TextField
                            required
                            variant="outlined"
                            label="Password"
                            fullWidth
                            size="small"
                            helperText="Please enter password"
                            value={password}
                            onChange={handleValue}
                            type="password"
                            name="password"
                            style={{ marginTop: 10 }}
                        />

                        <TextField
                            required
                            label="Re-Password"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please re-type password"
                            value={rePassword}
                            onChange={handleValue}
                            name="rePassword"
                            type="password"
                            style={{ marginTop: 10 }}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={rePassword}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
