import { makeStyles, Switch } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import SettingManagePaymentSection from '../section/SettingManagePaymentSection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

function HeaderSection({ shopId, proData, handleConfigProduction }) {
    return (
        <>
            <div style={{ overflow: 'hidden' }}>
                <Link to={`/${shopId}/settings/notification/`} />

                <div className="page-common-title">
                    Payment Setting
                    <span style={{ float: 'right' }}>
                        Production
                        <Switch
                            checked={proData === true ? 1 : 0}
                            onChange={handleConfigProduction}
                            color="primary"
                            name="checkedB"
                            value={proData}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </span>
                </div>
                <BreadcrumbsCommon
                    options={[
                        {
                            href: `/${shopId}/settings/payment`,
                            title: 'Payment Setting',
                        },
                    ]}
                />
            </div>
        </>
    );
}

const getPaymentConfigData = ({ shopId }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SETTING_PAYMENT.replace(':shopId', shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(
    dispatch,
    shopId,
    setPaymentConfigAr,
    setCurrencyConfigAr,
    setGetWayListAr,
    setOfflineGetWayListAr
) {
    try {
        await AppInitDispatch(dispatch, shopId);

        const response = await getPaymentConfigData({ shopId });
        setPaymentConfigAr(response.data.paymentConfig_ar);
        setCurrencyConfigAr(response.data.currencyConfig_ar);
        setGetWayListAr(response.data.paymentGateWayList);
        setOfflineGetWayListAr(response.data.paymentOfflineGateWayList);
    } catch (err) {
        console.log(err);
    }
}
export default function SettingManagePaymentPage() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();
    const [paymentConfigAr, setPaymentConfigAr] = useState([]);
    const [currencyConfigAr, setCurrencyConfigAr] = useState([]);
    const [getWayListAr, setGetWayListAr] = useState([]);
    const [offlineGetWayListAr, setOfflineGetWayListAr] = useState([]);
    const [proData, setProData] = useState(shopInfo?.payment_mode);

    const handleConfigProduction = () => {
        AxiosAuth.post(ConfigApi.API_SETTING_PAYMENT_CONFIG_PRO.replace(':shopId', shopId), {
            newVal: !proData,
        })
            .then(() => {
                setProData(!proData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // --Page Build
    useEffect(() => {
        if (shopInfo) {
            setProData(shopInfo?.payment_mode);
        }
        // Collect API Data
        initData(
            dispatch,
            shopId,
            setPaymentConfigAr,
            setCurrencyConfigAr,
            setGetWayListAr,
            setOfflineGetWayListAr
        );
    }, [dispatch, shopId, shopInfo]);
    return (
        <Preview placeholderModel="table" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <HeaderSection
                        shopId={shopId}
                        data={paymentConfigAr?.payment_config?.value}
                        handleConfigProduction={handleConfigProduction}
                        proData={proData}
                        setProData={setProData}
                    />
                    <SettingManagePaymentSection
                        paymentConfigAr={paymentConfigAr}
                        shopId={shopId}
                        setPaymentConfigAr={setPaymentConfigAr}
                        currencyConfigAr={currencyConfigAr || []}
                        setCurrencyConfigAr={setCurrencyConfigAr}
                        getWayListAr={getWayListAr}
                        offlineGetWayListAr={offlineGetWayListAr}
                    />
                </MainSection>
            </div>
        </Preview>
    );
}
