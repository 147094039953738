import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import shopCover from '../../../../../assets/images/shop-cover.jpg';
import CampaignModalUpdateMenu from '../../modals/comp/CampaignModalUpdateMenu';

export default function CampaignDetailOverViewInfo({ campaignDetail }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Grid item xs={12}>
                <div className="shop-detail-cover">
                    <img
                        src={campaignDetail.img_url ? campaignDetail.img_url : shopCover}
                        alt="Shop Cover"
                    />
                </div>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <div className="shop-detail-title" style={{ position: 'unset' }}>
                    <div>
                        <h6>{campaignDetail.campaign_title}</h6>
                        <p>
                            Duration:{campaignDetail.time_start} to {campaignDetail.time_end}
                        </p>
                    </div>
                    <div className="shop-detail-title-menu">
                        <IconButton
                            aria-label="delete"
                            style={{ float: 'right' }}
                            onClick={handleClick}
                            color="primary"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <CampaignModalUpdateMenu
                            anchor={anchorEl}
                            openMenu={open}
                            handleClose={handleClose}
                            id={campaignDetail.id}
                        />
                    </div>
                </div>
            </Grid>
        </>
    );
}
