import { TextField, useTheme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const currencies = [
    {
        value: 'USD',
    },
    {
        value: 'BDT',
    },
];

const languages = [{ value: 'bn-BD' }];
const countries = [{ value: 'BD' }];

const updateShopTitle = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_SHOP_UPDATE_LOCALE.replace(':shopId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function ShopLocaleSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const { shopInfo } = InitInfo();

    const dispatch = useDispatch();
    const history = useHistory();
    const [currency, setCurrency] = useState(shopInfo.locale.currency);
    const [country, setCountry] = useState(shopInfo.locale.country);
    const [language, setLanguage] = useState(shopInfo.locale.language);

    const handleCurrency = (event) => {
        setCurrency(event.target.value);
    };
    const handleCountry = (event) => {
        setCountry(event.target.value);
    };

    const handleLanguage = (event) => {
        setLanguage(event.target.value);
    };
    const handleNextClick = () => {
        // console.dir(next);
        updateShopTitle(
            {
                country,
                currency,
                language,
                id: shopInfo.id,
            },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    history.push(exiturl);
                }
            }
        );
    };
    useEffect(() => {}, [shopInfo]);
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={shopInfo.shop_name}
                    />
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">Update your shop Locale.</h4>
                        <TextField
                            required
                            label="Locale Country"
                            variant="outlined"
                            fullWidth
                            select
                            size="small"
                            helperText="Please enter Locale Country"
                            value={country}
                            onChange={handleCountry}
                            className="mb-3"
                        >
                            {countries.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Select"
                            value={currency}
                            onChange={handleCurrency}
                            fullWidth
                            size="small"
                            helperText="Please select your currency"
                            variant="outlined"
                            className="mb-3"
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            required
                            select
                            label="Locale Language"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter Locale Language"
                            value={language}
                            onChange={handleLanguage}
                        >
                            {languages.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data="1"
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
