import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updatingDetailProduct = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_DES_UPDATE.replace(':productId', params.productId),
            params
        )
            .then((response) => {
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        product_description: params.product_description,
                    });
                }

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

export default function ProductDescriptionSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { shopInfo, productId, productDetails } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [descriptionValue, setDescriptionValue] = useState(productDetails.product_description);

    const handleNextClick = () => {
        // console.dir(next);
        updatingDetailProduct(dispatch, {
            product_description: descriptionValue,
            shopId: shopInfo.id,
            productId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    history.push(next);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <h4 className="mb-2 mt-2 field-title">Product Description</h4>

                        <CKEditor
                            editor={ClassicEditor}
                            data={descriptionValue}
                            config={{
                                ckfinder: {
                                    uploadUrl:
                                        'https://store-api.bikiran.com/manage/images/upload/description',
                                    options: {
                                        resourceType: 'Images',
                                        disableObjectResizing: true,
                                    },
                                },
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescriptionValue(data);
                            }}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={descriptionValue}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
