import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import RewardProductUpdateModal from '../modals/RewardProductUpdateModal';

export default function RewardProductUpdateModals({ rewardproductData }) {
    const location = useLocation();
    const { shopId } = InitInfo();
    const history = useHistory();

    const rewardId = history.location.pathname.replace(/[^0-9]/g, '');
    const productRewardPercent = rewardproductData.filter(
        (e) => e.sl.toString() === rewardId.toString()
    )[0];
    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopId}/reward/`}>
            <RewardProductUpdateModal
                productRewardPercent={productRewardPercent}
                rewardId={rewardId}
                path={`/${shopId}/reward/${rewardId}/product/update`}
            />
        </StepHandler>
    );
}
