import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function MainSection({ children }) {
    const classes = useStyles();
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />

            {children}
        </main>
    );
}
