const initialState = {
    // id: 10000634,
    // title: "Men's Shirt-2",
    // unit: 1,
    // unit_type: 'pcs',
    // price_sale: 750,
    // price_purchase: 750,
    // mrp: 750,
    // vat: 0,
    // tax: 0,
    // product_detail: '',
    // default_image: '',
    // variation_header: null,
    // is_published: false,
    // status: 'active',
    // time_created: 0,
    // categories: [],
    // variation: [],
    // images: [],
};

const ProductDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'PRODUCT_DETAILS_SET': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default ProductDetailsReducer;
