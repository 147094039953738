import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import '../../../../assets/css/modal-app-style.css';
import '../../../../assets/css/style.css';
import ShopDetailOverViewInfo from './shopDetail/ShopDetailOverViewInfo';
import ShopDetailPropertyInfo from './shopDetail/ShopDetailPropertyInfo';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function ShopDetailSection() {
    const classes = useStyles();
    const shopInfo = useSelector((state) => state.shopData.shopInfo);

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <ShopDetailOverViewInfo shopInfo={shopInfo} />
                <ShopDetailPropertyInfo shopInfo={shopInfo} />
            </Grid>
        </div>
    );
}
