import { Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import React, { useState } from 'react';
import Barcode from 'react-barcode';
import Optimizee from '../../../utils/Optimizee';
import ProductOrderVariant from '../comps/ProductOrderVariant';
import OrderPaymentModal from '../modals/OrderPaymentModal';

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
    },
    tableContainer: {
        overflow: 'hidden',
        padding: '10px',
        position: 'relative',
        zIndex: 0,
        boxShadow: 'none',
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
}));

function TableData({ rowData, variation }) {
    const classes = useStyles();
    return (
        <TableRow key={rowData.id} className={[classes.tableRowHover, 'tableTr '].join(' ')}>
            <TableCell style={{ width: '96px' }}>
                <img
                    className={classes.image}
                    src={Optimizee(rowData?.product_image, 100)}
                    alt={rowData?.product_title}
                />
            </TableCell>
            <TableCell align="left">
                <b> {rowData.product_title}</b>
                <br />
                <ul className="variant-list">
                    <ProductOrderVariant
                        data={Object.values(variation).slice(0, Object.keys(variation).length - 2)}
                    />
                </ul>
            </TableCell>
            <TableCell align="center">{rowData.quantity}</TableCell>
            <TableCell align="center">{rowData.price}</TableCell>
            <TableCell align="center" width="10">
                {rowData.price * rowData.quantity}
            </TableCell>
        </TableRow>
    );
}

export default function OrderManageDetailSection({ memoAr, memoItemAr, shopId, paymentConfig }) {
    const classes = useStyles();
    let totalAmount = 0;
    let totalAmount2 = 0;
    const [modalShow, setModalShow] = useState(false);

    const paymentStatus = memoAr?.payment_status;
    // if (memoAr.net_amount < memoAr.paidAmount && memoAr.paidAmount !== 0) {
    //     paymentStatus = 'Paid';
    // } else if (memoAr.net_amount > memoAr.paidAmount && memoAr.paidAmount !== 0) {
    //     paymentStatus = 'Partial Paid';
    // } else {
    //     paymentStatus = 'Not Paid';
    // }
    const shippingAddressAr = memoAr?.address_shipping_json;
    const billingAddressAr = memoAr?.address_billing_json;
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 invoice-head">
                        <h5 className="order-copy">Office Copy</h5>
                        <h3>DETAIL</h3>
                    </div>
                    <div className="col-12">
                        <button
                            className="invoice-print-btn"
                            type="button"
                            onClick={(ev) => {
                                ev.preventDefault();
                                window.print();
                            }}
                        >
                            <LocalPrintshopIcon />
                        </button>

                        <div className="order-detail-section-order-no">
                            <button type="button">{paymentStatus?.toUpperCase()}</button>
                            <br />
                            <h6>INV-{memoAr?.id}</h6>
                        </div>
                        {paymentStatus === 'due' ? (
                            <div className="order-detail-section-paid">
                                <button type="button" onClick={() => setModalShow(true)}>
                                    Paid Now
                                </button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="col-12 col-lg-6 col-print-6">
                        <div className="order-detail-section-title">
                            <h3>INVOICE FROM</h3>
                            <p>{memoAr?.shop_name}</p>
                            <p>{memoAr?.shop_address}</p>
                            <p>{memoAr?.shop_contact}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-print-3">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO (Shipping)</h3>
                            {memoAr?.address_shipping_name},<br />
                            {memoAr?.address_shipping_phone}, <br />
                            {memoAr?.address_shipping_email},<br />
                            {shippingAddressAr?.line1},<br />
                            {shippingAddressAr?.line2},<br />
                            {shippingAddressAr?.city},{shippingAddressAr?.state}
                            {shippingAddressAr?.zip}, {shippingAddressAr?.country}
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-print-3">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO (Billing)</h3>
                            {memoAr?.address_billing_name},<br />
                            {memoAr?.address_billing_phone}, <br />
                            {memoAr?.address_billing_email},<br />
                            {billingAddressAr?.line1},<br />
                            {billingAddressAr?.line2},<br />
                            {billingAddressAr?.city},{billingAddressAr?.state}
                            {billingAddressAr?.zip}, {billingAddressAr?.country}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="invoice-watermark" />
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tableTr">
                                        <TableCell align="center">#</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="center">Qty</TableCell>
                                        <TableCell align="center">Unit Price</TableCell>
                                        <TableCell align="center">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {memoItemAr?.map((row) => {
                                        totalAmount += row.price * row.quantity;
                                        return (
                                            <TableData
                                                qty={row.quantity}
                                                key={row.id}
                                                rowData={row}
                                                variation={row.product_variation_json}
                                            />
                                        );
                                    })}
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center">{totalAmount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Discount
                                        </TableCell>
                                        <TableCell align="center">
                                            {memoAr.total_product_discount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Shipping
                                        </TableCell>
                                        <TableCell align="center">
                                            {memoAr.shipping_charge}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">
                                            {parseInt(totalAmount, 10) +
                                                memoAr.shipping_charge -
                                                parseInt(memoAr.total_product_discount, 10)}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        {/* <div className="order-detail-section-print-btn">
                            <Link to="/print" target="_blank">
                                <h6>
                                    <VisibilityIcon style={{ fontSize: '18px' }} /> Preview
                                </h6>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </Card>
            {/* Customer Copy               */}
            <Card className="order-customer-copy mt-5">
                <div className="row">
                    <div className="col-12 invoice-head">
                        <h5 className="order-copy">Customer Copy</h5>
                        <h3>DETAIL</h3>
                    </div>
                    <div className="col-12">
                        <div className="order-detail-section-order-no">
                            <button type="button">{paymentStatus?.toUpperCase()}</button>
                            <br />
                            <h6>INV-{memoAr?.id}</h6>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-print-6">
                        <div className="order-detail-section-title">
                            <h3>INVOICE FROM</h3>
                            <p>{memoAr?.shop_name}</p>
                            <p>{memoAr?.shop_address}</p>
                            <p>{memoAr?.shop_contact}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-print-3">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO (Shipping)</h3>
                            {memoAr?.address_shipping_name},<br />
                            {memoAr?.address_shipping_phone}, <br />
                            {memoAr?.address_shipping_email},<br />
                            {shippingAddressAr?.line1},<br />
                            {shippingAddressAr?.line2},<br />
                            {shippingAddressAr?.city},{shippingAddressAr?.state}
                            {shippingAddressAr?.zip}, {shippingAddressAr?.country}
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-print-3">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO (Billing)</h3>
                            {memoAr?.address_billing_name},<br />
                            {memoAr?.address_billing_phone}, <br />
                            {memoAr?.address_billing_email},<br />
                            {billingAddressAr?.line1},<br />
                            {billingAddressAr?.line2},<br />
                            {billingAddressAr?.city},{billingAddressAr?.state}
                            {billingAddressAr?.zip}, {billingAddressAr?.country}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="invoice-watermark" />
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tableTr">
                                        <TableCell align="center">#</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell align="center">Qty</TableCell>
                                        <TableCell align="center">Unit Price</TableCell>
                                        <TableCell align="center">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {memoItemAr?.map((row) => {
                                        totalAmount2 += row.price * row.quantity;
                                        return (
                                            <TableData
                                                qty={row.quantity}
                                                key={row.id}
                                                rowData={row}
                                                variation={row.product_variation_json}
                                            />
                                        );
                                    })}
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center">{totalAmount2}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Discount
                                        </TableCell>
                                        <TableCell align="center">
                                            {memoAr.total_product_discount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Shipping
                                        </TableCell>
                                        <TableCell align="center">
                                            {memoAr.shipping_charge}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="4" align="right">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">
                                            {totalAmount2 +
                                                memoAr.shipping_charge -
                                                memoAr.total_product_discount}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        {/* <div className="order-detail-section-print-btn">
                            <Link to="/print" target="_blank">
                                <h6>
                                    <VisibilityIcon style={{ fontSize: '18px' }} /> Preview
                                </h6>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </Card>
            {/* Courier Copy               */}
            <Card className="order-customer-copy mt-5">
                <div className="row">
                    <div className="col-12 invoice-head mb-4">
                        \ <h3>Courier Copy</h3>
                    </div>

                    <div className="col-12 col-lg-6 col-print-6">
                        <div className="order-detail-section-title">
                            <h3>INVOICE FROM</h3>
                            <p>{memoAr?.shop_name}</p>
                            <p>{memoAr?.shop_address}</p>
                            <p>{memoAr?.shop_contact}</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-print-3">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO (Shipping)</h3>
                            {memoAr?.address_shipping_name},<br />
                            {memoAr?.address_shipping_phone}, <br />
                            {memoAr?.address_shipping_email},<br />
                            {shippingAddressAr?.line1},<br />
                            {shippingAddressAr?.line2},<br />
                            {shippingAddressAr?.city},{shippingAddressAr?.state}
                            {shippingAddressAr?.zip}, {shippingAddressAr?.country}
                        </div>
                    </div>
                    <div className="col-12 col-lg-3 col-print-3">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO (Billing)</h3>
                            {memoAr?.address_billing_name},<br />
                            {memoAr?.address_billing_phone}, <br />
                            {memoAr?.address_billing_email},<br />
                            {billingAddressAr?.line1},<br />
                            {billingAddressAr?.line2},<br />
                            {billingAddressAr?.city},{billingAddressAr?.state}
                            {billingAddressAr?.zip}, {billingAddressAr?.country}
                        </div>
                    </div>
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Barcode value={memoAr?.id} displayValue={false} textAlign="center" />
                    </div>
                </div>
            </Card>
            <OrderPaymentModal
                modalShow={modalShow}
                handleModal={setModalShow}
                memoData={memoAr}
                memoItem={memoItemAr}
                shopId={shopId}
                paymentConfig={paymentConfig}
            />
        </>
    );
}
