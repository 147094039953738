import { Card, CardContent, TextField } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import InitInfo from '../../../utils/InitInfo';

export default function SettingManageAccountPage() {
    const { userInfo } = InitInfo();
    const refName = useRef();
    const refPhone = useRef();
    const refEmail = useRef();

    useEffect(() => {
        if (userInfo) {
            refName.current.value = userInfo?.name || '';
            refPhone.current.value = userInfo?.default_contact || '';
            refEmail.current.value = userInfo?.default_email || '';
        }
        // Collect API Data
    }, [userInfo]);
    return (
        <div className="row">
            <div className="col-12">
                <h5>Account Information</h5>
            </div>
            <div className="col-12 col-lg-4">
                <Card>
                    <CardContent>
                        <h6 className="mb-2 mt-2 field-title">Name</h6>
                        <TextField
                            required
                            label="Name"
                            inputRef={refName}
                            variant="outlined"
                            fullWidth
                        />
                        <h6 className="mb-2 mt-2 field-title">Email</h6>
                        <TextField
                            inputRef={refEmail}
                            required
                            label="Email"
                            variant="outlined"
                            fullWidth
                        />
                        <h6 className="mb-2 mt-2 field-title">Contact</h6>
                        <TextField
                            inputRef={refPhone}
                            required
                            label="Contact"
                            variant="outlined"
                            fullWidth
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
