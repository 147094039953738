import { Card } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import Optimizee from '../../../../utils/Optimizee';
import './print.css';

const data = [];

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
    },
    tableContainer: {
        overflow: 'hidden',
        padding: '10px',
        position: 'relative',
        zIndex: 0,
        boxShadow: 'none',
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
}));
export default function OrderInvoicePrintPdf() {
    const classes = useStyles();
    return (
        <div className="container">
            <Card>
                <div className="row">
                    <div className="col-12 invoice-head">
                        <h3>INVOICE</h3>
                    </div>
                    <div className="col-6">
                        <div className="invoice-print-section">
                            <span
                                className="invoice-print-btn"
                                onClick={() => window.print()}
                                role="button"
                                tabIndex={-1}
                            >
                                Print
                            </span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="order-detail-section-order-no">
                            <span>Paid</span>
                            <br />
                            <h6>INV-49d9d551-cf6a-4ffe-9121-843d0f76e270</h6>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="order-detail-section-title">
                            <h3>INVOICE FROM</h3>
                            <p>Rickey Jaskolski</p>
                            <p>DieSachbearbeiter Choriner Straße 49 10435 Berlin</p>
                            <p>Phone: 583-476-6615</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="order-detail-section-title">
                            <h3>INVOICE TO</h3>
                            <p>Rickey Jaskolski</p>
                            <p>DieSachbearbeiter Choriner Straße 49 10435 Berlin</p>
                            <p>Phone: 583-476-6615</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className="tableTr">
                                        <TableCell align="center">#</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell />
                                        <TableCell align="center">Qty</TableCell>
                                        <TableCell align="center">Unit Price</TableCell>
                                        <TableCell align="center">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.slice(0, 3).map((row) => (
                                        <TableRow
                                            key={row.id}
                                            className={[classes.tableRowHover, 'tableTr '].join(
                                                ' '
                                            )}
                                        >
                                            <TableCell align="center">{row.id}</TableCell>
                                            <TableCell style={{ width: '96px' }}>
                                                <img
                                                    className={classes.image}
                                                    src={Optimizee(row.imgUrl, 100)}
                                                    alt={row.title}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <b> {row.title}</b>
                                                <p style={{ fontSize: '10px' }}>SUK {row.id}</p>
                                            </TableCell>
                                            <TableCell align="center">2</TableCell>
                                            <TableCell align="center">{row.price}</TableCell>
                                            <TableCell align="center" width="10">
                                                {row.price * 2}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center">9000.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Discount
                                        </TableCell>
                                        <TableCell align="center">00.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Shipping
                                        </TableCell>
                                        <TableCell align="center">00.00</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan="5" align="right">
                                            Total
                                        </TableCell>
                                        <TableCell align="center">9000.00</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </Card>
        </div>
    );
}
