import {
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(1),
    },
}));
const updatingUser = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SETTING_USER_UPDATE_ROLE.replace(':shopId', params.shopId).replace(
                ':userId',
                params.userId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};

const getUserData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(
            ConfigApi.API_SETTING_USER_DATA.replace(':shopId', params.shopId).replace(
                ':userId',
                params.userId
            )
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
export default function UserUpdateRoleModal(props) {
    const classes = useStyles();

    const { prev, next, step, paths, exiturl } = props;
    const theme = useTheme();
    const history = useHistory();
    const { shopId, shopData } = InitInfo();
    const userId = history.location.pathname.replace(/[^0-9]/g, '');

    const deviceClassName = `device-${deviceSize(theme)}`;

    const [role, setRole] = useState('');

    const handleValue = (event) => {
        if (event.target.name === 'role') {
            setRole(event.target.value);
        }
    };
    const dispatch = useDispatch();

    const handleNextClick = () => {
        updatingUser({
            role,
            shopId,
            userId,
        })
            .then((response) => {
                // DataErrors(response, dispatch);
                handelSnackbarOpen(dispatch, response.data.message);

                if (response.data.error === 0) {
                    history.push(exiturl);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getUserData({ shopId, userId })
            .then((response) => {
                setRole(response.data.permission);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [shopId, userId]);

    return (
        <Modal
            {...props}
            className={['app-style', deviceClassName].join(' ')}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Update User Password"
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Role
                        </Typography>
                        <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formControl}
                        >
                            <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                            <Select
                                native
                                label="Role"
                                inputProps={{
                                    name: 'role',
                                    id: 'outlined-age-native-simple',
                                }}
                                onChange={handleValue}
                                value={role}
                            >
                                {Object.keys(shopData.permissions).map((value) => (
                                    <option value={value}>{shopData.permissions[value]}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={role}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
