import { AppBar, Button, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { ArrowBack, Close } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../../../../assets/css/modal-app-style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    spacing: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export function ProductModalHeader(props) {
    const { prev, step, exiturl, title } = props;
    const classes = useStyles();

    return (
        <div className="app-style-header">
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar>
                    <Link to={prev || exiturl}>
                        <IconButton edge="start" color="inherit" className={classes.spacing}>
                            <ArrowBack />
                        </IconButton>
                    </Link>

                    <Typography variant="h6" className={classes.title} noWrap>
                        {title || `Step - ${step + 1}`}
                    </Typography>

                    <Link to={exiturl}>
                        <IconButton color="inherit">
                            <Close />
                        </IconButton>
                    </Link>
                </Toolbar>
            </AppBar>
        </div>
    );
}
export function ProductModalFooter(props) {
    const { next, paths, step, data = [], skip = false, handleNextClick } = props;

    const btnNextClassName = [
        'modal-footer-btn',
        data.length > 0 ? 'modal-footer-btn-fill' : 'disabled-link',
    ].join(' ');

    return (
        <div className="app-style-footer">
            {skip === true && next && (
                <Link to={next} className="chip-style">
                    <Chip
                        clickable
                        size="small"
                        label="Skip"
                        variant="outlined"
                        color="secondary"
                    />
                </Link>
            )}
            <Button
                className={btnNextClassName}
                onClick={(event) => {
                    handleNextClick(event, next);
                }}
            >
                {next ? 'Next' : 'Finish'}
            </Button>
            <div className="modal-Stepper">
                {paths.map((item, j) => (
                    <div key={item} className={j <= step ? 'fill' : ''} />
                ))}
            </div>
        </div>
    );
}
