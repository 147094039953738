const initialState = false;

const NavDrawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DRAWER_TOGGLE': {
            const prevState = state;
            return !prevState;
        }
        case 'DRAWER_OPEN': {
            return true;
        }
        case 'DRAWER_CLOSE': {
            return false;
        }
        default:
            return state;
    }
};

export default NavDrawerReducer;
