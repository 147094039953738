import { TextField, useTheme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const yesNo = [
    {
        value: 1,
        label: 'YES',
    },
    {
        value: 0,
        label: 'NO',
    },
];

const updateShopLive = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_SHOP_UPDATE_LIVE.replace(':shopId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function ShopLiveSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const { shopInfo } = InitInfo();

    const dispatch = useDispatch();
    const history = useHistory();
    const [isLive, setIsLive] = useState(shopInfo.isLive?.value);
    const [message, setMessage] = useState(shopInfo.isLive?.message);
    const [password, setPassword] = useState('');

    const handleLive = (event) => {
        setIsLive(event.target.value);
        if (event.target.value === 1) {
            setMessage('');
        } else {
            setMessage('We are coming soon');
        }
    };
    const handleMsg = (event) => {
        setMessage(event.target.value);
    };
    const handlePass = (event) => {
        setPassword(event.target.value);
    };
    const handleNextClick = () => {
        // console.dir(next);
        updateShopLive(
            {
                message,
                isLive,
                password,
                id: shopInfo.id,
            },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    history.push(exiturl);
                }
            }
        );
    };
    useEffect(() => {}, [shopInfo]);
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={shopInfo.shop_name}
                    />
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">Update your shop Live.</h4>
                        <TextField
                            required
                            label="Is Live"
                            variant="outlined"
                            fullWidth
                            select
                            size="small"
                            helperText="Please enter Live"
                            value={isLive}
                            onChange={handleLive}
                            className="mb-3"
                        >
                            {yesNo.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {isLive === 0 ? (
                            <>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Message"
                                    value={message}
                                    onChange={handleMsg}
                                    fullWidth
                                    size="small"
                                    helperText="Please type message"
                                    variant="outlined"
                                    className="mb-3"
                                    multiline
                                    rows={4}
                                />
                            </>
                        ) : (
                            ''
                        )}
                        <TextField
                            id="outlined-select-currency"
                            label="Password"
                            value={password}
                            onChange={handlePass}
                            fullWidth
                            size="small"
                            helperText="Please type password"
                            variant="outlined"
                            className="mb-3"
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data="1"
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
