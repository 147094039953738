import React from 'react';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

export default function ProductFeatureSection({
    categories,
    featureProducts,
    setSelectFProduct,
    setShowFModal,
    shopId,
    setFeatureProducts,
}) {
    const handleRemove = (featureId) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_FEATURE_REMOVE.replace(':shopId', shopId).replace(
                ':featureId',
                featureId
            )
        )
            .then((response) => {
                setFeatureProducts(response.data.featureProducts);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <table className="w-100 setting-payment">
            <thead>
                <tr>
                    <th>Category</th>
                    <th>Title</th>
                    <th>Image</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {featureProducts?.map((row) => {
                    const cate = categories?.filter(
                        (catRow) => catRow.categoryId === row.categoryId
                    )[0];
                    return (
                        <tr key={row.id}>
                            <td>{cate?.category_name}</td>
                            <td>{row.category_title}</td>
                            <td>
                                {row.image_url ? (
                                    <img width={100} src={row?.image_url} alt="" />
                                ) : (
                                    'No image'
                                )}
                            </td>
                            <td style={{ width: '15%' }}>
                                <button
                                    type="button"
                                    className="btn btn-xs -btn-primary"
                                    onClick={() => {
                                        setSelectFProduct(row);
                                        setShowFModal(true);
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-xs -btn-primary"
                                    onClick={() => {
                                        handleRemove(row.id);
                                    }}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}
