/* eslint-disable no-unused-vars */
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    Switch,
    TextField,

    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import { ProductModalFooter, ProductModalHeader } from '../modals/comp/ProductModalHeaderFooter';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const updateProductTitle = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCT_TITLE_UPDATE.replace(':productId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

function showVariation() {
    return (
        <ul>
            <li>
                <div className="variation">
                    <span />
                    <span>Color: </span>
                    <span>Red</span>
                </div>
            </li>
            <li>
                <div className="variation">
                    <span />
                    <span>Age: </span>
                    <span>1-2 Year</span>
                </div>
            </li>
            <li>
                <div className="variation">
                    <span />
                    <span>Size: </span>
                    <span>44</span>
                </div>
            </li>
        </ul>
    );
}

export default function ProductVariantSetModal(props) {
    const { variations, prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const classes = useStyles();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const shopInfo = useSelector((state) => state.shopInfo);
    const productDetails = useSelector((state) => state.productDetails);
    const [titleValue, setTitleValue] = useState(productDetails.title);
    const dispatch = useDispatch();
    const history = useHistory();
    const currentVariation = variations[step];

    const handleValue = (event) => {
        setTitleValue(event.target.value);
    };

    const handleSearch = (ev) => {
        console.log(ev);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updateProductTitle(
            { productTitle: titleValue, id: productDetails.id, shopId: shopInfo.id },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0 && response.data.id) {
                    // --Update Store
                    ProductDataUpdateDispatch(dispatch, {
                        title: titleValue,
                    });
                    history.push(next);
                }
            }
        );
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={titleValue}
                    />
                    <div className="app-style-body">
                        <List dense className={classes.root}>
                            <ListItem button>
                                <ListItemAvatar>
                                    <Avatar
                                        alt="Variation Image"
                                        src="/static/images/avatar/xyz.jpg"
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={showVariation()} />
                                <ListItemSecondaryAction>
                                    <Switch
                                        edge="end"
                                        inputProps={{
                                            'aria-labelledby': 'switch-list-label-bluetooth',
                                        }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>

                        <TextField
                            label="Search Variation"
                            variant="outlined"
                            fullWidth
                            type="number"
                            helperText="Set Price (Sale)"
                            onChange={handleSearch}
                            size="small"
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={titleValue}
                        handleNextClick={handleNextClick}
                        skip
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
