import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import CustomerUpdateModal from '../modals/CustomerUpdateModal';

export default function CustomerUpdateModals() {
    const location = useLocation();
    const { shopInfo } = InitInfo();
    const history = useHistory();
    const customId = history.location.pathname.replace(/[^0-9]/g, '');
    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/customers/manage/`}>
            <CustomerUpdateModal path={`/${shopInfo.id}/customers/manage/${customId}/update/`} />
        </StepHandler>
    );
}
