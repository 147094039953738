/* eslint-disable no-unreachable */
import {
    Card,
    CardActions,
    CardHeader,
    IconButton,

    // eslint-disable-next-line prettier/prettier
    Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import Optimizee from '../../../utils/Optimizee';

const useStyles = makeStyles((theme) => ({
    root: {},
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        objectFit: 'cover',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

function ProductView({ data, shopInfo, rewardId }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                className="product-card-header"
                title={
                    <Link to={`/${shopInfo.id}/products/${data.id}/details/`}>
                        <Typography
                            variant="body2"
                            style={{ fontSize: '15px', fontWeight: 'bold' }}
                            color="textSecondary"
                            component="p"
                        >
                            {data.title}
                        </Typography>
                    </Link>
                }
                subheader={data.total_price ? `Price:${data.total_price}` : ''}
            />

            <Link to={`/${shopInfo.id}/products/${data.id}/details/`}>
                <img className="product-card-image" src={Optimizee(data.default_image)} alt="" />
            </Link>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites" />
                <IconButton aria-label="add to favorites" />
                <IconButton aria-label="share">
                    <Link to={`/${shopInfo.id}/reward/${rewardId}/product/update`}>
                        <EditIcon />
                    </Link>{' '}
                </IconButton>
                <IconButton aria-label="share">
                    <Link to={`/${shopInfo.id}/products/${data.id}/details`}>
                        <OpenInNewIcon />
                    </Link>
                </IconButton>

                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

export default function StockProductListMobileView({
    rewardproductData,
    productData,
    categoryList,
}) {
    const { shopInfo } = InitInfo();

    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');
    return (
        <>
            {rewardproductData &&
                rewardproductData.map((reward) => {
                    const row = FindArr.find(reward.product_sl);
                    return (
                        <div
                            className="col-12 productMobile-card-col"
                            key={row.id}
                            style={{ marginTop: 10 }}
                        >
                            <ProductView
                                data={row}
                                categoryList={categoryList}
                                shopInfo={shopInfo}
                                rewardId={reward.sl}
                            />
                        </div>
                    );
                })}
        </>
    );
}
