import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import CampaignDataDispatch from '../../../dispatches/CampaignDataDispatch';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import CampaignDetailSection from '../section/CampaignDetailSection';
import CampaignManageHeaderSection from '../section/CampaignManageHeaderSection';
import CampaignUpdateModals from '../section/CampaignUpdateModals';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
async function initData(dispatch, shopId, id, seCampDetails) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await CampaignDataDispatch(id, dispatch);
        seCampDetails(response.data);
    } catch (err) {
        console.log(err);
    }
}
export default function CampaignDetailPage() {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();
    const { id } = useParams();
    const [campDetails, seCampDetails] = useState({});

    useEffect(() => {
        initData(dispatch, shopId, id, seCampDetails);
    }, [dispatch, history, shopId, id]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <CampaignManageHeaderSection shopId={shopId} />
                    <CampaignDetailSection campDetails={campDetails} />
                </MainSection>
                <CampaignUpdateModals />
            </div>
        </Preview>
    );
}
