import {
    Card,
    CardContent,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    // eslint-disable-next-line prettier/prettier
    TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import DetectDevice from '../../utils/DetectDevice';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    card: {
        maxWidth: '100%',
        margin: theme.spacing(2),
    },
    media: {
        height: 190,
    },
}));

function TablePlaceholder() {
    const classes = useStyles();

    return (
        <DetectDevice>
            <TableContainer className={classes.tableContainer} component={Paper} type="lg">
                <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow className="tableTr">
                            <TableCell width="30">
                                <Skeleton animation="wave" height={50} width={30} />
                            </TableCell>
                            <TableCell>
                                <Skeleton animation="wave" height={50} />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton animation="wave" height={50} />
                            </TableCell>
                            <TableCell width="15%" align="center">
                                <Skeleton animation="wave" height={50} />
                            </TableCell>
                            <TableCell width="15%" align="center">
                                <Skeleton animation="wave" height={50} />
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {[0, 1, 2, 3].map((index) => {
                            const key = `tbl-placeholder-${index}`;
                            return (
                                <TableRow
                                    key={key}
                                    className={[classes.tableRowHover, 'tableTr '].join(' ')}
                                >
                                    <TableCell align="center">
                                        <Skeleton animation="wave" height={50} width={30} />
                                    </TableCell>

                                    <TableCell align="center">
                                        <Skeleton animation="wave" height={50} />
                                    </TableCell>

                                    <TableCell align="center">
                                        <Skeleton animation="wave" height={50} />
                                    </TableCell>

                                    <TableCell align="center">
                                        <Skeleton animation="wave" height={50} />
                                    </TableCell>

                                    <TableCell align="center">
                                        <Skeleton animation="wave" height={50} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="row" type="xs|sm">
                <div className="col-12 productMobile-card-col">
                    <Card className={classes.card}>
                        <CardHeader
                            avatar={
                                <Skeleton
                                    animation="wave"
                                    variant="circle"
                                    width={40}
                                    height={40}
                                />
                            }
                            title={
                                <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                />
                            }
                            subheader={<Skeleton animation="wave" height={10} width="40%" />}
                        />
                        <Skeleton animation="wave" variant="rect" className={classes.media} />

                        <CardContent>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                        </CardContent>
                    </Card>
                </div>
            </div>
        </DetectDevice>
    );
}

export default TablePlaceholder;
