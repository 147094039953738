import React from 'react';
import bikiranLogo from '../../../../assets/images/bikiran-logo.png';

export default function NavDrawerHeader() {
    return (
        <div className="logo-area">
            <div className="typo-area">
                <img src={bikiranLogo} alt="Bikiran" className="logo-image" />
                <span className="logo-typo">BIKIRAN</span>
            </div>

            <div className="clearfix" />
            <div className="logo-slogan">Achieve your target</div>
        </div>
    );
}
