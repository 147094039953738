import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import ShippingConfigModal from '../modals/ShippingConfigModal';
import SettingShippingChargeSection from '../section/SettingShippingChargeSection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

function HeaderSection({ shopId, setShowModal }) {
    // console.log(data);
    return (
        <>
            <div style={{ overflow: 'hidden' }}>
                <Link to={`/${shopId}/settings/notification/`} />

                <div className="page-common-title">
                    Shipping Charge Setting
                    <button
                        type="button"
                        className="btn btn-xs btn-primary float-right"
                        onClick={() => setShowModal(true)}
                    >
                        Add New
                    </button>
                </div>
                <BreadcrumbsCommon
                    options={[
                        {
                            href: `/${shopId}/settings/shipping-charge`,
                            title: ' Shipping Charge Setting',
                        },
                    ]}
                />
            </div>
        </>
    );
}

const getShippingConfigData = ({ shopId }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SETTING_SHIPPING_CHARGE.replace(':shopId', shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, setShippingConfigAr) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getShippingConfigData({ shopId });
        setShippingConfigAr(response.data.shippingCharge_ar);
    } catch (err) {
        console.log(err);
    }
}
export default function SettingShippingChargePage() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();
    const [shippingConfigAr, setShippingConfigAr] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [updateData, setUpdateData] = useState([]);
    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, setShippingConfigAr);
    }, [dispatch, shopId]);

    return (
        <Preview placeholderModel="table" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <HeaderSection shopId={shopId} setShowModal={setShowModal} />
                    <SettingShippingChargeSection
                        shippingConfigAr={shippingConfigAr}
                        shopId={shopId}
                        setUpdateData={setUpdateData}
                        setShowModal={setShowModal}
                    />
                </MainSection>
                <ShippingConfigModal
                    shopId={shopId}
                    show={showModal}
                    setShow={setShowModal}
                    setShippingConfigAr={setShippingConfigAr}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                />
            </div>
        </Preview>
    );
}
