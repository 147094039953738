import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import PermissionCreateModal from '../modals/PermissionCreateModal';

export default function PermissionModal({ type }) {
    const location = useLocation();
    const { shopInfo } = InitInfo();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/settings/permission`}>
            <PermissionCreateModal
                type={type}
                path={`/${shopInfo.id}/settings/permission/create/`}
            />
        </StepHandler>
    );
}
