import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import ShopDetailSection from '../sections/ShopDetailSection';
import ShopUpdateModals from '../sections/ShopUpdateModals';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

// const fetchProductData = (params) =>
// new Params((resolve, reject) => {
//     AxiosAuth.get(ConfigApi.API_PRODUCTS, {
//         params,
//     })
//         .then((response) => {
//             resolve(response);
//         })
//         .catch((err) => {
//             reject(err);
//         });
// });

async function initData(dispatch, shopId, setInitOk) {
    try {
        const st = await AppInitDispatch(dispatch, shopId);
        setInitOk(st);
    } catch (err) {
        console.log(err);
    }
}

export default function ShopOverviewPage() {
    const dispatch = useDispatch();
    PageSetProperties(dispatch, 'Shop Overview');

    const classes = useStyles();
    const { show, shopId, shopInfo } = InitInfo();
    const setInitOk = useState(false)[1];

    useEffect(() => {
        initData(dispatch, shopId, setInitOk);
    }, [dispatch, setInitOk, shopId]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <ShopDetailSection />
                    <ShopUpdateModals />
                </MainSection>
            </div>
        </Preview>
    );
}
