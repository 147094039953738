import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import RewardDailyUses from '../app/Reward/Pages/RewardDailyUses';
import RewardManagePage from '../app/Reward/Pages/RewardManagePage';
import RewardOptionManagePage from '../app/Reward/Pages/RewardOptionManagePage';
import RewardProductReview from '../app/Reward/Pages/RewardProductReview';
import RewardPurchaseManage from '../app/Reward/Pages/RewardPurchaseManage';

export default function RewardRouters() {
    const location = useLocation();

    return (
        <Switch>
            <Route path="/:shopId/reward/products">
                <RewardManagePage key={location.key} />
            </Route>
            <Route path="/:shopId/reward/option-use">
                <RewardOptionManagePage key={location.key} />
            </Route>
            <Route path="/:shopId/reward/daily-uses">
                <RewardDailyUses key={location.key} />
            </Route>
            <Route path="/:shopId/reward/review">
                <RewardProductReview key={location.key} />
            </Route>
            <Route path="/:shopId/reward/purchase">
                <RewardPurchaseManage key={location.key} />
            </Route>
            <Route path="/:shopId/reward/update/:modal">
                <RewardManagePage />
            </Route>

            <Route path="/:shopId/reward/create/product/:modal">
                <RewardManagePage />
            </Route>
            <Route path="/:shopId/reward/:rewardId/product/update/:modal">
                <RewardManagePage />
            </Route>
        </Switch>
    );
}
