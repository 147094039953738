import { TextField, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ShopInfoUpdateDispatch } from '../../../dispatches/ShopInfoDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const updatingDetailShop = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_CAMPAIGN_UPDATE_DESCRUPTION.replace(':campId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function CampaignDescriptionSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const campaignDetail = useSelector((state) => state.campaignDetail);

    const { shopId } = InitInfo();
    const [descriptionValue, setDescriptionValue] = useState(campaignDetail.description);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleValue = (event) => {
        setDescriptionValue(event.target.value);
    };
    const handleNextClick = () => {
        // console.dir(next);
        updatingDetailShop(
            { description: descriptionValue, id: campaignDetail.id, shopId },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    // --Update Store
                    ShopInfoUpdateDispatch(dispatch, {
                        description: descriptionValue,
                    });
                    history.push(next);
                }
            }
        );
    };
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={campaignDetail.campaign_title}
                    />
                    <div className="app-style-body">
                        <h4 className="mb-2 mt-2 field-title">Campaign Description</h4>
                        <TextField
                            required
                            label="Campaign Description"
                            variant="outlined"
                            fullWidth
                            helperText="Please enter the Campaign Description"
                            rows={10}
                            multiline
                            type="textarea"
                            value={descriptionValue}
                            onChange={handleValue}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={descriptionValue}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
