/* eslint-disable no-param-reassign */
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '96%',
        },
    },
}));
const updatingSocial = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_SHOP_UPDATE_SOCIAL.replace(':shopId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

const getSocialData = (shopId) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SHOP_DETAIL.replace(':shopId', shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, setConfigData) {
    console.log(shopId);
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getSocialData(shopId);
        setConfigData(response.data.social_config);
    } catch (err) {
        console.log(err);
    }
}
function ShopSocialSetModal(props) {
    const classes = useStyles();

    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const { prev, next, step, paths, exiturl } = props;
    const { shopId, shopInfo } = InitInfo();
    const dispatch = useDispatch();
    const history = useHistory();
    const [configData, setConfigData] = useState([]);

    const reffbPage = useRef();
    const reffbPageId = useRef();
    const reffbAppId = useRef();
    const refTwPage = useRef();
    const refYoutubePage = useRef();
    const refGoogleAnId = useRef();
    const refInsPage = useRef();

    const handleNextClick = () => {
        updatingSocial(
            {
                id: shopInfo.id,
                fbPage: reffbPage.current.value,
                reffbPageId: reffbPageId.current.value,
                reffbAppId: reffbAppId.current.value,
                refTwPage: refTwPage.current.value,
                refYtPage: refYoutubePage.current.value,
                refGoogleAn: refGoogleAnId.current.value,
                refInsPage: refInsPage.current.value,
            },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    history.push('/');
                }
            }
        );
    };
    useEffect(() => {
        initData(dispatch, shopId, setConfigData);
        reffbPage.current.value = configData?.facebook;
        reffbPageId.current.value = configData?.fbPageId;
        reffbAppId.current.value = configData?.fbAppId;
        refTwPage.current.value = configData?.twitter;
        refYoutubePage.current.value = configData?.youtube;
        refGoogleAnId.current.value = configData?.googleAnalyticsId;
        refInsPage.current.value = configData?.instagram;
    }, [
        configData.facebook,
        configData.fbAppId,
        configData.fbPageId,
        configData.googleAnalyticsId,
        configData.instagram,
        configData.twitter,
        configData.youtube,
        dispatch,
        shopId,
    ]);

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={shopInfo.shop_name}
                    />
                    <div className="app-style-body">
                        <h4 className="mb-2 mt-2 field-title">Social Setup</h4>
                        <div className="App">
                            <form className={classes.root} noValidate autoComplete="off">
                                <TextField
                                    id="outlined-basic"
                                    label="Facebook Page"
                                    variant="outlined"
                                    inputRef={reffbPage}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Facebook Page ID"
                                    variant="outlined"
                                    inputRef={reffbPageId}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Facebook App ID"
                                    variant="outlined"
                                    inputRef={reffbAppId}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Google Analytics Id"
                                    variant="outlined"
                                    inputRef={refGoogleAnId}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Youtube Link"
                                    variant="outlined"
                                    inputRef={refYoutubePage}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Twitter Link"
                                    variant="outlined"
                                    inputRef={refTwPage}
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="Instagram Link"
                                    variant="outlined"
                                    inputRef={refInsPage}
                                />
                            </form>
                        </div>
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data="12"
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ShopSocialSetModal;
