import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import ProductDataDispatch from '../../../dispatches/ProductDataDispatch';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import ProductDetailsSection from '../sections/ProductDetailsSection';
import ProductPageHeaderSection from '../sections/ProductPageHeaderSection';
import ProductUpdateModals from '../sections/ProductUpdateModals';
import ProductVariantModals from '../variant/ProductVariantModals';

const pageTitle = 'Products Details';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

async function initData(dispatch, shopId, productId, setProductDetails) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const productInfo = await ProductDataDispatch(dispatch, productId);
        setProductDetails(productInfo);
    } catch (err) {
        console.log(err);
    }
}

export default function ProductDetailsPage() {
    const dispatch = useDispatch();
    PageSetProperties(dispatch, pageTitle);

    const classes = useStyles();
    const { show, shopId, shopInfo } = InitInfo();
    const { productId } = useParams();
    const [productDetails, setProductDetails] = useState({});

    useEffect(() => {
        initData(dispatch, shopId, productId, setProductDetails);
    }, [dispatch, shopId, productId]);

    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <ProductPageHeaderSection shopInfo={shopInfo} />
                    <Preview placeholderModel="" show={productDetails.id}>
                        <ProductDetailsSection
                            shopInfo={shopInfo}
                            productDetails={productDetails}
                        />
                        <ProductUpdateModals shopInfo={shopInfo} productId={productId} />
                        <ProductVariantModals shopInfo={shopInfo} productDetails={productDetails} />
                    </Preview>
                </MainSection>
            </div>
        </Preview>
    );
}
