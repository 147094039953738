import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import SettingManageCornJobPage from '../app/settings/pages/SettingManageCornJobPage';
import SettingManageNotification from '../app/settings/pages/SettingManageNotification';
import SettingManagePaymentPage from '../app/settings/pages/SettingManagePaymentPage';
import SettingManagePermissionPage from '../app/settings/pages/SettingManagePermissionPage';
import SettingManageUserPage from '../app/settings/pages/SettingManageUserPage';
import SettingShippingChargePage from '../app/settings/pages/SettingShippingChargePage';

export default function SettingRouters() {
    const location = useLocation();

    return (
        <Switch>
            <Route path="/:shopId/settings/user">
                <SettingManageUserPage key={location.key} />
            </Route>
            <Route path="/:shopId/settings/user/create/:modals">
                <SettingManageUserPage />
            </Route>
            <Route path="/:shopId/settings/user/:cornId/update/:modals">
                <SettingManageUserPage />
            </Route>
            <Route path="/:shopId/settings/permission">
                <SettingManagePermissionPage key={location.key} />
            </Route>
            <Route path="/:shopId/settings/permission/create/:modals">
                <SettingManagePermissionPage />
            </Route>

            <Route path="/:shopId/settings/shipping-charge/">
                <SettingShippingChargePage key={location.key} />
            </Route>
            <Route path="/:shopId/settings/notification/">
                <SettingManageNotification key={location.key} />
            </Route>
            <Route path="/:shopId/settings/payment/">
                <SettingManagePaymentPage key={location.key} />
            </Route>
            <Route path="/:shopId/settings/corn-job/create/:modals">
                <SettingManageCornJobPage />
            </Route>

            <Route path="/:shopId/settings/corn-job/:cornId/update/:modals">
                <SettingManageCornJobPage />
            </Route>
            <Route path="/:shopId/settings">
                <SettingManageCornJobPage key={location.key} />
            </Route>
        </Switch>
    );
}
