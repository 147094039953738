import React from 'react';
import StatusData from '../../../../configs/StatusData';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';
import StatusBtn from '../../../utils/StatusBtn';

export default function OrderPageDetailHeaderSection({
    headTitle,
    memoData,
    setModalShow,
    setStatusSet,
}) {
    return (
        <div style={{ overflow: 'hidden' }}>
            {/* <Link to="/products/manage/create/">
                <Button variant="contained" color="secondary" style={{ float: 'right' }}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    Create New
                </Button>
            </Link> */}

            <div className="page-common-title">{headTitle}</div>
            <div className="page-common-sub-title" style={{ display: 'flex' }}>
                <div className="page-common-breadcrumbs">
                    <BreadcrumbsCommon
                        options={[
                            {
                                href: '/orders/',
                                title: 'Orders',
                            },
                            {
                                href: '/products/manage/',
                                title: 'Manage',
                            },
                        ]}
                    />
                </div>
                <div className="page-common-status">
                    {StatusData?.map((value) => {
                        const timeLineStatus =
                            memoData.timeLine &&
                            memoData.timeLine.filter((e) => e === value).map((e) => e)[0];
                        return (
                            <StatusBtn
                                key={value}
                                status={value}
                                timeLineStatus={!!timeLineStatus}
                                setModalShow={setModalShow}
                                setStatusSet={setStatusSet}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
