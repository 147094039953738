import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

function DefaultPlaceholder() {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Skeleton animation="wave" height={60} />
            </Grid>
            <Grid item xs={8}>
                <Skeleton animation="wave" height={30} />
            </Grid>
            <Grid item xs={4}>
                <Skeleton animation="wave" height={30} />
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" height={40} />
            </Grid>
            <Grid item xs={6}>
                <Skeleton animation="wave" height={40} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" height={40} />
            </Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" height={60} />
            </Grid>
            <Grid item xs={9}>
                <Skeleton animation="wave" height={60} />
            </Grid>
        </Grid>
    );
}

export default DefaultPlaceholder;
