import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store from '../../store';

// --Variables
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

// --Render
export default function BackdropCommon() {
    const classes = useStyles();
    const bdInfo = useSelector((state) => state.activeBackdrop);
    const [open, setOpen] = useState(bdInfo);

    useEffect(() => {
        const unsubscribeMe = store.subscribe(() => {
            setOpen(store.getState().activeBackdrop);
        });
        // effect
        return () => {
            unsubscribeMe();
        };
    }, []);

    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
