import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import StockManagePage from '../app/Stock/Pages/StockManagePage';

export default function StockRouters() {
    const location = useLocation();

    return (
        <Switch>
            <Route path="/:shopId/stock">
                <StockManagePage key={location.key} />
            </Route>
            <Route path="/:shopId/stock/update/:modal">
                <StockManagePage />
            </Route>

            <Route path="/:shopId/stock/create/:modal">
                <StockManagePage />
            </Route>
            <Route path="/:shopId/stock/:productId/product/update/:modal">
                <StockManagePage />
            </Route>
        </Switch>
    );
}
