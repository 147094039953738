import {
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const creatingCornJobs = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SETTING_CORN_JOB_CREATE.replace(':shopId', params.shopId),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
// const testUrl = [
//     { title: 'Product Variation 1', url: 'https://store-api.bikiran.com/cron/product-variation' },
//     { title: 'Product Variation 2', url: 'https://store-api.bikiran.com/cron/product-variation' },

//     { title: 'Product Variation 3', url: 'https://store-api.bikiran.com/cron/product-variation' },

//     { title: 'Product Variation 4', url: 'https://store-api.bikiran.com/cron/product-variation' },
//     { title: 'Product Variation 5', url: 'https://store-api.bikiran.com/cron/product-variation' },
// ];

export default function CornCreateModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const theme = useTheme();
    const history = useHistory();
    const { shopId } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [title, seTtitle] = useState('');
    const [detail, setDetail] = useState('');
    const [duration, setDuration] = useState('');
    const [startTime, setStartTime] = useState('');
    const [url, setUrl] = useState('');

    const handleValue = (event) => {
        console.log(event);
        if (event.target.name === 'title') {
            seTtitle(event.target.value);
        }
        if (event.target.name === 'detail') {
            setDetail(event.target.value);
        }
        if (event.target.name === 'duration') {
            setDuration(event.target.value);
        }
        if (event.target.name === 'startTime') {
            setStartTime(event.target.value);
        }
        if (event.target.name === 'url') {
            setUrl(event.target.value);
        }
    };
    console.log(url);
    const handleNextClick = () => {
        creatingCornJobs({ title, detail, duration, startTime, url, shopId })
            .then((response) => {
                // DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    history.push(exiturl);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal
            {...props}
            className={['app-style', deviceClassName].join(' ')}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Create Corn Job"
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Title
                        </Typography>
                        <TextField
                            required
                            label="Jobs Title"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the job title"
                            value={title}
                            onChange={handleValue}
                            name="title"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            Description
                        </Typography>
                        <TextField
                            required
                            label="Jobs Description"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the Job Description"
                            value={detail}
                            onChange={handleValue}
                            name="detail"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            Duration
                        </Typography>
                        <TextField
                            required
                            label="Duration"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the duration in minutes"
                            value={duration}
                            onChange={handleValue}
                            name="duration"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            Start Time
                        </Typography>
                        <TextField
                            required
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the Start Time"
                            value={startTime}
                            onChange={handleValue}
                            type="datetime-local"
                            name="startTime"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            URL
                        </Typography>
                        <TextField
                            required
                            label="URL"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the url"
                            value={url}
                            onChange={handleValue}
                            name="url"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            URL Two
                        </Typography>
                        {/* 
                        <Autocomplete
                            options={testUrl}
                            getOptionLabel={(option) => option.title}
                            getOptionSelected={(option) => option.url}
                            fullWidth
                            clearOnBlur
                            onChange={handleValue}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value="djddj"
                                    size="small"
                                    required
                                    label="URL"
                                    variant="outlined"
                                    fullWidth
                                    helperText="Please enter the url"
                                />
                            )}
                        /> */}
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={startTime}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
