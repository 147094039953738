import React from 'react';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';

export default function ReportManageHeaderSection({ shopInfo }) {
    return (
        <div style={{ overflow: 'hidden' }}>
            <div className="page-common-title">Report</div>
            <BreadcrumbsCommon
                options={[
                    {
                        href: `/${shopInfo.id}/reports/`,
                        title: 'Report',
                    },
                    {
                        href: `/${shopInfo.id}/reports/`,
                        title: 'Manage',
                    },
                ]}
            />
        </div>
    );
}
