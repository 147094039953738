import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import CornCreateModal from '../modals/CornCreateModal';

export default function CornJobCreateModals() {
    const location = useLocation();
    const { shopInfo } = InitInfo();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/settings/corn-jobs`}>
            <CornCreateModal path={`/${shopInfo.id}/settings/corn-job/create/`} />
        </StepHandler>
    );
}
