import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import StockAddProductModal from '../modals/StockAddProductModal';

export default function StockAddProductModals({ productData, categoryData }) {
    const location = useLocation();
    const { shopId } = InitInfo();
    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopId}/reward/`}>
            <StockAddProductModal
                productData={productData}
                categoryData={categoryData}
                path={`/${shopId}/stock/create/`}
            />
        </StepHandler>
    );
}
