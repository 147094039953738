/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Chip from '@material-ui/core/Chip';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StatusData from '../../../../../configs/StatusData';
import StatusBtn from '../../../../utils/StatusBtn';
import CartProductVariant from '../../../orders/comps/ProductOrderVariant';
import OrderConfirmModal from '../../../orders/modals/OrderConfirmModal';

function CustomerDetailPropertyInfo({ memoData, memoItemData, shopId }) {
    const [modalShow, setModalShow] = useState(false);
    const [statusSet, setStatusSet] = useState('');

    const handleModal = () => {
        setStatusSet('');
    };
    const paidAmount =
        memoData.traction &&
        memoData.traction.reduce((total, currentValue) => (total += currentValue), 0);
    return (
        <div className="row" style={{ padding: '15px' }}>
            <div
                className="col-12"
                style={{ borderBottom: '1px solid #e5dede', marginBottom: '10px', display: 'flex' }}
            >
                <div style={{ width: '90%' }}>
                    <Link to={`/${shopId}/orders/${memoData.id}/details`}>
                        <h5 className="user-orders-number">Order INV {memoData.id}</h5>
                        <h6 className="user-orders-time">Placed on {memoData.time_created}</h6>
                    </Link>
                </div>
                <div style={{ float: 'right' }}>
                    <StatusBtn
                        key={memoData.status}
                        status={memoData.status}
                        timeLineStatus
                        setModalShow={setModalShow}
                        setStatusSet={setStatusSet}
                    />
                </div>
            </div>
            <div className="col-12">
                {memoItemData &&
                    memoItemData.slice(0, 2).map((item) => (
                        <div className="row" key={item.id}>
                            <div className="col-4 col-lg-2">
                                <div className="user-orders-img">
                                    <img
                                        src={`https://www.optimizee.xyz/images/${btoa(
                                            item.product_image
                                        )},200,300.jpg`}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-8 col-lg-3 order-viewTitle">
                                <b>{item.product_title}</b>

                                <br />
                                <ul className="variant-list">
                                    <CartProductVariant
                                        data={Object.values(item.variation).slice(
                                            0,
                                            Object.keys(item.variation).length - 2
                                        )}
                                    />
                                </ul>
                            </div>
                            <div className="d-lg-none d-xl-none col-4" />
                            <div className="col-2 order-viewQty">Qty:{item.quantity}</div>
                            <div className="col-3">
                                Amount:
                                {memoData.net_amount && memoData.net_amount.toFixed(2)}
                                <br />
                                Paid:{paidAmount && paidAmount.toFixed(2)}
                            </div>
                            <div className="col-5 order-viewStatus">
                                {statusSet && (
                                    <OrderConfirmModal
                                        modalShow={modalShow}
                                        handleModal={handleModal}
                                        memoData={memoData}
                                        memoItem={item}
                                        shopId={shopId}
                                        orStatus={statusSet}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
            </div>

            <div className="col-12">
                <div className="form-row">
                    <div className="col-12 col-lg-4">
                        {memoItemData.length > 2 ? (
                            <Link to={`/${shopId}/orders/${memoData.id}/details`}>
                                <Chip
                                    size="small"
                                    label={`More ${memoItemData.length - 2}`}
                                    clickable
                                    style={{
                                        fontSize: '10px',
                                        margin: '5px',
                                        color: 'rgb(27 149 138 / 97%)',
                                        background: 'rgb(0 150 136 / 38%)',
                                    }}
                                />
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="col-12 col-lg-8">
                        {StatusData.map((value) => {
                            const timeLineStatus =
                                memoData.timeLine &&
                                memoData.timeLine.filter((e) => e === value).map((e) => e)[0];
                            return (
                                <StatusBtn
                                    key={value}
                                    status={value}
                                    timeLineStatus={!!timeLineStatus}
                                    setModalShow={setModalShow}
                                    setStatusSet={setStatusSet}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CustomerDetailPropertyInfo;
