import React, { cloneElement } from 'react';

export default function StepHandler({ pathname, children, exiturl, loop }) {
    if (loop > 0 && !Array.isArray(children)) {
        const paths = [];
        for (let x = 0; x < loop; x += 1) {
            paths.push(children?.props?.path.replace(':loopIndex', x));
        }

        const loopIndex = paths.indexOf(pathname);

        if (loopIndex !== -1) {
            return cloneElement(children, {
                pathname,
                count: loop,
                step: loopIndex,
                paths,
                prev: paths[loopIndex - 1],
                next: paths[loopIndex + 1],
                show: true,
                exiturl,
            });
        }
    }

    if (children) {
        const newChildren = Array.isArray(children) ? children : [children];
        const paths = newChildren.map((item) => item.props?.path);

        for (let i = 0; i <= newChildren.length; i += 1) {
            if (pathname === newChildren[i]?.props?.path) {
                return cloneElement(newChildren[i], {
                    pathname,
                    count: newChildren.length,
                    step: i,
                    paths,
                    prev: paths[i - 1],
                    next: paths[i + 1],
                    show: true,
                    exiturl,
                });
            }
        }
    }

    return <></>;
}
