import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Link } from 'react-router-dom';
import FindInArray from '../../../utils/FindInArray';
import Optimizee from '../../../utils/Optimizee';

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

function ProductRow({ row, shopInfo, percent, rewardId }) {
    const classes = useStyles();

    return (
        <TableRow className={[classes.tableRowHover, 'tableTr'].join(' ')}>
            <TableCell align="center">
                <Link to={`/${shopInfo.id}/products/${row.id}/details`}>
                    <img
                        className={classes.image}
                        src={Optimizee(row.default_image, 100)}
                        alt={row.title}
                    />
                </Link>
            </TableCell>

            <TableCell align="left">
                <Link to={`/${shopInfo.id}/products/${row.id}/details`}>
                    <Typography variant="h6">{row.title}</Typography>
                </Link>
            </TableCell>

            <TableCell align="center">{row.total_price ? row.total_price : 0}</TableCell>
            <TableCell align="center">{percent} %</TableCell>

            <TableCell align="center">
                <Link to={`/${shopInfo.id}/reward/${rewardId}/product/update`}>
                    <EditIcon />
                </Link>
            </TableCell>
        </TableRow>
    );
}

export default function RewardProductListDesktopView({
    rewardproductData,
    productData,
    categoryList = [],
    shopInfo = {},
}) {
    const classes = useStyles();

    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');
    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow className="tableTr">
                        <TableCell colSpan="2" align="center">
                            Products
                        </TableCell>
                        <TableCell align="center">Price</TableCell>
                        <TableCell align="center">Reward Perent</TableCell>
                        <TableCell colSpan="2" align="center">
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rewardproductData &&
                        rewardproductData.map((reward) => {
                            const row = FindArr.find(reward.product_sl);
                            return (
                                <ProductRow
                                    row={row}
                                    key={row.sl}
                                    categoryList={categoryList}
                                    shopInfo={shopInfo}
                                    percent={reward.reward_percent}
                                    rewardId={reward.sl}
                                />
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
