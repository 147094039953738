import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DraftsIcon from '@material-ui/icons/Drafts';
import LanguageIcon from '@material-ui/icons/Language';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PhoneIcon from '@material-ui/icons/Phone';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../../../assets/css/store-select-section.css';
import InitInfo from '../../../utils/InitInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 525,
    },
    rootTwo: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(7),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function CampaignManageSection({ campDataAr }) {
    const classes = useStyles();
    const { shopId } = InitInfo();

    return (
        <div className={classes.rootTwo}>
            <Grid container spacing={3}>
                {campDataAr.map((campaign) => (
                    <Grid key={campaign.id} item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div className="prev-store">
                            <List className={classes.root}>
                                <ListItem button>
                                    <Link
                                        to={`/${shopId}/campaigns/${campaign.id}/detail`}
                                        className="link"
                                    >
                                        <ListItemText primary={campaign.campaign_title} />
                                    </Link>
                                    <IconButton
                                        aria-label="delete"
                                        color="primary"
                                        value={campaign.id}
                                        style={{ marginLeft: 'auto' }}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                </ListItem>
                            </List>

                            <hr />

                            <List className={classes.root}>
                                <ListItem button key={1}>
                                    <ListItemIcon>
                                        <NotificationsNoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Notification" />
                                    <IconButton aria-label="delete" color="primary">
                                        <Badge edge="end" badgeContent={4} color="error" />
                                    </IconButton>
                                </ListItem>
                                <ListItem button key={2}>
                                    <ListItemIcon>
                                        <DraftsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Messages" />
                                    <IconButton aria-label="delete" color="primary">
                                        <Badge badgeContent={5} color="error" />
                                    </IconButton>
                                </ListItem>
                                <ListItem button key={3}>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="01934335477" />
                                </ListItem>
                                <ListItem button key={4}>
                                    <ListItemIcon>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <Link to="." className="link">
                                        <ListItemText primary="www.rupkotha.com" />
                                    </Link>
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
