import { Switch } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import BackdropOpenDispatch from '../../../dispatches/BackdropOpenDispatch';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import InitInfo from '../../../utils/InitInfo';

const updateProductPublish = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_PRODUCT_PROPERTY_UPDATE.replace(':productId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function ProductPublishBtn({ data }) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(!!data.is_published);
    const { shopInfo } = InitInfo();
    const handleChange = () => {
        const newVal = value !== true;
        console.log(newVal);
        BackdropOpenDispatch(dispatch, true);

        updateProductPublish(
            { is_val: newVal, id: data.id, shopId: shopInfo.id, type: 'is_published' },
            (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0 && response.data.id) {
                    // --Update Store
                    ProductDataUpdateDispatch(dispatch, {
                        is_published: value,
                    });
                    setValue(newVal);
                }
                BackdropOpenDispatch(dispatch, false);
            }
        );
    };

    return (
        <Switch
            checked={value}
            onChange={handleChange}
            color="primary"
            name="checkedB"
            value={value}
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    );
}
