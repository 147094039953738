import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Link } from 'react-router-dom';
import DetectDevice from '../../../utils/DetectDevice';

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '50%',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
}));

function TableData({ classes, customerData, shopId }) {
    let serial = 0;
    return (
        <TableBody>
            {customerData.map((row) => {
                serial += 1;
                return (
                    <TableRow
                        key={row.id}
                        className={[classes.tableRowHover, 'tableTr '].join(' ')}
                    >
                        <TableCell align="center" width="10">
                            {serial}
                        </TableCell>
                        <TableCell scope="row">
                            <IconButton
                                edge="end"
                                aria-label="user"
                                aria-haspopup="true"
                                color="inherit"
                            >
                                <Avatar className={classes.purple} style={{ margin: '0px auto' }}>
                                    {row.name.slice(0, 2)}
                                </Avatar>
                            </IconButton>
                            <Link to={`/${shopId}/customers/manage/${row.id}/detail/`}>
                                <b style={{ paddingLeft: '20px' }}>{row.name}</b>
                            </Link>
                        </TableCell>
                        <TableCell align="center">{row.default_email}</TableCell>
                        <TableCell align="center">{row.default_contact}</TableCell>
                        <TableCell align="center">
                            {row?.provider_ar?.map((rItem) => (
                                <Chip
                                    label={rItem.provider}
                                    color="primary"
                                    variant="outlined"
                                    key={rItem.provider}
                                    style={{ marginRight: 5 }}
                                />
                            ))}
                        </TableCell>

                        <TableCell align="center">{row.status.toUpperCase()}</TableCell>

                        <TableCell align="center" width="10">
                            <Link to={`/${shopId}/customers/manage/${row.id}/update/`}>
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Link>
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
}

function MobileDataView({ classes, row, shopId }) {
    return (
        <div className="col-12 productMobile-card-col" key={row.id}>
            <Card className={classes.root}>
                <CardContent>
                    <div className="row">
                        <div className="col-10">
                            <Typography variant="body2" color="textSecondary" component="p">
                                <Link to={`/${shopId}/customers/manage/${row.id}/detail/`}>
                                    {row.name}
                                </Link>
                            </Typography>
                        </div>
                        <div className="col-1">
                            <Link to={`/${shopId}/customers/manage/${row.id}/update/`}>
                                <IconButton style={{ paddingTop: 0 }}>
                                    <EditIcon />
                                </IconButton>
                            </Link>
                        </div>
                        <div className="col-6">{row.default_email}</div>

                        <div className="col-5">
                            <p style={{ textAlign: 'right' }}>
                                {row.default_contact.toUpperCase()}
                            </p>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
export default function CustomerManageSection({ customerData, shopId }) {
    const classes = useStyles();
    const handleChangePage = () => {};
    return (
        <DetectDevice>
            <TableContainer className={classes.tableContainer} type="lg|xl" component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow className="tableTr">
                            <TableCell>SL</TableCell>
                            <TableCell>Name</TableCell>

                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Phone</TableCell>
                            <TableCell align="center">Providers</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center" />
                        </TableRow>
                    </TableHead>
                    <TableData classes={classes} customerData={customerData} shopId={shopId} />
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={2}
                    rowsPerPage={10}
                    page={0}
                    onChangePage={handleChangePage}
                />
            </TableContainer>

            <div className="row" type="sm|xs">
                {customerData.map((row) => (
                    <MobileDataView row={row} key={row.id} classes={classes} shopId={shopId} />
                ))}
            </div>
        </DetectDevice>
    );
}
