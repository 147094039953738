import {
    FormControlLabel,
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const updatingPriceProduct = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_PRICE_UPDATE.replace(':productId', params.productId),
            params
        )
            .then((response) => {
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        price_sale: params.productPrice,
                        vat_type: params.productVatType,
                        vat_percent: params.productVatPercent,
                        price_sale_offer: 0,
                    });
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function financial(x) {
    return x.toFixed(2);
}

function vatCalculator(priceX, vatType, vatPercentX) {
    const price = Number.parseFloat(priceX || 0);
    const vatPercent = Number.parseFloat(vatPercentX || 0);

    if (vatType === 'no_vat') {
        return {
            rawPrice: financial(price),
            vatAmount: financial(0),
            totalPrice: financial(price),
        };
    }

    if (vatType === 'include_vat') {
        const rawPrice = (price * 100) / (100 + vatPercent);
        const vat = (price * vatPercent) / (100 + vatPercent);

        return {
            rawPrice: financial(rawPrice),
            vatAmount: financial(vat),
            totalPrice: financial(price),
        };
    }

    if (vatType === 'exclude_vat') {
        const vatAmount = (price * vatPercent) / 100;

        return {
            rawPrice: financial(price),
            vatAmount: financial(vatAmount),
            totalPrice: financial(price + vatAmount),
        };
    }
    return {};
}

function ShowCalculation({ vatType, calculatedData }) {
    if (vatType !== 'no_vat' && calculatedData.totalPrice > 0) {
        return (
            <div className="price-vat-show">
                <span>{calculatedData.rawPrice}</span>
                <span>+</span>
                <span>{calculatedData.vatAmount}</span>
                <span>=</span>
                <span>{calculatedData.totalPrice}</span>
            </div>
        );
    }
    return null;
}

export default function ProductPriceSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { shopInfo, productDetails } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [rawPrice, setRawPrice] = useState(productDetails.price_sale);
    const [vatType, setVatType] = useState(productDetails?.vat_type);
    const [vatPercent, setVatPercent] = useState(productDetails.vat_percent);

    const handleRawPrice = (event) => {
        setRawPrice(event.target.value >= 0 ? event.target.value : 0);
    };

    const handleVatType = (event) => {
        console.log(event);
        setVatType(event.target.checked === true ? 1 : 0);
        if (event.target.checked === true) {
            setVatPercent(0);
        }
    };

    const handleVatPercent = (event) => {
        setVatPercent(event.target.value >= 0 ? event.target.value : 0);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updatingPriceProduct(dispatch, {
            productPrice: rawPrice,
            productVatType: vatType,
            productVatPercent: vatPercent,
            shopId: shopInfo.id,
            productId: productDetails.id,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    history.push(next);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const calculatedData = vatCalculator(rawPrice, vatType, vatPercent);

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Price Setup
                        </Typography>

                        <TextField
                            required
                            label="Product Price"
                            variant="outlined"
                            fullWidth
                            type="number"
                            helperText="Please enter the product price"
                            onChange={handleRawPrice}
                            value={rawPrice}
                            size="small"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={vatType === 1}
                                    name="checkedB"
                                    color="primary"
                                    onChange={handleVatType}
                                    value={vatType}
                                />
                            }
                            label="Vat"
                        />

                        {vatType !== 0 && (
                            <TextField
                                required
                                label="Product Vat (Percent)"
                                variant="outlined"
                                fullWidth
                                value={vatPercent}
                                type="number"
                                onChange={handleVatPercent}
                                size="small"
                                style={{ marginBottom: 10 }}
                            />
                        )}
                        <ShowCalculation vatType={vatType} calculatedData={calculatedData} />
                        <small style={{ paddingTop: 10, color: 'red' }}>
                            If Click Next Offer Price Will be changed
                        </small>
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={rawPrice ? [1] : []}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
