import { TextField, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import RewardDataDispatch from '../../../dispatches/RewardDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const updateRewardPercent = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_REWARD_UPDATE.replace(':shopId', params.shopId), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function RewardUpdateModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const rewardInfo = useSelector((state) => state.rewardInfo);

    const { shopId } = InitInfo();
    const [percentValue, setPercentValue] = useState(rewardInfo.percent);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleValue = (event) => {
        setPercentValue(event.target.value);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updateRewardPercent({ shopId, percentValue }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                // --Update Store
                RewardDataDispatch(dispatch, {
                    percent: percentValue,
                });
                history.push(exiturl);
            }
        });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Reward Set"
                    />
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">Update Reward Setting.</h4>
                        <TextField
                            required
                            label="Reward Percent"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter Percent"
                            value={percentValue}
                            onChange={handleValue}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={percentValue}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
