import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductCategoryPage from '../app/products/pages/ProductCategoryPage';
import ProductDetailsPage from '../app/products/pages/ProductDetailsPage';
import ProductFeaturePage from '../app/products/pages/ProductFeaturePage';
import ProductIndexPage from '../app/products/pages/ProductIndexPage';
import ProductManagePage from '../app/products/pages/ProductManagePage';
import ProductStockPage from '../app/products/pages/ProductStockPage';

export default function ProductRouters() {
    return (
        <Switch>
            <Route path="/:shopId/products" exact>
                <ProductIndexPage />
            </Route>

            <Route path="/:shopId/products/manage">
                <ProductManagePage />
            </Route>

            <Route path="/:shopId/products/:productId/details">
                <ProductDetailsPage />
            </Route>

            <Route path="/:shopId/products/:productId/update/:modals">
                <ProductDetailsPage />
            </Route>

            <Route path="/:shopId/products/:productId/update-variations/:index">
                <ProductDetailsPage />
            </Route>

            <Route path="/:shopId/products/stock">
                <ProductStockPage />
            </Route>

            <Route path="/:shopId/products/category">
                <ProductCategoryPage />
            </Route>
            <Route path="/:shopId/products/feature">
                <ProductFeaturePage />
            </Route>
        </Switch>
    );
}
