import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import GatewayConfigModal from '../modals/GatewayConfigModal';
import SmsTemplateConfigModal from '../modals/SmsTemplateConfigModal';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

function NotifiTempDataRow({ notifiListAr, setSmskey, setShow, setConfigData }) {
    const classes = useStyles();
    let serial = 0;
    return (
        <TableBody>
            {Object.values(notifiListAr)?.map((row) => {
                serial += 1;
                return (
                    <TableRow key={serial} className={[classes.tableRowHover, 'tableTr'].join(' ')}>
                        <TableCell align="center">{serial}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{row.keyword}</TableCell>

                        <TableCell align="center">
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                startIcon={<PermDataSettingIcon />}
                                onClick={() => {
                                    setSmskey(row.keyword);
                                    setShow(true);
                                    setConfigData(row?.data?.sms?.template);
                                }}
                            >
                                Config
                            </Button>
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
}
function NotifiDataRow({ notifiListAr, shopId, setNotifiListAr }) {
    const classes = useStyles();
    let serial = 0;

    const handleCheckbox = (ev) => {
        AxiosAuth.post(ConfigApi.API_SETTING_NOTIFICATION_SET.replace(':shopId', shopId), {
            type: ev[0],
            value: ev[1],
            keyword: ev[2],
        })
            .then((response) => {
                setNotifiListAr(response.data.notificationList);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <TableBody>
            {Object.values(notifiListAr)?.map((row) => {
                serial += 1;
                const sms = row?.data?.sms;
                const email = row?.data?.email;
                const push = row?.data?.push;
                return (
                    <TableRow key={serial} className={[classes.tableRowHover, 'tableTr'].join(' ')}>
                        <TableCell align="center">{serial}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="center">
                            <Checkbox
                                color="secondary"
                                checked={sms?.is_enable === 1}
                                disabled={!sms?.template}
                                onClick={() =>
                                    handleCheckbox(['sms', sms?.is_enable === 1 ? 0 : 1, row.key])
                                }
                            />
                        </TableCell>
                        <TableCell align="center">
                            <Checkbox
                                color="default"
                                checked={email?.is_enable === 1}
                                disabled={!email?.template}
                                onClick={() =>
                                    handleCheckbox([
                                        'email',
                                        email?.is_enable === 1 ? 0 : 1,
                                        row.key,
                                    ])
                                }
                            />
                        </TableCell>
                        <TableCell align="center">
                            <Checkbox
                                color="primary"
                                checked={push?.is_enable === 1}
                                disabled={!push?.template}
                                onClick={() =>
                                    handleCheckbox(['push', push?.is_enable === 1 ? 0 : 1, row.key])
                                }
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
}
function GateWayDataRow({ gateWayAr, setShowGateway }) {
    const classes = useStyles();
    const serial = 0;

    return (
        <TableBody>
            <TableRow key={serial} className={[classes.tableRowHover, 'tableTr'].join(' ')}>
                <TableCell align="center">1</TableCell>
                <TableCell align="left">SMS Gateway</TableCell>
                <TableCell align="left" style={{ whiteSpace: 'unset' }}>
                    Sender: {gateWayAr?.sender} <br />
                    API ID: {gateWayAr['api-id']} <br />
                    API KEY: {gateWayAr['api-key']} <br />
                    Domain: {gateWayAr?.domain} <br />
                    Country Code: {gateWayAr['country-code']} <br />
                    Priority: {gateWayAr?.priority} <br />
                </TableCell>
                <TableCell align="center">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<PermDataSettingIcon />}
                        onClick={() => {
                            setShowGateway(true);
                        }}
                    >
                        Config
                    </Button>
                </TableCell>
            </TableRow>
        </TableBody>
    );
}
export default function SettingNotificationSection({
    notifiListAr,
    shopId,
    setNotifiListAr,
    gateWayAr,
    setGateWayAr,
}) {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [smsKey, setSmskey] = useState('');
    const [configData, setConfigData] = useState('');
    const [showGateway, setShowGateway] = useState(false);
    return (
        <>
            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="notification-overview-header mb-5"
            >
                <Tab eventKey="profile" title="Config">
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                            size="small"
                            style={{ marginTop: 30 }}
                        >
                            <TableHead>
                                <TableRow className="tableTr">
                                    <TableCell align="center">SL</TableCell>
                                    <TableCell>Notification Title</TableCell>
                                    <TableCell align="center">SMS</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Push</TableCell>
                                </TableRow>
                            </TableHead>

                            <NotifiDataRow
                                shopId={shopId}
                                notifiListAr={notifiListAr}
                                setNotifiListAr={setNotifiListAr}
                            />
                        </Table>
                    </TableContainer>
                </Tab>
                <Tab eventKey="contact" title="SMS Template">
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                            size="small"
                            style={{ marginTop: 30 }}
                        >
                            <TableHead>
                                <TableRow className="tableTr">
                                    <TableCell align="center">SL</TableCell>
                                    <TableCell>Notification Title</TableCell>
                                    <TableCell align="center">SMS Template</TableCell>
                                    {/* <TableCell align="center">Email Template</TableCell>
                                    <TableCell align="center">Push Template</TableCell> */}
                                </TableRow>
                            </TableHead>

                            <NotifiTempDataRow
                                shopId={shopId}
                                notifiListAr={notifiListAr}
                                setNotifiListAr={setNotifiListAr}
                                setSmskey={setSmskey}
                                setShow={setShow}
                                setConfigData={setConfigData}
                            />
                        </Table>
                    </TableContainer>
                </Tab>
                <Tab eventKey="gateway" title="Gateway">
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="simple table"
                            size="small"
                            style={{ marginTop: 30 }}
                        >
                            <TableHead>
                                <TableRow className="tableTr">
                                    <TableCell align="center">SL</TableCell>
                                    <TableCell>Gateway Title</TableCell>
                                    <TableCell align="center">Value</TableCell>
                                    <TableCell align="center" />
                                    {/* <TableCell align="center">Email Template</TableCell>
                                    <TableCell align="center">Push Template</TableCell> */}
                                </TableRow>
                            </TableHead>

                            <GateWayDataRow
                                shopId={shopId}
                                gateWayAr={gateWayAr}
                                setGateWayAr={setGateWayAr}
                                setShowGateway={setShowGateway}
                            />
                        </Table>
                    </TableContainer>
                </Tab>
            </Tabs>
            <SmsTemplateConfigModal
                show={show}
                setShow={setShow}
                shopId={shopId}
                smsKey={smsKey}
                setSmskey={setSmskey}
                setNotifiListAr={setNotifiListAr}
                notifiListAr={notifiListAr}
                configData={configData}
            />
            {gateWayAr ? (
                <GatewayConfigModal
                    show={showGateway}
                    setShow={setShowGateway}
                    shopId={shopId}
                    gateWayAr={gateWayAr}
                    setGateWayAr={setGateWayAr}
                />
            ) : (
                ''
            )}
        </>
    );
}
