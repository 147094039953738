import { useTheme } from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import ProductCommonPropertyBtn from '../comps/ProductCommonPropertyBtn';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

export default function ProductPropertyModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const history = useHistory();

    const theme = useTheme();
    const { productDetails } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const handleNextClick = () => {
        history.push(next);
    };
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-variant"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <div className="product-property-modal">
                            <div className="product-property-switch">
                                <ProductCommonPropertyBtn
                                    data={productDetails.is_published}
                                    id={productDetails.id}
                                    type="is_published"
                                />
                            </div>
                            <div className="product-property-title">Is Published</div>
                        </div>
                        <div className="product-property-modal">
                            <div className="product-property-switch">
                                <ProductCommonPropertyBtn
                                    data={productDetails.is_exclusive}
                                    id={productDetails.id}
                                    type="is_exclusive"
                                />
                            </div>
                            <div className="product-property-title">Is Exclusive</div>
                        </div>
                        <div className="product-property-modal">
                            <div className="product-property-switch">
                                <ProductCommonPropertyBtn
                                    data={productDetails.is_in_stock}
                                    id={productDetails.id}
                                    type="is_in_stock"
                                />
                            </div>
                            <div className="product-property-title">Is Stock</div>
                        </div>
                        <div className="product-property-modal">
                            <div className="product-property-switch">
                                <ProductCommonPropertyBtn
                                    data={productDetails.is_free_shipping}
                                    id={productDetails.id}
                                    type="is_free_shipping"
                                />
                            </div>
                            <div className="product-property-title">Is Free Shipping</div>
                        </div>
                        <div className="product-property-modal">
                            <div className="product-property-switch">
                                <ProductCommonPropertyBtn
                                    data={productDetails.is_having_emi}
                                    id={productDetails.id}
                                    type="is_having_emi"
                                />
                            </div>
                            <div className="product-property-title">Having EMI</div>
                        </div>
                        <div className="product-property-modal">
                            <div className="product-property-switch">
                                <ProductCommonPropertyBtn
                                    data={productDetails.is_having_lote}
                                    id={productDetails.id}
                                    type="is_having_lote"
                                />
                            </div>
                            <div className="product-property-title">Having LOT</div>
                        </div>
                        <div className="product-property-modal">
                            <div className="product-property-switch">
                                <ProductCommonPropertyBtn
                                    data={productDetails.is_having_package}
                                    id={productDetails.id}
                                    type="is_having_package"
                                />
                            </div>
                            <div className="product-property-title">Having Package</div>
                        </div>
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={[{ id: productDetails.id }]}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
