import { Switch } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

const setPaymentConfig = ({ currency, rate, shopId, isDefault }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SETTING_PAYMENT_CURRENCY_SET.replace(':shopId', shopId), {
            currency,
            rate,
            shopId,
            isDefault,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const updatePaymentConfig = ({ currency, rate, shopId, currencyId, isDefault }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SETTING_PAYMENT_CURRENCY_UPDATE.replace(':shopId', shopId).replace(
                ':currencyId',
                currencyId
            ),
            {
                currency,
                rate,
                shopId,
                isDefault,
            }
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const currencyList = [
    { title: 'Bangladeshi Taka', currency: 'BDT' },
    { title: 'American Dollar ', currency: 'USD' },
    { title: 'Euro ', currency: 'EURO' },
];
function PaymentConfigCurrencyModal({
    show,
    setShow,
    shopId,
    setCurrencyConfigAr,
    selectCurrency,
    setSelectCurrency,
}) {
    const [currency, setCurrency] = useState();
    const [rate, setRate] = useState();
    const [isDefault, setIsDefault] = useState();

    const handleIsDefault = () => {
        const newVal = isDefault !== true;
        setIsDefault(newVal);
    };

    const handlePaymentConfig = () => {
        setPaymentConfig({
            currency,
            rate,
            shopId,
            isDefault,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShow(false);
                    setCurrencyConfigAr(response.data.currencyConfig_ar);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handlePaymentConfigUpdate = (currencyId) => {
        updatePaymentConfig({
            currency,
            rate,
            shopId,
            currencyId,
            isDefault,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShow(false);
                    setCurrencyConfigAr(response.data.currencyConfig_ar);
                    setSelectCurrency('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleAutoComp = (event, newValue) => {
        setCurrency(newValue ? newValue.currency : '');
    };
    const handleRate = (event) => {
        setRate(event.target.value);
    };
    useEffect(() => {
        if (selectCurrency.sl) {
            setCurrency(selectCurrency?.currency);
            setRate(selectCurrency?.rate);
            setIsDefault(selectCurrency?.is_default === 1);
        }
    }, [selectCurrency]);

    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
                setSelectCurrency('');
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Currency Config</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Autocomplete
                            id="combo-box-demo"
                            options={currencyList}
                            getOptionLabel={(option) => option.currency}
                            onChange={handleAutoComp}
                            style={{ width: '100%' }}
                            defaultValue={
                                currencyList.filter(
                                    (item) => item.currency === selectCurrency?.currency
                                )[0]
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Currency Name" variant="outlined" />
                            )}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <TextField
                            label="Currency Rate"
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={rate}
                            onChange={handleRate}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Is Default</Form.Label>
                        <Switch
                            checked={isDefault}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={handleIsDefault}
                            value={isDefault}
                        />
                    </Form.Group>
                    {selectCurrency.sl ? (
                        <button
                            type="button"
                            onClick={() => handlePaymentConfigUpdate(selectCurrency.sl)}
                            className="btn btn-primary"
                            style={{ float: 'right' }}
                        >
                            Update Info
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={handlePaymentConfig}
                            className="btn btn-primary"
                            style={{ float: 'right' }}
                        >
                            Save Info
                        </button>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default PaymentConfigCurrencyModal;
