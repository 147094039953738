export default function IsLoggedIn(response, history, to = '/login/') {
    if (history && !response.headers['secure-auth']) {
        history.push(to);
    }

    if (response.headers['secure-auth']) {
        return true;
    }

    return false;
}
