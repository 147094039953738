import { Tooltip } from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import StockManageModal from '../../modals/StockManageModal';
import StockPriceManageModal from '../../modals/StockPriceManageModal';

// const multiplexing = (variation) => {
//     const concept = (primaryArr, key, newData) => {
//         const output = [];

//         if (primaryArr.length > 0) {
//             for (let x = 0; x < primaryArr.length; x += 1) {
//                 for (let y = 0; y < newData.length; y += 1) {
//                     output.push({ ...primaryArr[x], [key]: newData[y] });
//                 }
//             }
//         } else {
//             for (let y = 0; y < newData.length; y += 1) {
//                 output.push({ [key]: newData[y] });
//             }
//         }

//         return output;
//     };

//     const keys = Object.keys(variation);

//     let output = [];
//     for (let y = 0; y < keys.length; y += 1) {
//         output = concept(output, keys[y], variation[keys[y]]);
//     }
//     return output;
// };

function ProductPropertyVariantStock({ value, variation, productId }) {
    const [stockModal, setStockModal] = useState(false);
    const [priceModal, setPriceModal] = useState(false);
    const [selectVariant, setSelectVariant] = useState(false);
    // const multiVariation = multiplexing(variation);

    const handleStockModal = (row) => {
        setStockModal(true);
        setSelectVariant(row);
    };
    const handlePriceModal = (row) => {
        setPriceModal(true);
        setSelectVariant(row);
    };
    // console.log(value);
    return (
        <>
            <TabPanel value={value}>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Color</th>
                            <th>Size</th>
                            <th>Age</th>
                            <th>Stock</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {variation?.map((row) => (
                            <tr key={`${row.color}-${row.ageRange}-${row.size}`}>
                                <td style={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={row.color}>
                                        <div className="color-item">
                                            <button
                                                type="button"
                                                style={{ backgroundColor: row.color }}
                                            />
                                        </div>
                                    </Tooltip>
                                    <span>{row.color}</span>
                                </td>
                                <td style={{ width: '15%' }}>{row.size}</td>
                                <td style={{ width: '15%' }}>{row.ageRange}</td>
                                <td>
                                    {row.stock} <br />
                                    <Badge color="red" onClick={() => handleStockModal(row)}>
                                        Manage Stock
                                    </Badge>
                                </td>
                                <td>
                                    0 <br />
                                    <Badge color="red" onClick={() => handlePriceModal(row)}>
                                        Manage price
                                    </Badge>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </TabPanel>
            <StockManageModal
                show={stockModal}
                setStockModal={setStockModal}
                productId={productId}
                variant={selectVariant}
            />
            <StockPriceManageModal
                show={priceModal}
                setPriceModal={setPriceModal}
                productId={productId}
                variant={selectVariant}
            />
        </>
    );
}
export default ProductPropertyVariantStock;
