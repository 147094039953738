import React, { useEffect, useRef } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import SmsTemplateConfig from '../../../../configs/SmsTemplateConfig';
import AxiosAuth from '../../../utils/AxiosAuth';

const setSmsTemplateConfig = ({ type, shopId, keyword, value }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SETTING_NOTIFICATION_TEMP_SET.replace(':shopId', shopId), {
            type,
            keyword,
            value,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function SmsTemplateConfigModal({
    show,
    setShow,
    shopId,
    smsKey,
    setSmskey,
    setNotifiListAr,
    configData,
}) {
    const refPrice = useRef();
    const SmsConfigData = SmsTemplateConfig[smsKey];

    const handleTempConfig = () => {
        setSmsTemplateConfig({
            value: refPrice.current.value,
            shopId,
            keyword: smsKey,
            type: 'sms',
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setNotifiListAr(response.data.notificationList);
                    setShow(false);
                    setSmskey(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (configData) {
            refPrice.current.value = configData;
        }
    }, [configData]);
    // console.log(configData);
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Config SMS Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    {SmsConfigData?.detail}
                    <br />
                    {SmsConfigData?.hint}
                </div>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Set Config</Form.Label>
                        <textarea
                            ref={refPrice}
                            className="form-control"
                            type="text"
                            placeholder="Set Config"
                        />
                    </Form.Group>
                    <button
                        type="button"
                        onClick={handleTempConfig}
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                    >
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default SmsTemplateConfigModal;
