import TabPanel from '@material-ui/lab/TabPanel';
import React from 'react';
import InitInfo from '../../../../utils/InitInfo';
import ProductManageSection from '../../../products/sections/ProductManageSection';

export default function CampaignPropertyProduct({ productData }) {
    const { categories } = InitInfo();
    return (
        <TabPanel value="1">
            <ProductManageSection productData={productData} categories={categories} />
        </TabPanel>
    );
}
