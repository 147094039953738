import { useTheme } from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

export default function ProductTagModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const history = useHistory();

    const theme = useTheme();
    const { productDetails } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const handleNextClick = () => {
        history.push(exiturl);
    };
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-variant"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body" />

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={[{ id: productDetails.id }]}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
