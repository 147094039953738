import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import NavDrawerToggleDispatch from '../../../dispatches/NavDrawerToggleDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import ProductManageCategorySection from '../sections/ProductManageCategorySection';
import ProductPageCategoryHeaderSection from '../sections/ProductPageCategoryHeaderSection';

const pageTitle = 'Product Category';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

async function initData(dispatch, shopId) {
    try {
        await AppInitDispatch(dispatch, shopId);
    } catch (err) {
        console.log(err);
    }
}
export default function ProductCategoryPage() {
    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();

    NavDrawerToggleDispatch(dispatch, false);
    const [categoryList, setCategoryList] = useState();
    const classes = useStyles();

    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;

    useEffect(() => {
        initData(dispatch, shopId, {
            shopId,
        });
        if (shopId) {
            AxiosAuth.get(ConfigApi.API_CATEGORY.replace(':shopId', shopId), { shopId })
                .then((response) => {
                    setCategoryList(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [dispatch, shopId, show]);
    console.log(categoryList);
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <ProductPageCategoryHeaderSection />
                    <ProductManageCategorySection categoryList={categoryList} />
                </MainSection>
            </div>
        </Preview>
    );
}
