import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    MenuList,
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ImageIcon from '@material-ui/icons/Image';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    spacing: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const creatingProduct = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_PRODUCTS, params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const getProductData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_PRODUCTS, {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function RecentProductList({ productList = [], classes, setTitleValue }) {
    return (
        <>
            <Typography variant="h6">Recent Products</Typography>

            <List className={classes.root}>
                {Array.isArray(productList) &&
                    productList?.map((row) => (
                        <ListItem key={row.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={row.title} secondary="Jan 9, 2014" />

                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments">
                                    <MenuList />
                                </IconButton>
                            </ListItemSecondaryAction>

                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => {
                                        setTitleValue(row.title);
                                    }}
                                >
                                    <FileCopyIcon value={row.title} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
            </List>
        </>
    );
}

export default function ProductCreateModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { shopId } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [productList, setProductList] = useState(null);
    const [titleValue, setTitleValue] = useState('');

    const handleValue = (event) => {
        setTitleValue(event.target.value);
    };

    const handleNextClick = () => {
        creatingProduct({ productName: titleValue, shopId })
            .then((response) => {
                // DataErrors(response, dispatch);
                if (response.data.error === 0 && response.data.id) {
                    history.push(next.replace(':id', response.data.id));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getProductData({ shopId })
            .then((response) => {
                setProductList(response.data.products);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [shopId]);

    return (
        <Modal
            {...props}
            className={['app-style', deviceClassName].join(' ')}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={titleValue}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Name of the Product
                        </Typography>
                        <TextField
                            required
                            label="Product Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the product name"
                            value={titleValue}
                            onChange={handleValue}
                        />

                        <div className="mt-3">
                            {Array.isArray(productList) && (
                                <RecentProductList
                                    productList={productList.slice(0, 5)}
                                    classes={classes}
                                    setTitleValue={setTitleValue}
                                />
                            )}
                        </div>
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={titleValue}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
