const initialState = {};

const UserInformationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_INIT_SET': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default UserInformationReducer;
