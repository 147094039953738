import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import CampaignCreateModal from '../modals/CampaignCreateModal';
import CampaignDescriptionSetModal from '../modals/CampaignDescriptionSetModal';
import CampaignDiscountSetModal from '../modals/CampaignDiscountSetModal';
import CampaignDurationModal from '../modals/CampaignDurationModal';
import CampaignImageSetModal from '../modals/CampaignImageSetModal';
import CampaignProductSetModal from '../modals/CampaignProductSetModal';

export default function CampaignManageModals() {
    const location = useLocation();
    const { shopId } = InitInfo();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopId}/campaigns/`}>
            <CampaignCreateModal path={`/${shopId}/campaigns/create/`} />
            <CampaignDurationModal path={`/${shopId}/campaigns/:id/update/duration/`} />
            <CampaignDiscountSetModal path={`/${shopId}/campaigns/:id/update/discount/`} />
            <CampaignDescriptionSetModal path={`/${shopId}/campaigns/:id/update/description/`} />
            <CampaignImageSetModal path={`/${shopId}/campaigns/:id/update/images/`} />
            <CampaignProductSetModal path={`/${shopId}/campaigns/:id/update/products/`} />
        </StepHandler>
    );
}
