import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { Link } from 'react-router-dom';

export default function ShopManageHeaderSection() {
    return (
        <div style={{ overflow: 'hidden' }}>
            <Link to="/shop/manage/create/">
                <Button variant="contained" color="secondary" style={{ float: 'right' }}>
                    <AddIcon style={{ marginRight: '8px' }} />
                    Create New
                </Button>
            </Link>

            <div className="page-common-title">Shop List</div>
            <hr />
        </div>
    );
}
