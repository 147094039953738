import { combineReducers } from 'redux';
import BackdropCommonReducer from './reducers/BackdropCommonReducer';
import CampaignDetailReducer from './reducers/CampaignDetailReducer';
import InitSetReducer from './reducers/InitSetReducer';
import NavDrawerReducer from './reducers/NavDrawerReducer';
import NavMenuReducer from './reducers/NavMenuReducer';
import ProductDetailsReducer from './reducers/ProductDetailsReducer';
import rewardInfoReducer from './reducers/rewardInfoReducer';
import ShopInfoReducer from './reducers/ShopInfoReducer';
import ShopInitReducer from './reducers/ShopInitReducer';
import SnackbarCommonReducer from './reducers/SnackbarCommonReducer';
import UserInformationReducer from './reducers/UserInformationReducer';

const rootReducer = combineReducers({
    activeSnackbar: SnackbarCommonReducer,
    activeBackdrop: BackdropCommonReducer,
    drawer: NavDrawerReducer,

    initData: InitSetReducer,
    shopData: ShopInitReducer,

    // cart: CartReducer,
    activeMenu: NavMenuReducer,
    productDetails: ProductDetailsReducer,
    shopInfo: ShopInfoReducer,
    campaignDetail: CampaignDetailReducer,
    rewardInfo: rewardInfoReducer,

    // allProducts:
    // iceCream: iceCreamReducer,
    userInformation: UserInformationReducer,
});

export default rootReducer;
