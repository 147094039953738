import store from '../store';

function setRewardDataToStore(payload) {
    return {
        type: 'REWARD_INFO_SET',
        payload,
    };
}

export default function RewardDataDispatch(dispatch, newData, callback) {
    const { productDetails } = store.getState();
    const newProductDetails = { ...productDetails, ...newData };
    dispatch(setRewardDataToStore(newProductDetails));

    if (typeof callback === 'function') {
        callback(newProductDetails);
    }
}
