import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import ProductManageModals from '../sections/ProductManageModals';
import ProductManageSection from '../sections/ProductManageSection';
import ProductPageHeaderSection from '../sections/ProductPageHeaderSection';

const pageTitle = 'List of Products/Manage Products';

const apiParams = {
    rows: 100,
    'page-no': 1,
};

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

const getProductData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_PRODUCTS, {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, params, setProductData) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getProductData(params);
        setProductData(response.data.products);
    } catch (err) {
        console.log(err);
    }
}

export default function ProductManagePage() {
    const dispatch = useDispatch();
    PageSetProperties(dispatch, pageTitle);

    const classes = useStyles();
    const { show, shopId, shopInfo, categories } = InitInfo();
    const { search } = useLocation();
    const [productData, setProductData] = useState(null);

    useEffect(() => {
        const urlParams = qs.parse(search);

        initData(
            dispatch,
            shopId,
            {
                shopId,
                ...apiParams,
                ...urlParams,
            },
            setProductData
        );
    }, [search, dispatch, shopId]);

    if (!productData) {
        return null;
    }
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <ProductPageHeaderSection shopInfo={shopInfo} />

                    <Preview placeholderModel="table" show={!!productData}>
                        <ProductManageSection
                            shopInfo={shopInfo}
                            productData={productData || []}
                            categories={categories}
                        />
                        <ProductManageModals />
                    </Preview>
                </MainSection>
            </div>
        </Preview>
    );
}
