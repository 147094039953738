/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import SupportDetailSection from '../section/SupportDetailSection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
const getSupportData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SUPPORT.replace(':shopId', params.shopId), params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const getSupportTicketData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(
            ConfigApi.API_SUPPORT_TICKET_MESSAGE.replace(':shopId', params.shopId).replace(
                ':ticketId',
                params.ticketId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const creatingTicketMessage = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SUPPORT_TICKET_MESSAGE_CREATE.replace(':shopId', params.shopId),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const completingTicket = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SUPPORT_TICKET_COMPLETE.replace(':shopId', params.shopId).replace(
                ':ticketId',
                params.ticketId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
async function initData(dispatch, shopId) {
    try {
        await AppInitDispatch(dispatch, shopId);
    } catch (err) {
        console.log(err);
    }
}
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};

export default function SupportDetailPage() {
    PageSetProperties('Support Detail');
    const dispatch = useDispatch();
    const history = useHistory();

    const { ticketId } = useParams();
    const classes = useStyles();
    const { show, shopId, shopInfo } = InitInfo();
    const [supportData, setSupportData] = useState([]);
    const [supportTicketMessageData, setSupportTicketMessageData] = useState([]);
    const [message, setMessage] = useState('');
    const [messageFile, setMessageFile] = useState('');

    const handleMessage = (event) => {
        setMessage(event.target.value);
    };
    const handleRemoveFile = () => {
        setMessageFile('');
        setMessage('');
    };

    useEffect(() => {
        initData(dispatch, shopId);
        setMessageFile('');
        setMessage('');
        getSupportData({ shopId })
            .then((response) => {
                setSupportData(response.data.supportAr);
            })
            .catch((err) => {
                console.log(err);
            });
        getSupportTicketData({ shopId, ticketId })
            .then((response) => {
                setSupportTicketMessageData(response.data.ticketMessages);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch, shopId, ticketId]);

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    setMessageFile(response.data.imageUrl);
                    setMessage(response.data.imageName);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const sendMessage = (e) => {
        e.preventDefault();
        creatingTicketMessage(dispatch, {
            shopId,
            ticketId,
            message,
            messageFile,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setSupportTicketMessageData(response.data.ticketMessages);
                    setMessage('');
                    setMessageFile('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const completeTicket = (e) => {
        e.preventDefault();
        completingTicket(dispatch, {
            shopId,
            ticketId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    handelSnackbarOpen(dispatch, response.data.message);
                    history.push(`/${shopId}/support/`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    {/* <SupportPageHeaderSection shopInfo={shopInfo} /> */}
                    <SupportDetailSection
                        supportData={supportData}
                        supportTicketMessageData={supportTicketMessageData}
                        handleMessage={handleMessage}
                        message={message}
                        sendMessage={sendMessage}
                        handleOnFileChange={handleOnFileChange}
                        messageFile={messageFile}
                        handleRemoveFile={handleRemoveFile}
                        completeTicket={completeTicket}
                    />
                </MainSection>
            </div>
        </Preview>
    );
}
