import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import RewardCreateProductModal from '../modals/RewardCreateProductModal';

export default function RewardAddProductModals({ productData }) {
    const location = useLocation();
    const { shopId } = InitInfo();
    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopId}/reward/`}>
            <RewardCreateProductModal
                productData={productData}
                path={`/${shopId}/reward/create/product/`}
            />
        </StepHandler>
    );
}
