/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

const setPaymentConfig = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SETTING_PAYMENT_OFFLINE.replace(':shopId', params.shopId), {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function OfflineGatewayModal({ show, setShow, shopId, getWayListAr, selectedData }) {
    const refAccName = useRef();
    const refType = useRef();
    const refAccNumber = useRef();
    const refBank = useRef();
    const refBtnText = useRef();
    const refBankSwift = useRef();

    const [iconVal, setIconVal] = useState();
    const uploadRef = createRef();
    const history = useHistory();

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    if (response.data.error === 0) {
                        setIconVal(response.data.imageUrl);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const handlePaymentConfig = () => {
        setPaymentConfig({
            type: refType?.current?.value,
            accName: refAccName?.current?.value,
            accNumber: refAccNumber?.current?.value,
            btnText: refBtnText?.current?.value,
            bankName: refBank?.current?.value,
            bankSwift: refBankSwift?.current?.value,
            icon: iconVal,
            shopId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShow(false);
                    history.push(`/${shopId}/settings/payment/`);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (selectedData?.id > 0) {
            refType.current.value = selectedData?.vendor;
            refAccName.current.value = selectedData?.api_json?.accName;
            refAccNumber.current.value = selectedData?.api_json?.accNumber;
            refBank.current.value = selectedData?.api_json?.bankName;
            if (selectedData?.api_json?.bankSwift) {
                refBankSwift.current.value = selectedData?.api_json?.bankSwift;
            }
            if (selectedData?.api_json?.btnText) {
                refBtnText.current.value = selectedData?.api_json?.btnText;
            }
        }
    }, [selectedData]);
    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Offline Payment Method</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Type</Form.Label>
                        <select ref={refType} className="form-control">
                            {getWayListAr?.map((row) => (
                                <option value={row?.vendor} key={row?.vendor}>
                                    {row?.title}
                                </option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Account Name</Form.Label>
                        <input
                            ref={refAccName}
                            className="form-control"
                            type="text"
                            placeholder="Account Name"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Account Number</Form.Label>
                        <input
                            ref={refAccNumber}
                            className="form-control"
                            type="text"
                            placeholder="Account Number"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Bank Name</Form.Label>
                        <input
                            ref={refBank}
                            className="form-control"
                            type="text"
                            placeholder="Bank Name"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Bank Swift</Form.Label>
                        <input
                            ref={refBankSwift}
                            className="form-control"
                            type="text"
                            placeholder="Bank Swift"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Button Text</Form.Label>
                        <input
                            ref={refBtnText}
                            className="form-control"
                            type="text"
                            placeholder="Button Text"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Icon</Form.Label>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Upload Icon"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                value={iconVal}
                            />
                            <input
                                ref={uploadRef}
                                type="file"
                                name="file"
                                onChange={handleOnFileChange}
                                multiple
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg,image/jpg,image/gif,image/svg+xml"
                            />
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    <CloudUploadIcon
                                        onClick={() => {
                                            uploadRef.current.click();
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <button
                            type="button"
                            onClick={handlePaymentConfig}
                            className="btn btn-primary mb-3"
                            style={{ float: 'right', marginBottom: 30 }}
                        >
                            Save Info
                        </button>
                    </Form.Group>
                    <Form.Group style={{ height: 20 }} />
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default OfflineGatewayModal;
