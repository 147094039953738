import React from 'react';
import DefaultPlaceholder from '../components/placeholders/DefaultPlaceholder';
import LoadingFullScreen from '../components/placeholders/LoadingFullScreen';
import TablePlaceholder from '../components/placeholders/TablePlaceholder';

export default function Preview({ children, placeholderModel, show, errorPage }) {
    if (errorPage) {
        return errorPage;
    }

    if (show) {
        return children;
    }

    if (placeholderModel === 'app-init') {
        return <LoadingFullScreen />;
    }

    if (placeholderModel === 'table') {
        return <TablePlaceholder />;
    }

    return <DefaultPlaceholder />;
}
