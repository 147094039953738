import React from 'react';

function SettingShippingChargeSection({ shippingConfigAr, setUpdateData, setShowModal }) {
    return (
        <table className="w-100 setting-payment">
            <thead>
                <tr>
                    <th>Country</th>
                    <th>City</th>
                    <th>Zip Code</th>
                    <th>Amount</th>
                    <th>Is Enable</th>
                    <th>Is Default</th>
                    <th>Priority</th>
                    <th>Status</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {shippingConfigAr?.map((row) => (
                    <tr key={row.sl}>
                        <td>{row.country}</td>
                        <td>{row.city}</td>
                        <td>{row.zip_code}</td>
                        <td>{row.amount}</td>
                        <td>{row.is_enable}</td>
                        <td>{row.is_default}</td>
                        <td>{row.priority}</td>
                        <td>{row.status}</td>
                        <td>
                            <button
                                type="button"
                                className="btn btn-xs btn-info"
                                onClick={() => {
                                    setShowModal(true);
                                    setUpdateData(row);
                                }}
                            >
                                Edit
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default SettingShippingChargeSection;
