import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import CustomerManageDetailPage from '../app/customers/pages/CustomerManageDetailPage';
import CustomerManagePage from '../app/customers/pages/CustomerManagePage';

export default function CustomerRouters() {
    const location = useLocation();

    return (
        <Switch>
            <Route path="/:shopId/customers/manage/:customId/detail">
                <CustomerManageDetailPage key={location.key} />
            </Route>
            <Route path="/:shopId/customers/manage/:customId/update/:modals">
                <CustomerManagePage />
            </Route>
            <Route path="/:shopId/customers/manage">
                <CustomerManagePage key={location.key} />
            </Route>
            <Route path="/:shopId/customers/manage/create/:modals">
                <CustomerManagePage />
            </Route>
        </Switch>
    );
}
