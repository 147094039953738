import { withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import TabPanel from '@material-ui/lab/TabPanel';
import React from 'react';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
        width: '50%',
        marginTop: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
        paddingTop: 25,
    },
}))(LinearProgress);

function ProductReview() {
    return (
        <div className="row">
            <div className="col-4">
                <div>
                    <h6>Average rating</h6>
                    <h3>0.5/5</h3>
                    <h6>
                        <StarIcon />
                        <StarIcon />
                        <StarIcon />
                        <StarHalfIcon />
                        <StarOutlineIcon />
                    </h6>
                    <p>(82.47k reviews)</p>
                </div>
            </div>
            <div className="col-8">
                <div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={60} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                    <div className="feedback-progress-bar">
                        <p>5 Star</p>
                        <BorderLinearProgress variant="determinate" value={50} />
                        <p>59.56k</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function ProductPropertyReview({ value }) {
    return (
        <TabPanel value={value}>
            <ProductReview />
        </TabPanel>
    );
}
