/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReportManagePage from '../app/reports/pages/ReportManagePage';
import ShopOverviewPage from '../app/shops/pages/ShopOverviewPage';
import ErrorPage from '../app/system/pages/ErrorPage';
import IndexPage from '../app/system/pages/IndexPage';
import CampaignRouters from './CampaignRouters';
import CustomerRouters from './CustomerRouters';
import FinanceRouters from './FinanceRouters';
import OrderRouters from './OrderRouters';
import ProductRouters from './ProductRouters';
import RewardRouters from './RewardRouters';
import SettingRouters from './SettingRouters';
import StockRouters from './StockRouters';
import SupportRouters from './SupportRouters';

export default function ShopRouters() {
    return (
        <Switch>
            <Route path="/" exact>
                <IndexPage />
            </Route>

            <Route path="/:shopId/overview">
                <ShopOverviewPage />
            </Route>

            <Route path="/:shopId/update/:modals">
                <ShopOverviewPage />
            </Route>

            <Route path="/:shopId/products">
                <ProductRouters />
            </Route>

            <Route path="/:shopId/orders">
                <OrderRouters />
            </Route>

            <Route path="/:shopId/customers">
                <CustomerRouters />
            </Route>

            <Route path="/:shopId/campaigns">
                <CampaignRouters />
            </Route>
            <Route path="/:shopId/finance">
                <FinanceRouters />
            </Route>

            <Route path="/:shopId/reports">
                <ReportManagePage />
            </Route>

            <Route path="/:shopId/settings">
                <SettingRouters />
            </Route>
            <Route path="/:shopId/support">
                <SupportRouters />
            </Route>
            <Route path="/:shopId/reward">
                <RewardRouters />
            </Route>
            <Route path="/:shopId/stock">
                <StockRouters />
            </Route>

            <Route path="*">
                <ErrorPage />
            </Route>
        </Switch>
    );
}
