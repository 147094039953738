/* eslint-disable no-undef */
/* eslint-disable no-await-in-loop */
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

const setPaymentConfig = ({ vendorTitle, subTitle, btnText, icon, shopId, type }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SETTING_PAYMENT_BASIC_SET.replace(':shopId', shopId), {
            vendorTitle,
            subTitle,
            btnText,
            icon,
            type,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
function PaymentBasicConfigModal({ show, setShow, shopId, type, title, configData }) {
    const refVTitle = useRef();
    const refSTitle = useRef();
    const refBtnText = useRef();
    const uploadRef = createRef();
    const [iconVal, setIconval] = useState();

    const handlePaymentConfig = () => {
        setPaymentConfig({
            vendorTitle: refVTitle?.current?.value,
            subTitle: refSTitle?.current?.value,
            btnText: refBtnText?.current?.value,
            icon: iconVal,
            shopId,
            type,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShow(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    if (response.data.error === 0) {
                        setIconval(response.data.imageUrl);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        if (configData) {
            refVTitle.current.value = configData?.vendor_title;
            refSTitle.current.value = configData?.sub_title;
            refBtnText.current.value = configData?.button_text;
            setIconval(configData?.icon);
        }
    }, [configData]);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {title} Payment Config Basic
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Vendor Title</Form.Label>
                        <input
                            ref={refVTitle}
                            className="form-control"
                            type="text"
                            placeholder="Vendor Title"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Sub Title</Form.Label>
                        <input
                            ref={refSTitle}
                            className="form-control"
                            type="text"
                            placeholder="Sub Title"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Button Text</Form.Label>
                        <input
                            ref={refBtnText}
                            className="form-control"
                            type="text"
                            placeholder="Button Text"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Icon</Form.Label>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Upload Icon"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                value={iconVal}
                            />
                            <input
                                ref={uploadRef}
                                type="file"
                                name="file"
                                onChange={handleOnFileChange}
                                multiple
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg,image/jpg,image/gif,image/svg+xml"
                            />
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                    <CloudUploadIcon
                                        onClick={() => {
                                            uploadRef.current.click();
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                    </Form.Group>
                    <button
                        type="button"
                        onClick={handlePaymentConfig}
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                    >
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default PaymentBasicConfigModal;
