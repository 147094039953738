import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import UserCreateModal from '../modals/UserCreateModal';

export default function UserModals() {
    const location = useLocation();
    const { shopInfo } = InitInfo();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/settings/user`}>
            <UserCreateModal path={`/${shopInfo.id}/settings/user/create/`} />
        </StepHandler>
    );
}
