import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Link } from 'react-router-dom';
import shopCover from '../../../../../assets/images/shop-cover.jpg';

export default function CustomerDetailOverViewInfo({ customerData, shopId }) {
    return (
        <>
            <Grid item xs={12}>
                <div className="shop-detail-cover">
                    <img
                        src={customerData.img_url ? customerData.img_url : shopCover}
                        alt="Shop Cover"
                    />
                </div>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <div className="shop-detail-title" style={{ paddingLeft: 0 }}>
                    <div>
                        <h6>{customerData.name}</h6>
                        <p>{customerData.default_email}</p>
                        <p>{customerData.default_contact}</p>
                    </div>
                    <div className="shop-detail-title-menu">
                        <Link to={`/${shopId}/customers/manage/${customerData.id}/update/`}>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        </Link>
                    </div>
                </div>
            </Grid>
        </>
    );
}
