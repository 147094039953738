import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../configs/ConfigApi';
import BackdropOpenDispatch from '../../dispatches/BackdropOpenDispatch';
import SnackbarOpenDispatch from '../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../utils/AxiosAuth';
import IsLoggedIn from '../../utils/IsLoggedIn';

// --Variables
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const loadLoginData = (callback) => {
    AxiosAuth.get(ConfigApi.API_LOGGING_IN)
        .then((response) => {
            if (typeof callback === 'function') {
                callback(response);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

// --Functions
const loggingIn = (data, callback) => {
    AxiosAuth.post(ConfigApi.API_LOGGING_IN, data)
        .then((response) => {
            if (callback) {
                callback(response);
            }

            // --Adding Secure Token
            if (response.headers['secure-access']) {
                localStorage.setItem('Secure-Access', response.headers['secure-access']);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://rupkotha.com.bd/">
                rupkotha.com.bd
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// --Handler
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};

const handelBackdropOpen = (dispatch) => {
    BackdropOpenDispatch(dispatch, true);
};

const handelBackdropClose = (dispatch) => {
    BackdropOpenDispatch(dispatch, false);
};

// --Render
export default function LoginPage() {
    // --Variables & Hooks
    const history = useHistory();
    const classes = useStyles();
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [stay, setStay] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Form Submitting
        handelBackdropOpen(dispatch);
        loggingIn(
            {
                user: emailAddress,
                password,
                stay,
                login_by: 'email',
            },
            (response) => {
                handelBackdropClose(dispatch);
                handelSnackbarOpen(dispatch, response.data.message);

                // --For 1st Log in check the data
                if (response.data.isLoggedIn) {
                    history.push('/');
                }
            }
        );
    };

    // --Effects
    // Empty array [] = Called only once
    // With Array Value = Called when changed
    // No array = Called each render
    useEffect(() => {
        handelBackdropOpen(dispatch);
        loadLoginData((response) => {
            handelBackdropClose(dispatch);
            if (IsLoggedIn(response)) {
                history.push('/');
            }
        });
    }, [dispatch, history]);

    // --Render
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={[classes.paper, 'login-area '].join(' ')}>
                <div className="login-logo">BIKIRAN.COM</div>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={emailAddress}
                        onChange={(e) => {
                            setEmailAddress(e.target.value);
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="remember"
                                color="primary"
                                checked={stay}
                                onChange={(e) => {
                                    setStay(e.target.checked);
                                }}
                            />
                        }
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                    >
                        Sign In
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}
