import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import CampaignDescriptionSetModal from '../modals/CampaignDescriptionSetModal';
import CampaignDiscountSetModal from '../modals/CampaignDiscountSetModal';
import CampaignDurationModal from '../modals/CampaignDurationModal';
import CampaignImageSetModal from '../modals/CampaignImageSetModal';
import CampaignProductSetModal from '../modals/CampaignProductSetModal';
import CampaignUpdateTitleModal from '../modals/CampaignUpdateTitleModal';

export default function CampaignUpdateModals() {
    const location = useLocation();
    const { shopId } = InitInfo();
    const { id } = useParams();
    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopId}/campaigns/${id}/detail/`}>
            <CampaignUpdateTitleModal path={`/${shopId}/campaigns/${id}/update/title/`} />
            <CampaignDurationModal path={`/${shopId}/campaigns/${id}/update/duration/`} />
            <CampaignDiscountSetModal path={`/${shopId}/campaigns/${id}/update/discount/`} />
            <CampaignDescriptionSetModal path={`/${shopId}/campaigns/${id}/update/description/`} />
            <CampaignImageSetModal path={`/${shopId}/campaigns/${id}/update/images/`} />
            <CampaignProductSetModal path={`/${shopId}/campaigns/${id}/update/products/`} />
        </StepHandler>
    );
}
