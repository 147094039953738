import { TableFooter, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import qs from 'query-string';
import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Optimizee from '../../../utils/Optimizee';
import PreviewCategories from '../comps/PreviewCategories';
import ProductPublishBtn from '../comps/ProductPublishBtn';
import ProductModalUpdateMenu from '../modals/comp/ProductModalUpdateMenu';

const useStyles = makeStyles((theme) => ({
    table: {
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

function ProductRow({ row, categoryList, shopInfo }) {
    const classes = useStyles();
    return (
        <TableRow className={[classes.tableRowHover, 'tableTr'].join(' ')}>
            <TableCell width="10" align="center">
                {row.id}
            </TableCell>

            <TableCell align="center">
                <Link to={`/${shopInfo.id}/products/${row.id}/details`}>
                    <img
                        className={classes.image}
                        src={Optimizee(row.default_image, 100)}
                        alt={row.title}
                    />
                </Link>
            </TableCell>

            <TableCell align="left">
                <Link to={`/${shopInfo.id}/products/${row.id}/details`}>
                    <Typography variant="h6">{row.title}</Typography>
                </Link>
                <PreviewCategories categoryList={categoryList} categories={row.categories} />
            </TableCell>

            <TableCell align="center">{row.price ? row.price : 0}</TableCell>
            <TableCell align="center">{0.0}</TableCell>
            <TableCell align="center">{row?.stock_qty}</TableCell>
            <TableCell align="center">
                <ProductPublishBtn data={row} />
            </TableCell>
            <TableCell align="center">
                <Link to=".">
                    <CachedIcon />
                </Link>
            </TableCell>
            <TableCell align="center">
                <Chip size="small" label="Active" color="primary" />
            </TableCell>
            <TableCell align="center">
                <ButtonGroup size="small">
                    <Button>
                        <Link to={`/${shopInfo.id}/products/${row.id}/details`}>
                            <OpenInNewIcon />
                        </Link>
                    </Button>
                    <Button>
                        {' '}
                        <ProductModalUpdateMenu productId={row.id} shopId={shopInfo.id} />
                    </Button>
                </ButtonGroup>
            </TableCell>
        </TableRow>
    );
}

export default function ProductListDesktopView({ productData, categoryList = [], shopInfo = {} }) {
    const classes = useStyles();
    const history = useHistory();
    const { search } = useLocation();
    const searchObj = qs.parse(search);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const handleChangePage = (event, newPage) => {
        searchObj['page-no'] = newPage + 1;
        history.push(`?${qs.stringify(searchObj)}`);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const totalProduct = productData.length;

    return (
        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="product-list-tab">
            <Tab eventKey="profile" title="Active Products">
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className="tableTr">
                                <TableCell width="30">ID</TableCell>
                                <TableCell width="96" />
                                <TableCell>Products</TableCell>
                                <TableCell width="8%" align="center">
                                    Price
                                </TableCell>

                                <TableCell width="8%" align="center">
                                    Sale
                                </TableCell>
                                <TableCell width="8%" align="center">
                                    Stock
                                </TableCell>

                                <TableCell width="8%" align="center">
                                    Published
                                </TableCell>
                                <TableCell width="8%" align="center">
                                    Tag Load
                                </TableCell>
                                <TableCell width="8%" align="center">
                                    Status
                                </TableCell>
                                <TableCell width="8%" colSpan="2" align="center">
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {productData
                                .filter((publish) => publish.is_published === 1)
                                .map((row) => (
                                    <ProductRow
                                        row={row}
                                        key={row.id}
                                        categoryList={categoryList}
                                        shopInfo={shopInfo}
                                    />
                                ))}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[50, 100, { label: 'All', value: -1 }]}
                                    colSpan={13}
                                    count={totalProduct}
                                    rowsPerPage={rowsPerPage}
                                    page={
                                        searchObj['page-no']
                                            ? parseInt(searchObj['page-no'], 10) - 1
                                            : 0
                                    }
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Tab>
            <Tab eventKey="contact" title="Inactive Products">
                <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className="tableTr">
                                <TableCell width="30">ID</TableCell>
                                <TableCell width="96" />
                                <TableCell>Products</TableCell>
                                <TableCell width="8%" align="center">
                                    Price
                                </TableCell>

                                <TableCell width="8%" align="center">
                                    Sale
                                </TableCell>
                                <TableCell width="8%" align="center">
                                    Stock
                                </TableCell>

                                <TableCell width="8%" align="center">
                                    Published
                                </TableCell>
                                <TableCell width="8%" align="center">
                                    Tag Load
                                </TableCell>
                                <TableCell width="8%" align="center">
                                    Status
                                </TableCell>
                                <TableCell width="8%" colSpan="2" align="center">
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {productData
                                .filter((publish) => publish.is_published === 0)
                                .map((row) => (
                                    <ProductRow
                                        row={row}
                                        key={row.id}
                                        categoryList={categoryList}
                                        shopInfo={shopInfo}
                                    />
                                ))}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[50, 100, { label: 'All', value: -1 }]}
                                    colSpan={13}
                                    count={totalProduct}
                                    rowsPerPage={rowsPerPage}
                                    page={
                                        searchObj['page-no']
                                            ? parseInt(searchObj['page-no'], 10) - 1
                                            : 0
                                    }
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Tab>
        </Tabs>
    );
}
