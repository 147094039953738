import { TextField, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const creatingShop = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_CAMPAIGN_CREATE.replace(':shopId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function CampaignCreateModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const [titleValue, setTitleValue] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const { shopId } = InitInfo();

    const handleValue = (event) => {
        setTitleValue(event.target.value.replace(/[^\w\s.]/gi, ''));
    };

    const handleNextClick = () => {
        creatingShop({ campaignName: titleValue, id: shopId }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0 && response.data.id) {
                history.push(next.replace(':id', response.data.id));
            }
        });
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader prev={prev} step={step} exiturl={exiturl} />
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">
                            Name of your Campaign, you want to create new.
                        </h4>
                        <TextField
                            required
                            label="Campaign Name"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the campaign name"
                            value={titleValue}
                            onChange={handleValue}
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={titleValue}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
