import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';

const pageTitle = 'Product Stock';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

export default function ProductStockPage() {
    // --Page Build
    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;

    const classes = useStyles();
    const { shopInfo } = InitInfo();

    return (
        <Preview placeholderModel="table" show>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <div>Stock Page</div>
                </MainSection>
            </div>
        </Preview>
    );
}
