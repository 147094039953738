import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Collapse,
    IconButton,
    Table,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    // eslint-disable-next-line prettier/prettier
    Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import qs from 'query-string';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import Optimizee from '../../../utils/Optimizee';
import PreviewCategories from '../comps/PreviewCategories';
import ProductPublishBtn from '../comps/ProductPublishBtn';
import ProductModalUpdateMenu from '../modals/comp/ProductModalUpdateMenu';

const useStyles = makeStyles((theme) => ({
    root: {},
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
        objectFit: 'cover',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

function ProductView({ data, categoryList, shopInfo }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                className="product-card-header"
                action={<ProductModalUpdateMenu id={shopInfo.id} />}
                title={
                    <Link to={`/${shopInfo.id}/products/${data.id}/details/`}>
                        <Typography
                            variant="body2"
                            style={{ fontSize: '15px', fontWeight: 'bold' }}
                            color="textSecondary"
                            component="p"
                        >
                            {data.title}
                        </Typography>
                    </Link>
                }
                subheader={data.total_price ? `Price:${data.total_price}` : ''}
            />

            <Link to={`/${shopInfo.id}/products/${data.id}/details/`}>
                <img className="product-card-image" src={Optimizee(data.default_image)} alt="" />
                <CardContent className="mob-product-prev-title">
                    Category:
                    {data.categories.length > 0 ? (
                        <PreviewCategories
                            categoryList={categoryList}
                            categories={data.categories}
                        />
                    ) : (
                        ' Not Set'
                    )}
                </CardContent>
            </Link>
            <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <ProductPublishBtn data={data} />
                </IconButton>
                <IconButton aria-label="add to favorites">
                    <Link to=".">
                        <CachedIcon />
                    </Link>
                </IconButton>
                <IconButton aria-label="share">
                    <Chip size="small" label="Active" color="primary" />
                </IconButton>
                <IconButton aria-label="share">
                    <Link to={`/${shopInfo.id}/products/${data.id}/details`}>
                        <OpenInNewIcon />
                    </Link>
                </IconButton>

                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph>Offer Price: 0.00</Typography>
                    <Typography paragraph>Sale Price: 0.00</Typography>
                    <Typography paragraph>Stock: 0</Typography>
                    <Typography paragraph>Rating: 4.9/5.0</Typography>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default function ProductListMobileView({ productData, categoryList }) {
    const { shopInfo } = InitInfo();
    const history = useHistory();
    const { search } = useLocation();
    const searchObj = qs.parse(search);

    const [rowsPerPage, setRowsPerPage] = useState(100);

    const handleChangePage = (event, newPage) => {
        searchObj['page-no'] = newPage + 1;
        history.push(`/rupkatha/products/manage/?${qs.stringify(searchObj)}`);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };
    const totalProduct = productData.length;
    console.log(productData);

    return (
        <>
            {productData.map((row) => (
                <div className="col-12 productMobile-card-col" key={row.id}>
                    <ProductView data={row} categoryList={categoryList} shopInfo={shopInfo} />
                </div>
            ))}

            <TableContainer>
                <Table aria-label="simple table" size="small">
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[50, 100, { label: 'All', value: -1 }]}
                                count={totalProduct}
                                rowsPerPage={rowsPerPage}
                                page={
                                    searchObj['page-no']
                                        ? parseInt(searchObj['page-no'], 10) - 1
                                        : 0
                                }
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage="Rows"
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}
