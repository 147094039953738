import { useParams } from 'react-router-dom';
import store from '../store';

function InitInfo() {
    const storeStates = store.getState();
    const params = useParams();
    const shopId = (params.shopId ? params.shopId : storeStates?.shopInfo?.id) || 'default';

    return {
        show: !!storeStates?.initData?.initId,
        storeStates,
        initData: storeStates?.initData,
        initId: storeStates?.initData?.initId,
        locale: storeStates?.initData?.locale,
        country: storeStates?.initData?.locale?.country,
        currency: storeStates?.initData?.locale?.currency,
        language: storeStates?.initData?.locale?.language,
        shopData: storeStates?.shopData,
        shopInfo: storeStates?.shopData?.shopInfo,
        shopId,
        categories: storeStates?.shopData?.categories,
        productId: storeStates?.productDetails.id,
        productDetails: storeStates?.productDetails,
        userInfo: storeStates?.userInformation,
    };
}

export default InitInfo;
