import {
    AppBar,
    Avatar,
    Badge,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Toolbar,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useScrollTrigger
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconApps from '@material-ui/icons/Apps';
import IconMenu from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import NavDrawerToggleDispatch from '../../dispatches/NavDrawerToggleDispatch';
import AppBarUserAvatar from '../combined/appbar/AppBarUserAvatar';

const drawerWidth = parseInt(process.env.REACT_APP_NAV_DRAWER_WIDTH, 10);

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function AppBarSection({ shopInfo }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleDrawerToggle = () => {
        NavDrawerToggleDispatch(dispatch);
    };

    return (
        <ElevationScroll>
            <AppBar position="fixed" className={classes.appBar} color="transparent">
                <Toolbar style={{ backgroundColor: '#FFF' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <IconMenu />
                    </IconButton>

                    <Typography variant="h6" noWrap>
                        <List className={classes.root}>
                            <ListItem>
                                <ListItemAvatar className="hide-avatar">
                                    <Avatar>{shopInfo?.shop_name?.slice(0, 1)}</Avatar>
                                </ListItemAvatar>
                                <Link to={`/${shopInfo.id}/overview/ `} className="link">
                                    <ListItemText primary={shopInfo.shop_name} />
                                </Link>
                            </ListItem>
                        </List>
                    </Typography>

                    <div className={classes.grow} />

                    <IconButton aria-label="show 4 new mails" color="inherit">
                        <IconApps />
                    </IconButton>

                    <IconButton aria-label="show 17 new notifications" color="inherit">
                        <Badge badgeContent={2} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>

                    <AppBarUserAvatar />
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    );
}

AppBarSection.prototype = {
    handleDrawerToggle: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default AppBarSection;
