// eslint-disable-next-line prettier/prettier
import { AppBar, Button, IconButton, makeStyles, TextField, Toolbar, Typography, useTheme } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowBack, Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { RewardDailyUse } from '../../../../configs/RewardType';
import RewardDataDispatch from '../../../dispatches/RewardDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    spacing: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const updateReward = (params, callback) => {
    AxiosAuth.post(
        ConfigApi.API_REWARD_OPTION_UPDATE.replace(':shopId', params.shopId).replace(
            ':rwId',
            params.rwId
        ),
        params
    )
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

const addReward = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_REWARD_OPTION_CREATE.replace(':shopId', params.shopId), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function RewardDailyUseModal(props) {
    const { prev, next, exiturl, selectedData } = props;
    const classes = useStyles();

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const { shopId } = InitInfo();

    const dispatch = useDispatch();
    const history = useHistory();
    const [rwType, setRwType] = useState('');
    const [rwPoint, setRwPoint] = useState('');
    const [rwExDate, setRwExDate] = useState('');

    const handleRwType = (event) => {
        setRwType(event.target.value);
    };
    const handleRwPoint = (event) => {
        setRwPoint(event.target.value);
    };
    const handleRwExDate = (event) => {
        setRwExDate(event.target.value);
    };
    const handleNextClick = () => {
        if (selectedData?.sl > 0) {
            // console.dir(next);
            updateReward(
                { shopId, rwPoint, rwType, rwExDate, rwId: selectedData?.sl },
                (response) => {
                    DataErrors(response, dispatch);
                    if (response.data.error === 0) {
                        // --Update Store
                        RewardDataDispatch(dispatch, {
                            percent: rwType,
                        });
                        history.push(exiturl);
                    }
                }
            );
        } else {
            addReward({ shopId, rwPoint, rwType, rwExDate }, (response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    // --Update Store
                    RewardDataDispatch(dispatch, {
                        percent: rwType,
                    });
                    history.push(exiturl);
                }
            });
        }
    };
    useEffect(() => {
        if (selectedData?.sl > 0) {
            setRwType(selectedData?.type);
            setRwPoint(selectedData?.reward_percent);
            setRwExDate(selectedData?.expired_date);
        }
    }, [selectedData]);
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <div className="app-style-header">
                        <AppBar position="static" color="transparent" elevation={0}>
                            <Toolbar>
                                <Link to={prev || exiturl}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        className={classes.spacing}
                                    >
                                        <ArrowBack />
                                    </IconButton>
                                </Link>

                                <Typography variant="h6" className={classes.title} noWrap>
                                    Add or Edit
                                </Typography>

                                <Link to={exiturl}>
                                    <IconButton color="inherit">
                                        <Close />
                                    </IconButton>
                                </Link>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <div className="app-style-body">
                        <TextField
                            required
                            label="Reward Type"
                            value={rwType}
                            onChange={handleRwType}
                            variant="outlined"
                            fullWidth
                            select
                            size="small"
                            helperText="Please enter Reward Type"
                            className="mb-3"
                        >
                            {RewardDailyUse.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            required
                            label="Reward Point"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter Reward Point"
                            value={rwPoint}
                            onChange={handleRwPoint}
                            className="mb-3"
                        />
                        <TextField
                            required
                            label="Expire Date"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter Expire Date"
                            value={rwExDate}
                            onChange={handleRwExDate}
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                    <div className="app-style-footer">
                        <Button
                            className="modal-footer-btn modal-footer-btn-fill"
                            onClick={(event) => {
                                handleNextClick(event, next);
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
