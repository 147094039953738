import { CssBaseline, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import DashboardStoreSelectSection from '../../../components/section/DashboardStoreSelectSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import ShopManageHeaderSection from '../../shops/sections/ShopManageHeaderSection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

const fetchShopList = () =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SHOP_LIST)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, history, setShopListData) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const shopList = await fetchShopList();
        setShopListData(shopList.data);
    } catch (err) {
        console.log(err);
        if (err.data.error === 1502) {
            history.push('/login/');
        }
    }
}

// General Parameter of Each Page
// 1. Title Set
// 2. Data Load (From DB) In a single File
// 3. Spinner until load Data

export default function IndexPage() {
    const dispatch = useDispatch();
    PageSetProperties(dispatch);
    const history = useHistory();
    const classes = useStyles();
    const { show, shopId, shopInfo } = InitInfo();
    const [shopListData, setShopListData] = useState(null);

    useEffect(() => {
        initData(dispatch, shopId, history, setShopListData);
    }, [dispatch, history, shopId]);
    // console.log(shopInfo);
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <ShopManageHeaderSection />
                    <DashboardStoreSelectSection
                        shopInfo={shopInfo}
                        shopListData={shopListData || []}
                    />
                </MainSection>
            </div>
        </Preview>
    );
}
