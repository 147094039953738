import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import SettingManageUserSection from '../section/SettingManageUserSection';
import SettingUserHeaderSection from '../section/SettingUserHeaderSection';
import UserModals from '../section/UserModals';
import UserUpdateModals from '../section/UserUpdateModals';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
const getUsersData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SETTING_USER_LIST.replace(':shopId', params.shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, history, params, setUserAr) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getUsersData(params);
        setUserAr(response.data.userData);
    } catch (err) {
        if (err.data.error === 1502) {
            history.push('/login/');
        }
    }
}
export default function SettingManageUserPage() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { show, shopId, shopInfo, shopData } = InitInfo();
    const [userAr, setUserAr] = useState([]);
    const [deleteRole, setDeletedRole] = useState('');
    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, history, { shopId }, setUserAr);
    }, [dispatch, history, shopId, deleteRole]);
    return (
        <Preview placeholderModel="table" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <SettingUserHeaderSection shopId={shopId} />
                    <SettingManageUserSection
                        userData={userAr}
                        shopId={shopId}
                        roleList={shopData.permissions}
                        setDeletedRole={setDeletedRole}
                    />
                </MainSection>
            </div>
            <UserModals />
            <UserUpdateModals />
        </Preview>
    );
}
