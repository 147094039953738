const RewardType = [
    {
        title: 'Sign Up Rewards',
        value: 'sign_up',
    },
    {
        title: 'Add to Favorite',
        value: 'favorite_add',
    },
    {
        title: 'Add to Cart',
        value: 'favorite_cart',
    },
    {
        title: 'Profile Complete',
        value: 'profile_complete',
    },
    {
        title: 'Address Add',
        value: 'address_add',
    },
    {
        title: 'Family Member Add',
        value: 'family_add',
    },
    {
        title: 'First Payment',
        value: 'first_payment',
    },
    {
        title: 'Notification ON',
        value: 'notification_on',
    },
    {
        title: 'Email/Mobile Verification',
        value: 'verification',
    },
];

export const RewardPurchaseType = [
    {
        title: '0 to 1000',
        value: '0_1000',
    },
    {
        title: '1001 to 10000',
        value: '1001_10000',
    },
    {
        title: '10001 to 50000',
        value: '10001_50000',
    },
];
export const RewardDailyUse = [
    {
        title: '15 sec Favorite List Visit',
        value: '15_sec_fav_visit',
    },
    {
        title: 'Cart Visit',
        value: 'cart_visit',
    },
    {
        title: 'Social Share',
        value: 'social_share',
    },
    {
        title: 'Daily Visit 5 Product Details',
        value: 'daily_5_pro_detail',
    },
];
export const RewardReview = [
    {
        title: 'Product Review',
        value: 'product_review',
    },
];
export default RewardType;
