const initialState = {
    //     // App Init Status
    //     appInit: true,
    //     // Product Data
    //     products: [],
    //     flushSaleProductSls: [],
    //     exclusiveProductSls: [],
    //     bestSaleProductSls: [],
    //     recentVisitedSls: [],
    //     interestedOnProductSls: [],
    //     // Categories
    //     categories: [],
    //     // Home Page
    //     featureImages: [],
    //     categoryOptions: [],
    //     // Product Page
    //     filterOptions: [],
    //     //--User Information
    //     cartProductSls: [],
    //     userBasicInformation:{},
    //     userAddresses: [],
};

const ShopInitReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOP_INIT_SET': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default ShopInitReducer;
