import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import CornUpdateModal from '../modals/CornUpdateModal';

export default function CornJobUpdateModals() {
    const location = useLocation();
    const history = useHistory();
    const { shopInfo } = InitInfo();
    const cornId = history.location.pathname.replace(/[^0-9]/g, '');
    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/settings/corn-jobs`}>
            <CornUpdateModal path={`/${shopInfo.id}/settings/corn-job/${cornId}/update/`} />
        </StepHandler>
    );
}
