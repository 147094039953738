/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */

import { TextField } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { createRef, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';

const updatingFeature = ({ shopId, category, title, iconVal }) =>
    new Promise((resolve, reject) => {
        console.log('ok');
        AxiosAuth.post(ConfigApi.API_PRODUCT_FEATURE_UPDATE.replace(':shopId', shopId), {
            shopId,
            category,
            title,
            iconVal,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function FeatureProductModal({ show, setShowFModal, categories, selectFProduct }) {
    const { shopId } = InitInfo();
    const uploadRef = createRef();

    const [category, setCategory] = useState('');
    const [iconVal, setIconval] = useState('');
    const [title, setTitle] = useState('');

    const handleCategory = (event, newValue) => {
        setCategory(newValue ? newValue.categoryId : '');
    };

    const handleTitle = (ev) => {
        setTitle(ev.target.value);
    };
    const handleFeature = () => {
        updatingFeature({
            shopId,
            category,
            title,
            iconVal,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShowFModal(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    if (response.data.error === 0) {
                        setIconval(response.data.imageUrl);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        if (selectFProduct) {
            setTitle(selectFProduct?.category_title);
            setIconval(selectFProduct?.image_url);
            setCategory(selectFProduct?.categoryId);
        }
    }, [categories, selectFProduct, shopId]);
    return (
        <Modal
            show={show}
            onHide={() => setShowFModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Feature Product Manage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Autocomplete
                    options={categories}
                    defaultValue={
                        categories.filter(
                            (item) => item.categoryId === selectFProduct?.categoryId
                        )[0]
                    }
                    getOptionLabel={(option) => option.view}
                    getOptionSelected={(option) => option.categoryId}
                    fullWidth
                    selectOnFocus={false}
                    clearOnBlur
                    onChange={handleCategory}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            required
                            label="Category"
                            variant="outlined"
                            fullWidth
                            helperText="Please enter the Feature category"
                            style={{ marginBottom: 20 }}
                        />
                    )}
                />
                <TextField
                    value={title}
                    size="small"
                    required
                    label="Category Custom Title"
                    variant="outlined"
                    fullWidth
                    helperText="Please enter Custom Title"
                    style={{ marginBottom: 10 }}
                    onChange={handleTitle}
                />
                <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                    <Form.Label className="custom-label">Icon</Form.Label>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Upload Icon"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            value={iconVal}
                        />
                        <input
                            ref={uploadRef}
                            type="file"
                            name="file"
                            onChange={handleOnFileChange}
                            multiple
                            style={{ display: 'none' }}
                            accept="image/png, image/jpeg,image/jpg,image/gif,image/svg+xml"
                        />
                        <div className="input-group-append">
                            <span className="input-group-text" id="basic-addon2">
                                <CloudUploadIcon
                                    onClick={() => {
                                        uploadRef.current.click();
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </Form.Group>

                <Form>
                    <img
                        style={{ width: 100, display: 'flex', margin: 'auto' }}
                        src={iconVal}
                        alt=""
                    />
                    <button type="button" onClick={handleFeature} className="stock-modal-btn">
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default FeatureProductModal;
