/* eslint-disable no-undef */
import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';

const orderPayment = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_ORDERS_CASH_PAYMENT.replace(':shopId', params.shopId).replace(
                ':orderId',
                params.orderId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};

export default function OrderPaymentModal({
    modalShow,
    handleModal,
    memoData,
    memoItem,
    shopId,
    paymentConfig,
}) {
    const history = useHistory();
    const orderId = memoData?.id;
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(
        memoData?.total_amount - memoData?.total_product_discount + memoData?.shipping_charge
    );
    const refAccount = useRef();

    const handleAmount = (event) => {
        setAmount(event.target.value);
    };
    const handleOrderPayment = () => {
        orderPayment({ shopId, orderId, amount, account: refAccount.current.value })
            .then((response) => {
                handelSnackbarOpen(dispatch, response.data.message);
                if (response.data.error === 0) {
                    history.push(`/${shopId}/orders/${orderId}/details`);
                    handleModal();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal
            show={modalShow}
            onHide={() => handleModal(false)}
            size="md"
            className="contained-modal-title-vcenter"
            centered
            scrollable
            style={{ zIndex: 1200, background: '#000000bd' }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <small>ORDER INV {memoData?.id}</small>
                    <br />
                    {memoItem?.product_title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-8">
                        Qty:{memoItem[0]?.quantity}
                        <br />
                        Price:{memoData?.total_amount}
                        <br />
                        Discount:{memoData?.total_product_discount}
                    </div>
                    <div className="col-12 mt-2">
                        <h6>Shipping Info</h6>
                        <table>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>: {memoData?.address_shipping_name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>: {memoData?.address_shipping_email}</td>
                                </tr>
                                <tr>
                                    <td>Contact</td>
                                    <td>: {memoData?.address_shipping_phone}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        : {memoData?.address_shipping_json?.line1},
                                        {memoData?.address_shipping_json?.line2}
                                    </td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>: {memoData?.address_shipping_json?.city}</td>
                                </tr>
                                <tr>
                                    <td>State/Region</td>
                                    <td>: {memoData?.address_shipping_json?.state}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>: {memoData?.address_shipping_json?.country}</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12 mt-2">
                        <h6>Billing Info</h6>
                        <table>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>: {memoData?.address_billing_name}</td>
                                </tr>
                                <tr>
                                    <td>Contact</td>
                                    <td>: {memoData?.address_billing_phone}</td>
                                </tr>
                                <tr>
                                    <td>Contact</td>
                                    <td>: {memoData?.address_billing_email}</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12 mt-2">
                        <Form>
                            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                                <Form.Label className="custom-label">Select Account</Form.Label>
                                <select ref={refAccount} className="form-control">
                                    {paymentConfig?.map((row) => (
                                        <option value={row?.vendor} key={row?.vendor}>
                                            {row?.vendor_title}-{row?.api_json?.accNumber}
                                        </option>
                                    ))}
                                </select>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                                <Form.Label className="custom-label">Amount</Form.Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Amount"
                                    onChange={handleAmount}
                                    value={amount}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleOrderPayment}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
