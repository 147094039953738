import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavDrawerToggleDispatch from '../../dispatches/NavDrawerToggleDispatch';
import DetectDevice from '../../utils/DetectDevice';
import NavBarItems from '../combined/navdrawer/NavDrawerItems';

const drawerWidth = parseInt(process.env.REACT_APP_NAV_DRAWER_WIDTH, 10);

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
}));

export default function NavigationDrawerSection({ shopInfo, window }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.drawer);
    const container = window !== undefined ? () => window().document.body : undefined;

    const handleDrawerToggle = () => {
        NavDrawerToggleDispatch(dispatch, false);
    };

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <DetectDevice>
                <Drawer
                    type="xs"
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={open}
                    onClose={handleDrawerToggle}
                >
                    <NavBarItems shopId={shopInfo.id} />
                </Drawer>
                <Drawer
                    type="sm|md|lg|xl"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <NavBarItems shopId={shopInfo.id} />
                </Drawer>
            </DetectDevice>
        </nav>
    );
}
