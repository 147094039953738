import { Tooltip } from '@material-ui/core';
import React, { useRef } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';

const updatingPriceProduct = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_VARIANT_PRICE_UPDATE.replace(':productId', params.productId),
            params
        )
            .then((response) => {
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        price_sale: params.productPrice,
                        vat_type: params.productVatType,
                        vat_percent: params.productVatPercent,
                    });
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function StockPriceManageModal({ show, setPriceModal, variant, productId }) {
    const dispatch = useDispatch();

    const refPrice = useRef();
    const handleVariantPrice = () => {
        updatingPriceProduct(dispatch, {
            productPrice: refPrice.current.value,
            variant,
            productId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setPriceModal(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal
            show={show}
            onHide={() => setPriceModal(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Price Manage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '15%', display: 'flex', alignItems: 'center' }}>
                                <Tooltip title="red">
                                    <div className="color-item">
                                        <button
                                            type="button"
                                            style={{ backgroundColor: variant?.color }}
                                        />
                                    </div>
                                </Tooltip>
                                <span>Red</span>
                            </td>
                            <td style={{ width: '15%' }}>{variant?.size}</td>
                            <td style={{ width: '15%' }}>{variant?.ageRange}</td>
                        </tr>
                    </tbody>
                </table>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Set Price</Form.Label>
                        <input
                            ref={refPrice}
                            className="form-control"
                            type="text"
                            placeholder="Set new Price"
                        />
                    </Form.Group>
                    <button
                        type="button"
                        onClick={handleVariantPrice}
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                    >
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default StockPriceManageModal;
