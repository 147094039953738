/* eslint-disable no-await-in-loop */

import { Button, TextField, useTheme } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { createRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';

export default function ShopWelcomeBannerModal({ show, setShow, setWelcomeBanner }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const uploadRef = createRef();
    const { shopId } = InitInfo();
    const [images, setImages] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleNextClick = () => {
        AxiosAuth.post(ConfigApi.API_SHOP_WELCOME_IMAGE_UPLOAD.replace(':shopId', shopId), {
            images,
            startDate,
            endDate,
        })
            .then((response) => {
                DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    setShow(false);
                    setWelcomeBanner(response.data.wc_banner_ar);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleStartDate = (event) => {
        setStartDate(event.target.value);
    };
    const handleEndDate = (event) => {
        setEndDate(event.target.value);
    };

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    setImages(response.data.imageUrl);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    console.log(endDate);
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            show={show}
            onHide={() => setShow(false)}
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">Update your shop Live.</h4>
                        <TextField
                            required
                            type="datetime-local"
                            label="Start Date"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter start date"
                            value={startDate}
                            onChange={handleStartDate}
                            className="mb-3"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            required
                            type="datetime-local"
                            label="End Date"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter end date"
                            value={endDate}
                            onChange={handleEndDate}
                            className="mb-3"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <img src={images} alt="" style={{ width: '100%' }} />
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            <PhotoCamera />
                        </Button>
                    </div>

                    <Button
                        className="modal-footer-btn modal-footer-btn-fill"
                        onClick={() => handleNextClick()}
                    >
                        Save
                    </Button>

                    <input
                        ref={uploadRef}
                        type="file"
                        name="file"
                        onChange={handleOnFileChange}
                        multiple
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
