import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';

function setCampaignDataToStore(payload) {
    return {
        type: 'CAMPAIGN_DETAILS_SET',
        payload,
    };
}

export function CampaignDataUpdateDispatch(dispatch, newData, callback) {
    const { campaignDetails } = store.getState();
    const newCampaignDetails = { ...campaignDetails, ...newData };
    dispatch(setCampaignDataToStore(newCampaignDetails));

    if (typeof callback === 'function') {
        callback(newCampaignDetails);
    }
}

export default function CampaignDataDispatch(id, dispatch) {
    return new Promise((resolve, reject) => {
        const { campaignDetail } = store.getState();

        if (campaignDetail.id !== parseInt(id, 10)) {
            AxiosAuth.get(ConfigApi.API_CAMPAIGN_DETAILS.replace(':campId', id))
                .then((response) => {
                    dispatch(setCampaignDataToStore(response.data));

                    resolve(response.data, response);
                })
                .catch((err) => {
                    reject(err);
                });
        } else {
            resolve(campaignDetail);
        }
    });
}
