import React from 'react';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import ProductVariantSetModal from './ProductVariantSetModal';

function multiplex(ar1, ar2) {
    const output = [];

    for (let x = 0; x < ar1.length; x += 1) {
        const valX = ar1[x];

        for (let y = 0; y < ar2.length; y += 1) {
            const valY = ar2[y];
            output.push({ ...valX, ...valY });
        }
    }
    return output;
}

function arrayToObj(key, ar = []) {
    return ar.map((item) => ({
        [key]: item,
    }));
}

function variantSampler(data = []) {
    const headers = Object.keys(data);
    let output = arrayToObj(headers[0], data[headers[0]]);

    for (let x = 1; x < headers.length; x += 1) {
        const arr = arrayToObj(headers[x], data[headers[x]]);
        output = multiplex(output, arr);
    }
    return output;
}

export default function ProductVariantModals({ shopInfo = {}, productDetails = {} }) {
    const location = useLocation();
    const variationHeader = productDetails.variation_header || [];
    // const headers = Object.keys(variationHeader);

    const variationRawData = variantSampler(variationHeader);

    return (
        <StepHandler
            pathname={location.pathname}
            exiturl={`/${shopInfo.id}/products/${productDetails.id}/details/`}
            loop={variationRawData.length}
        >
            <ProductVariantSetModal
                path={`/${shopInfo.id}/products/${productDetails.id}/update-variations/:loopIndex/`}
                variations={variationRawData}
            />
        </StepHandler>
    );
}
