export default function ReplaceMap(string = '', obj) {
    let newString = string;
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        newString = newString.replace(`:${key}`, obj[key]);
    }

    return newString;
}
