import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import UserUpdateModal from '../modals/UserUpdateModal';
import UserUpdatePasswordModal from '../modals/UserUpdatePasswordModal';
import UserUpdateRoleModal from '../modals/UserUpdateRoleModal';

export default function UserUpdateModals() {
    const location = useLocation();
    const history = useHistory();
    const { shopInfo } = InitInfo();
    const userId = history.location.pathname.replace(/[^0-9]/g, '');
    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/settings/user`}>
            <UserUpdateModal path={`/${shopInfo.id}/settings/user/${userId}/update/`} />
            <UserUpdatePasswordModal
                path={`/${shopInfo.id}/settings/user/${userId}/update/password/`}
            />
            <UserUpdateRoleModal path={`/${shopInfo.id}/settings/user/${userId}/update/role/`} />
        </StepHandler>
    );
}
