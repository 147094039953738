/* eslint-disable no-await-in-loop */
import { Button, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { createRef, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../../configs/ConfigApi';
import AppInitDispatch from '../../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../../utils/AxiosAuth';
import DataErrors from '../../../../utils/DataErrors';
import ShopWelcomeBannerModal from '../../modals/ShopWelcomeBannerModal';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function ShopProfile({ shopInfo }) {
    return (
        <table className="shop-profile-table">
            <tbody>
                <tr>
                    <td>Shop Name</td>
                    <td>: {shopInfo.shop_name}</td>
                </tr>
                <tr>
                    <td>Shop Contact</td>
                    <td>: {shopInfo.contact_number}</td>
                </tr>
                <tr>
                    <td>Shop Email</td>
                    <td>: {shopInfo.email_address}</td>
                </tr>
                <tr>
                    <td>Shop Address</td>
                    <td>: {shopInfo.address}</td>
                </tr>
            </tbody>
        </table>
    );
}
function BannerOption({
    sliderList,
    onImageRemove,
    uploadRef,
    handleOnFileChange,
    setMobileBannerId,
}) {
    return (
        <div className="banner-option">
            <div className="row">
                {sliderList?.map((item) => (
                    <div className="col-12 col-lg-6 col-xl-6 col-md-6" key={item.id}>
                        <div className="banner-option-image">
                            <div className="banner-option-mobile">
                                {item.image_url_mobile ? (
                                    <>
                                        <div className="image-item-btn">
                                            <div className="image-item-btn">
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => onImageRemove(item.id, 'mobile')}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <img src={item.image_url_mobile} alt="" />
                                    </>
                                ) : (
                                    <div className="banner-option-upload-btn">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                            onClick={() => {
                                                setMobileBannerId(item.id);
                                                uploadRef.current.click();
                                            }}
                                            // {...dragProps}
                                            // style={isDragging ? { color: 'red' } : null}
                                        >
                                            <PhotoCamera />
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="image-item-btn">
                                <div className="image-item-btn">
                                    <IconButton
                                        color="secondary"
                                        onClick={() => onImageRemove(item.id)}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <img src={item.img_url} alt="" />
                        </div>
                    </div>
                ))}
                <div className="col-12 col-lg-6 col-xl-6 col-md-6">
                    <div className="banner-option-upload-btn">
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                            // {...dragProps}
                            // style={isDragging ? { color: 'red' } : null}
                        >
                            <PhotoCamera />
                        </Button>
                        <input
                            ref={uploadRef}
                            type="file"
                            name="file"
                            onChange={handleOnFileChange}
                            multiple
                            style={{ display: 'none' }}
                            accept="image/png, image/jpeg,image/jpg"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
function WelcomeBannerOption({ welcomeBanner, setShow, shopId, setWelcomeBanner, dispatch }) {
    const classes = useStyles();

    const onImageRemove = (id) => {
        if (id) {
            AxiosAuth.post(
                ConfigApi.API_SHOP_WELCOME_IMAGE_REMOVE.replace(':shopId', shopId).replace(
                    ':imgId',
                    id
                )
            )
                .then((response) => {
                    DataErrors(response, dispatch);
                    if (response.data.error === 0) {
                        setShow(false);
                        setWelcomeBanner(response.data.wc_banner_ar);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    return (
        <div className="banner-option">
            <div className="row">
                <div className="col-12">
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() => setShow(true)}
                        className="mb-3"
                    >
                        Add New
                    </Button>
                </div>
                <div className="col-12 ">
                    <table style={{ width: '100%' }}>
                        <thead style={{ color: 'black' }}>
                            <tr>
                                <td>SL</td>
                                <td>Welcome Image</td>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody style={{ color: 'black' }}>
                            {welcomeBanner?.map((row) => (
                                <tr key={row.id}>
                                    <td>{row.id}</td>
                                    <td>
                                        <img src={row.image_url} alt="" style={{ height: 70 }} />
                                    </td>
                                    <td>{row.time_start}</td>
                                    <td>{row.time_end}</td>
                                    <td>
                                        {' '}
                                        <IconButton
                                            aria-label="delete"
                                            className={classes.margin}
                                            onClick={() => onImageRemove(row.id)}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
async function initData(dispatch, shopId, setSliderList, setWelcomeBanner) {
    try {
        await AppInitDispatch(dispatch, shopId);
        AxiosAuth.get(ConfigApi.API_SHOP_SLIDER_IMAGE.replace(':shopId', shopId))
            .then((response) => {
                setSliderList(response.data);
            })
            .catch((err) => {
                console.log(err);
            });

        AxiosAuth.get(ConfigApi.API_SHOP_WELCOME_IMAGE.replace(':shopId', shopId))
            .then((response) => {
                setWelcomeBanner(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (err) {
        console.log(err);
    }
}
// const createSliderImg = ({ imageUrl, shopId, setSliderList }) => {

// };
export default function ShopDetailPropertyAppBar({ shopInfo }) {
    const dispatch = useDispatch();
    const uploadRef = createRef();

    const [sliderList, setSliderList] = useState();
    const [welcomeBanner, setWelcomeBanner] = useState();
    const [mobileBannerId, setMobileBannerId] = useState();
    const [show, setShow] = useState(false);

    const onImageRemove = (id, type) => {
        AxiosAuth.post(
            ConfigApi.API_SHOP_SLIDER_IMAGE_REMOVE.replace(':shopId', shopInfo.id).replace(
                ':imgId',
                id
            ),
            { type }
        )
            .then((response) => {
                setSliderList(response.data.images_ar);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    console.log(response.data.imageUrl);
                    if (response.data.error === 0) {
                        AxiosAuth.post(ConfigApi.API_SHOP_SLIDER_IMAGE_UPLOAD, {
                            imageUrl: response.data.imageUrl,
                            shopId: shopInfo.id,
                            mobileBannerId,
                        })
                            .then((slidData) => {
                                setSliderList(slidData.data.images_ar);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    useEffect(() => {
        initData(dispatch, shopInfo.id, setSliderList, setWelcomeBanner);
    }, [dispatch, shopInfo.id]);

    return (
        <>
            <AppBar
                position="static"
                style={{ background: '#fff', boxShadow: 'none', padding: 40 }}
            >
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="shop-overview-header mb-3"
                >
                    <Tab eventKey="home" title="Home">
                        <ShopProfile shopInfo={shopInfo} />
                    </Tab>
                    <Tab eventKey="profile" title="Profile">
                        <ShopProfile shopInfo={shopInfo} />
                    </Tab>
                    <Tab eventKey="contact" title="Banner">
                        <BannerOption
                            sliderList={sliderList}
                            onImageRemove={onImageRemove}
                            uploadRef={uploadRef}
                            handleOnFileChange={handleOnFileChange}
                            setMobileBannerId={setMobileBannerId}
                        />
                    </Tab>
                    <Tab eventKey="welcome" title="Welcome Banner">
                        <WelcomeBannerOption
                            welcomeBanner={welcomeBanner}
                            setShow={setShow}
                            shopId={shopInfo?.id}
                            setWelcomeBanner={setWelcomeBanner}
                            dispatch={dispatch}
                        />
                        <ShopWelcomeBannerModal
                            show={show}
                            setShow={setShow}
                            setWelcomeBanner={setWelcomeBanner}
                        />
                    </Tab>
                </Tabs>
            </AppBar>
        </>
    );
}
