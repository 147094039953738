import { TextField, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const updateProductRewardPercent = (params, callback) => {
    AxiosAuth.post(
        ConfigApi.API_REWARD_PRODUCT_UPDATE.replace(':shopId', params.shopId).replace(
            ':rewardId',
            params.rewardId
        ),
        params
    )
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

const getRewardData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(
            ConfigApi.API_REWARD_DATA.replace(':shopId', params.shopId).replace(
                ':rewardId',
                params.rewardId
            )
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, setPercentValue, rewardId) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getRewardData({ shopId, rewardId });
        setPercentValue(response.data.reward_percent);
    } catch (err) {
        console.log(err);
    }
}
export default function RewardProductUpdateModal(props) {
    const { prev, next, step, paths, exiturl, rewardId } = props;

    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const { shopId } = InitInfo();

    const [percentValue, setPercentValue] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const handleValue = (event) => {
        setPercentValue(event.target.value);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updateProductRewardPercent({ shopId, percentValue, rewardId }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                // --Update Store
                history.push(exiturl);
            }
        });
    };
    console.log(rewardId);
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, setPercentValue, rewardId);
    }, [dispatch, history, rewardId, shopId]);

    return (
        <Preview placeholderModel="app-init" show={!!rewardId}>
            <Modal
                className={['app-style', deviceClassName].join(' ')}
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Body>
                    <div className="app-style-content header-footer-fixed">
                        <ProductModalHeader
                            prev={prev}
                            step={step}
                            exiturl={exiturl}
                            title="Reward Set"
                        />
                        <div className="app-style-body">
                            <h4 className="mb-3 mt-2 field-title">Update Reward Percent.</h4>
                            <TextField
                                required
                                label="Reward Percent"
                                variant="outlined"
                                fullWidth
                                size="small"
                                helperText="Please enter Percent"
                                value={percentValue}
                                onChange={handleValue}
                            />
                        </div>
                        <ProductModalFooter
                            next={next}
                            paths={paths}
                            step={step}
                            data={percentValue}
                            handleNextClick={handleNextClick}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </Preview>
    );
}
