import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import CornJobCreateModals from '../section/CornJobCreateModals';
import CornJobUpdateModals from '../section/CornJobUpdateModals';
import SettingCornJobHeaderSection from '../section/SettingCornJobHeaderSection';
import SettingCornJobSection from '../section/SettingCornJobSection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
const getCornJobsData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SETTING_CORN_JOBS.replace(':shopId', params.shopId), { params })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, params, setCornAr) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getCornJobsData(params);
        setCornAr(response.data.cornData);
    } catch (err) {
        console.log(err);
    }
}
export default function SettingManageCornJobPage() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();
    const [cornAr, setCornAr] = useState([]);

    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, { shopId }, setCornAr);
    }, [dispatch, shopId]);
    return (
        <Preview placeholderModel="table" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <SettingCornJobHeaderSection shopId={shopId} />
                    <SettingCornJobSection cornData={cornAr} shopId={shopId} />
                </MainSection>
            </div>
            <CornJobCreateModals />
            <CornJobUpdateModals />
        </Preview>
    );
}
