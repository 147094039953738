/* eslint-disable no-await-in-loop */
import { Button, IconButton, Typography, useTheme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { createRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const maxNumber = 10;

const updatingImagesProduct = (dispatch, params, productId) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_PRODUCT_IMG_UPDATE.replace(':productId', productId), params)
            .then((response) => {
                if (response.data.error === 0) {
                    // --Update Store
                    ProductDataUpdateDispatch(dispatch, {
                        default_image: params.images[0].url,
                        images: params.images,
                    });
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function ProductImagePrev({ image, index, onImageRemove }) {
    return (
        <div className="image-item">
            <div className="image-item-btn">
                <IconButton color="secondary" onClick={() => onImageRemove(index)}>
                    <DeleteForeverIcon />
                </IconButton>
            </div>
            <img src={image} alt="" width="100" />
        </div>
    );
}

export default function ProductImageSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const uploadRef = createRef();
    const { shopInfo, productDetails, productId } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [images, setImages] = useState(productDetails?.images || []);

    const onChange = (imageList) => {
        // data for submit
        setImages(imageList);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updatingImagesProduct(dispatch, { images, shopId: shopInfo.id }, productId)
            .then((response) => {
                if (response.data.error === 0) {
                    history.push(next);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        for (let x = 0; x < files.length; x += 1) {
            const formData = new FormData();
            formData.append('image', files[x]);
            await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    images.push({
                        id: response.data.imageId,
                        url: response.data.imageUrl,
                    });
                    setImages([...images]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Set Image
                        </Typography>
                        <div className="App">
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageRemoveAll,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        {imageList.length === 0 ? (
                                            <div className="image-item">
                                                <p
                                                    role="presentation"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        uploadRef.current.click();
                                                    }}
                                                >
                                                    Please Upload Product Image
                                                </p>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {images.map((image, index) => (
                                            <ProductImagePrev
                                                image={image.url}
                                                index={index}
                                                onImageRemove={onImageRemove}
                                                key={`uploadedImage_${Math.random(1, 100)}`}
                                            />
                                        ))}

                                        <div className="upload-btn">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                onClick={() => {
                                                    uploadRef.current.click();
                                                }}
                                                {...dragProps}
                                                style={isDragging ? { color: 'red' } : null}
                                            >
                                                <PhotoCamera />
                                            </Button>
                                            &nbsp;
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                onClick={onImageRemoveAll}
                                            >
                                                <DeleteForeverIcon />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </ImageUploading>

                            <input
                                ref={uploadRef}
                                type="file"
                                name="file"
                                onChange={handleOnFileChange}
                                multiple
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg"
                            />
                        </div>
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={images}
                        skip
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
