import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import CustomerManageSection from '../section/CustomerMamageSection';
import CustomerManageHeaderSection from '../section/CustomerManageHeaderSection';
import CustomerModals from '../section/CustomerModals';
import CustomerUpdateModals from '../section/CustomerUpdateModals';

const pageTitle = 'Customer List';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

const getCustomerData = (shopId) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_CUSTOMER_LIST.replace(':shopId', shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, setCustomerData) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getCustomerData(shopId);
        setCustomerData(response.data.userData);
    } catch (err) {
        console.log(err);
    }
}

export default function CustomerManagePage() {
    const dispatch = useDispatch();
    PageSetProperties(dispatch, pageTitle);

    const classes = useStyles();
    const { show, shopId, shopInfo } = InitInfo();
    const { search } = useLocation();
    const [customerData, setCustomerData] = useState([]);

    useEffect(() => {
        initData(dispatch, shopId, setCustomerData);
    }, [search, dispatch, shopId]);
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <CustomerManageHeaderSection shopInfo={shopInfo} headTitle="Customer List" />

                    <Preview placeholderModel="table" show={show}>
                        <CustomerManageSection customerData={customerData} shopId={shopId} />
                    </Preview>
                    <CustomerModals />
                    <CustomerUpdateModals />
                </MainSection>
            </div>
        </Preview>
    );
}
