import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

function ProductIndexPage() {
    const { shopId } = useParams();

    return <Redirect to={`/${shopId}/products/manage/`} />;
}

export default ProductIndexPage;
