/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import SupportSection from '../section/SupportSection';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
const getSupportData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_SUPPORT.replace(':shopId', params.shopId), params)
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
async function initData(dispatch, shopId, setSupportDepartment, setSupportData) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getSupportData({ shopId });
        setSupportDepartment(response.data.supportDepartment);
        setSupportData(response.data.supportAr);
    } catch (err) {
        console.log(err);
    }
}

export default function SupportPage() {
    PageSetProperties('Support');
    const dispatch = useDispatch();

    const classes = useStyles();
    const { show, shopId, shopInfo } = InitInfo();
    const [supportDepartment, setSupportDepartment] = useState([]);
    const [supportData, setSupportData] = useState([]);

    useEffect(() => {
        initData(dispatch, shopId, setSupportDepartment, setSupportData);
    }, [dispatch, shopId]);

    if (!supportData) {
        return null;
    }
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <SupportSection
                        supportData={supportData}
                        supportDepartment={supportDepartment}
                        shopId={shopId}
                    />
                </MainSection>
            </div>
        </Preview>
    );
}
