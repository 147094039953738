import {
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';

const UpdatingCornJobs = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SETTING_CORN_JOB_UPDATE.replace(':shopId', params.shopId).replace(
                ':cornId',
                params.cornId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const getCronJobsData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(
            ConfigApi.API_SETTING_CORN_JOB_DATA.replace(':shopId', params.shopId).replace(
                ':cornId',
                params.cornId
            ),
            {
                params,
            }
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

export default function CornUpdateModal(props) {
    const { prev, next, step, paths, exiturl } = props;
    const theme = useTheme();
    const history = useHistory();
    const cornId = history.location.pathname.replace(/[^0-9]/g, '');
    const { shopId } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [title, seTtitle] = useState('');
    const [detail, setDetail] = useState('');
    const [duration, setDuration] = useState('');
    const [startTime, setStartTime] = useState('');
    const [url, setUrl] = useState('');

    const handleValue = (event) => {
        if (event.target.name === 'title') {
            seTtitle(event.target.value);
        }
        if (event.target.name === 'detail') {
            setDetail(event.target.value);
        }
        if (event.target.name === 'duration') {
            setDuration(event.target.value);
        }
        if (event.target.name === 'startTime') {
            setStartTime(event.target.value);
        }
        if (event.target.name === 'url') {
            setUrl(event.target.value);
        }
    };

    const handleNextClick = () => {
        UpdatingCornJobs({ title, detail, duration, startTime, url, shopId, cornId })
            .then((response) => {
                // DataErrors(response, dispatch);
                if (response.data.error === 0) {
                    history.push(exiturl);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getCronJobsData({ shopId, cornId })
            .then((response) => {
                seTtitle(response.data.cornData.title);
                setDetail(response.data.cornData.description);
                setDuration(response.data.cornData.duration);
                setStartTime(response.data.cornData.next_time);
                setUrl(response.data.cornData.url);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [cornId, shopId]);

    return (
        <Modal
            {...props}
            className={['app-style', deviceClassName].join(' ')}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title="Update Corn Job"
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Title
                        </Typography>
                        <TextField
                            required
                            label="Jobs Title"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the job title"
                            value={title}
                            onChange={handleValue}
                            name="title"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            Description
                        </Typography>
                        <TextField
                            required
                            label="Jobs Description"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the Job Description"
                            value={detail}
                            onChange={handleValue}
                            name="detail"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            Duration
                        </Typography>
                        <TextField
                            required
                            label="Duration"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the duration in minutes"
                            value={duration}
                            onChange={handleValue}
                            name="duration"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            Start Time
                        </Typography>
                        <TextField
                            required
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the Start Time"
                            value={startTime}
                            onChange={handleValue}
                            type="datetime-local"
                            name="startTime"
                        />
                        <Typography className="mb-3 mt-2" variant="h6">
                            URL
                        </Typography>
                        <TextField
                            required
                            label="URL"
                            variant="outlined"
                            fullWidth
                            size="small"
                            helperText="Please enter the url"
                            value={url}
                            onChange={handleValue}
                            name="url"
                        />
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={startTime}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
