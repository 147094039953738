import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AxiosAuth from '../../../utils/AxiosAuth';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));
const runCronJobs = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(params.url, { params })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
function CornDataRow({ cornData, shopId }) {
    const classes = useStyles();
    let serial = 0;
    const [btnDisplay, setBtnDisplay] = useState('none');
    const [cornUrl, setCornUrl] = useState('');

    const handleApplyClick = (url) => {
        setCornUrl(url);
        setBtnDisplay('block');
        runCronJobs({ shopId, url })
            .then((response) => {
                if (response.data.error === 0) {
                    setBtnDisplay('none');
                    setCornUrl('');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleApplyAllClick = () => {
        setBtnDisplay('block');
        cornData.map((row) => {
            const { url } = row;
            setCornUrl(row.url);

            return runCronJobs({ shopId, url })
                .then((response) => {
                    if (response.data.error === 0) {
                        setBtnDisplay('none');
                        setCornUrl('');
                        setBtnDisplay('none');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    };
    return (
        <TableBody>
            {cornData.map((row) => {
                serial += 1;
                return (
                    <TableRow key={row.id} className={[classes.tableRowHover, 'tableTr'].join(' ')}>
                        <TableCell align="center">{serial}</TableCell>
                        <TableCell align="left">
                            {row.title}
                            <br />
                            {row.description}
                        </TableCell>
                        <TableCell align="center">{row.duration} MIN</TableCell>
                        <TableCell align="center">{row.next_time}</TableCell>
                        <TableCell align="center">
                            {row.last_time !== '0' ? row.last_time : '-'}
                        </TableCell>

                        <TableCell align="center">
                            <ButtonGroup size="small" aria-label="small button group">
                                <Button
                                    style={{
                                        width: btnDisplay === 'none' ? '' : '98px',
                                    }}
                                    onClick={() => handleApplyClick(row.url)}
                                >
                                    {cornUrl === row.url ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        'Apply Now'
                                    )}
                                </Button>
                                <Button>
                                    <Link to={`/${shopId}/settings/corn-job/${row.id}/update/`}>
                                        <EditIcon />
                                    </Link>
                                </Button>
                            </ButtonGroup>
                        </TableCell>
                    </TableRow>
                );
            })}
            <TableRow>
                <TableCell colSpan="6">
                    {' '}
                    <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="text-right"
                        style={{
                            display: 'block',
                        }}
                    >
                        <Button
                            onClick={handleApplyAllClick}
                            style={{
                                width: '140px',
                            }}
                        >
                            {btnDisplay === 'none' ? 'Apply All' : <CircularProgress size={20} />}
                        </Button>
                    </ButtonGroup>
                </TableCell>
            </TableRow>
        </TableBody>
    );
}

export default function SettingCornJobSection({ cornData, shopId }) {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow className="tableTr">
                        <TableCell>SL</TableCell>
                        <TableCell>Corn Jobs</TableCell>
                        <TableCell width="8%" align="center">
                            Duration
                        </TableCell>
                        <TableCell width="8%" align="center">
                            Last Run
                        </TableCell>
                        <TableCell width="8%" align="center">
                            Next Run
                        </TableCell>
                        <TableCell width="8%" align="center">
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>

                <CornDataRow cornData={cornData} shopId={shopId} />
            </Table>
        </TableContainer>
    );
}
