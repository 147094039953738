import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Label from '@material-ui/icons/Label';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import PermissionModal from './PermissionModal';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
    },
});

export default function SettingManagePermissionSection() {
    const classes = useStyles();
    const { shopId, shopData } = InitInfo();
    const [selectedType, setSelectedType] = useState('');
    const history = useHistory();
    const handletype = (value) => {
        setSelectedType(value);
        history.push(`/${shopId}/settings/permission/create/`);
    };
    return (
        <>
            <TreeView
                className={classes.root}
                defaultExpanded={['3']}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
            >
                {Object.keys(shopData.permissions).map((value) => (
                    <StyledTreeItem
                        nodeId={value}
                        keys={value}
                        labelText={shopData.permissions[value]}
                        labelIcon={Label}
                        labelInfo={<AddCircleOutlineIcon onClick={() => handletype(value)} />}
                    >
                        {shopData.permissionList &&
                            shopData.permissionList
                                .filter((e) => e.type === value)
                                .map((en) => (
                                    <StyledTreeItem
                                        keys={en.id}
                                        nodeId="5"
                                        labelText={en.has_permissions}
                                        labelIcon={KeyboardArrowRightIcon}
                                        color="#1a73e8"
                                        bgColor="#e8f0fe"
                                    />
                                ))}
                    </StyledTreeItem>
                ))}{' '}
            </TreeView>
            <PermissionModal type={selectedType} />
        </>
    );
}
