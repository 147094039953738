import { Card, CardContent, TextField } from '@material-ui/core';
import React from 'react';

export default function SettingManagePasswordPage() {
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <h5>Password Change</h5>
                </div>
                <div className="col-12 col-lg-4">
                    <Card>
                        <CardContent>
                            <h6 className="mb-2 mt-2 field-title">Old Password</h6>
                            <TextField
                                type="password"
                                required
                                label="Old Password"
                                variant="outlined"
                                fullWidth
                            />
                            <h6 className="mb-2 mt-2 field-title">New Password</h6>
                            <TextField
                                type="password"
                                required
                                label="New Password"
                                variant="outlined"
                                fullWidth
                            />
                            <h6 className="mb-2 mt-2 field-title">Retype New Password</h6>
                            <TextField
                                type="password"
                                required
                                label="Retype New Password"
                                variant="outlined"
                                fullWidth
                            />
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}
