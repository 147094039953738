import { TextField, useTheme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const creatingRewardProduct = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_REWARD_PRODUCT_CREATE.replace(':shopId', params.shopId), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function RewardCreateProductModal(props) {
    const { prev, next, step, paths, exiturl, productData } = props;
    const theme = useTheme();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const dispatch = useDispatch();
    const history = useHistory();
    const { shopId } = InitInfo();
    const [productId, setProductId] = useState('');
    const [percentValue, setPercentValue] = useState('');

    const handleProducts = (event, newValue) => {
        setProductId(newValue ? newValue.id : '');
    };
    const handleValue = (event) => {
        setPercentValue(event.target.value);
    };

    const handleNextClick = () => {
        creatingRewardProduct({ shopId, productId, percentValue }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                history.push(exiturl);
            }
        });
    };
    console.log(productData);
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader prev={prev} step={step} exiturl={exiturl} />
                    <div className="app-style-body">
                        <h4 className="mb-3 mt-2 field-title">Choose a product</h4>
                        <Autocomplete
                            options={productData}
                            getOptionLabel={(option) => option.title}
                            getOptionSelected={(option) => option.id}
                            fullWidth
                            selectOnFocus={false}
                            clearOnBlur
                            onChange={handleProducts}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value=""
                                    size="small"
                                    required
                                    label="Select Product"
                                    variant="outlined"
                                    fullWidth
                                    helperText="Please select a product"
                                />
                            )}
                        />
                        {productId && (
                            <TextField
                                required
                                label="Product Price"
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={
                                    productData.filter((e) => e.id === productId).map((e) => e)[0]
                                        .total_price
                                }
                                style={{ marginTop: '10px' }}
                                readonly
                            />
                        )}
                        {productId && (
                            <TextField
                                required
                                label="Reward Percent"
                                variant="outlined"
                                fullWidth
                                size="small"
                                helperText="Please enter Percent"
                                value={percentValue}
                                onChange={handleValue}
                                style={{ marginTop: '10px' }}
                            />
                        )}
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data="dddjjdj"
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
