/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconButton } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfigApi from '../../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../../utils/AxiosAuth';

const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function ProductModalUpdateMenu({ productId, shopId }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCache = () => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_CACHE.replace(':shopId', shopId).replace(':productId', productId),
            shopId
        )
            .then((response) => {
                setAnchorEl(null);
                handelSnackbarOpen(dispatch, response.data.message);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <IconButton
                aria-label="delete"
                style={{ float: 'right' }}
                onClick={handleClick}
                color="primary"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/title/`}
                        className="d-block w-100"
                    >
                        Update Title{' '}
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/category/`}
                        className="d-block w-100"
                    >
                        Update Category
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/sku/`}
                        className="d-block w-100"
                    >
                        Update SKU
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/description/`}
                        className="d-block w-100"
                    >
                        Update Description
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/prices/`}
                        className="d-block w-100"
                    >
                        Update Price
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/offer-prices/`}
                        className="d-block w-100"
                    >
                        Update Offer Price
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/images/`}
                        className="d-block w-100"
                    >
                        Update Image
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/variation/`}
                        className="d-block w-100"
                    >
                        Update Variant
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/property/`}
                        className="d-block w-100"
                    >
                        Update Property
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/tag/`}
                        className="d-block w-100"
                    >
                        Update Tag
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        to={`/${shopId}/products/${productId}/update/feature-image/`}
                        className="d-block w-100"
                    >
                        Set Feature Image
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleCache} style={{ color: '#405c69' }}>
                    Cache Update
                </MenuItem>
            </Menu>
        </>
    );
}
