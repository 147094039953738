import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import '../../../../assets/css/modal-app-style.css';
import '../../../../assets/css/style.css';
import CustomerDetailOverViewInfo from './customerDetail/CustomerDetailOverViewInfo';
import CustomerDetailPropertyInfo from './customerDetail/CustomerDetailPropertyInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tabStyle: {
        color: 'black',
    },
}));

export default function CustomerManageDetailSection({
    customerData,
    memoData,
    memoItemData,
    shopId,
}) {
    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <CustomerDetailOverViewInfo customerData={customerData} shopId={shopId} />
                    {memoData.map((row) => {
                        const memoItem = memoItemData.filter(
                            (mItem) => mItem.memoId.toString() === row.id.toString()
                        );
                        return (
                            <CustomerDetailPropertyInfo
                                memoItemData={memoItem}
                                memoData={row}
                                shopId={shopId}
                                key={row.id}
                            />
                        );
                    })}
                </Grid>
            </div>
        </>
    );
}
