import React from 'react';
import { useLocation } from 'react-router-dom';
import StepHandler from '../../../utils/StepHandler';
import ProductCategorySetModal from '../modals/ProductCategorySetModal';
import ProductDescriptionSetModal from '../modals/ProductDescriptionSetModal';
import ProductFeatureImgModal from '../modals/ProductFeatureImgModal';
import ProductImageSetModal from '../modals/ProductImageSetModal';
import ProductPriceOfferSetModal from '../modals/ProductPriceOfferSetModal';
import ProductPriceSetModal from '../modals/ProductPriceSetModal';
import ProductPropertyModal from '../modals/ProductPropertyModal';
import ProductSkuSetModal from '../modals/ProductSkuSetModal';
import ProductTagModal from '../modals/ProductTagModal';
import ProductUpdateTitleModal from '../modals/ProductUpdateTitleModal';
import ProductVariationModal from '../modals/ProductVariationModal';

export default function ProductUpdateModals({ shopInfo, productId }) {
    const location = useLocation();

    return (
        <StepHandler
            shopInfo={shopInfo}
            pathname={location.pathname}
            exiturl={`/${shopInfo.id}/products/${productId}/details/`}
        >
            <ProductUpdateTitleModal path={`/${shopInfo.id}/products/${productId}/update/title/`} />
            <ProductCategorySetModal
                path={`/${shopInfo.id}/products/${productId}/update/category/`}
            />
            <ProductSkuSetModal path={`/${shopInfo.id}/products/${productId}/update/sku/`} />
            <ProductDescriptionSetModal
                path={`/${shopInfo.id}/products/${productId}/update/description/`}
            />
            <ProductPriceSetModal path={`/${shopInfo.id}/products/${productId}/update/prices/`} />

            <ProductPriceOfferSetModal
                path={`/${shopInfo.id}/products/${productId}/update/offer-prices/`}
            />

            <ProductImageSetModal path={`/${shopInfo.id}/products/${productId}/update/images/`} />
            <ProductVariationModal
                path={`/${shopInfo.id}/products/${productId}/update/variation/`}
            />
            <ProductPropertyModal path={`/${shopInfo.id}/products/${productId}/update/property/`} />
            <ProductTagModal path={`/${shopInfo.id}/products/${productId}/update/tag/`} />
            <ProductFeatureImgModal
                path={`/${shopInfo.id}/products/${productId}/update/feature-image/`}
            />
        </StepHandler>
    );
}
