import { Switch } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

const createShippingCharge = ({ shopId, country, city, zip, amount, priority, isDafult }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SETTING_SHIPPING_CHARGE_CREATE.replace(':shopId', shopId), {
            country,
            city,
            zip,
            amount,
            priority,

            isDafult,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
const updateShippingCharge = ({
    shopId,
    shippingId,
    country,
    city,
    zip,
    amount,
    priority,
    isDefault,
}) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_SETTING_SHIPPING_CHARGE_UPDATE.replace(':shopId', shopId).replace(
                ':shippingId',
                shippingId
            ),
            {
                country,
                city,
                zip,
                amount,
                priority,
                isDefault,
            }
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function ShippingConfigModal({
    show,
    setShow,
    shopId,
    setShippingConfigAr,
    updateData,
    setUpdateData,
}) {
    const refCountry = useRef();
    const refCity = useRef();
    const refZip = useRef();
    const refAmount = useRef();
    const refPriority = useRef();

    const [isDefault, setIsDefault] = useState();
    const handleIsDafult = () => {
        const newVal = isDefault !== true;
        setIsDefault(newVal);
    };
    const handleShippingCreate = () => {
        createShippingCharge({
            shopId,
            country: refCountry.current?.value,
            city: refCity.current?.value,
            zip: refZip.current?.value,
            amount: refAmount.current?.value,
            priority: refPriority.current?.value,
            isDefault,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShow(false);
                    setShippingConfigAr(response.data.shippingCharge_ar);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleShippingUpdate = (shippingId) => {
        updateShippingCharge({
            shopId,
            shippingId,
            country: refCountry.current?.value,
            city: refCity.current?.value,
            zip: refZip.current?.value,
            amount: refAmount.current?.value,
            priority: refPriority.current?.value,
            isDefault,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShow(false);
                    setShippingConfigAr(response.data.shippingCharge_ar);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        if (updateData.sl > 10000000) {
            refCountry.current.value = updateData?.country || '';
            refCity.current.value = updateData?.city || '';
            refZip.current.value = updateData?.zip_code || '';
            refAmount.current.value = updateData?.amount || '';
            refPriority.current.value = updateData?.priority || '';
            setIsDefault(updateData?.is_default === 1);
        }
    }, [updateData]);
    // console.log(isDefault);
    return (
        <Modal
            show={show}
            onHide={() => {
                setShow(false);
                setUpdateData([]);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Set Shipping Charge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Country</Form.Label>
                        <input
                            ref={refCountry}
                            className="form-control"
                            type="text"
                            placeholder="Country"
                            value="BD"
                            readOnly
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">City</Form.Label>
                        <input
                            ref={refCity}
                            className="form-control"
                            type="text"
                            placeholder="City"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Zip Code</Form.Label>
                        <input
                            ref={refZip}
                            className="form-control"
                            type="text"
                            placeholder="Zip Code"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Amount</Form.Label>
                        <input
                            ref={refAmount}
                            className="form-control"
                            type="text"
                            placeholder="Amount"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Priority</Form.Label>
                        <input
                            ref={refPriority}
                            className="form-control"
                            type="text"
                            placeholder="Priority"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Is Default</Form.Label>
                        <Switch
                            checked={isDefault}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={handleIsDafult}
                            value={isDefault}
                        />
                    </Form.Group>
                    {updateData.sl > 10000000 ? (
                        <button
                            type="button"
                            onClick={() => handleShippingUpdate(updateData.sl)}
                            className="btn btn-primary"
                            style={{ float: 'right' }}
                        >
                            Update Info
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={() => handleShippingCreate}
                            className="btn btn-primary"
                            style={{ float: 'right' }}
                        >
                            Save Info
                        </button>
                    )}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ShippingConfigModal;
