/* eslint-disable no-return-assign */
import Chip from '@material-ui/core/Chip';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import StatusData from '../../../../configs/StatusData';
import FindInArray from '../../../utils/FindInArray';
import InitInfo from '../../../utils/InitInfo';
import StatusBtn from '../../../utils/StatusBtn';
import CartProductVariant from '../comps/ProductOrderVariant';
import OrderConfirmModal from '../modals/OrderConfirmModal';

function OrderManageSection({ memoData, memoItemData, productData }) {
    const [modalShow, setModalShow] = useState(false);
    const [statusSet, setStatusSet] = useState('');
    const { shopId } = InitInfo();

    const handleModal = () => {
        setStatusSet('');
    };
    // console.log(productData);

    const paidAmount = memoData.transaction_amount ? memoData.transaction_amount : 0;

    if (!productData) {
        return null;
    }
    const FindArr = new FindInArray();
    FindArr.init(productData, 'id');
    return (
        <>
            <div className="row" type="sm|xs|lg">
                <div
                    className="col-12"
                    style={{
                        borderBottom: '1px solid #e5dede',
                        marginBottom: '10px',
                        display: 'flex',
                    }}
                >
                    <div style={{ width: '90%' }}>
                        <Link to={`/${shopId}/orders/${memoData.id}/details`}>
                            <h5 className="user-orders-number">Order INV {memoData.id}</h5>
                            <h6 className="user-orders-time">Placed on {memoData.time_created}</h6>
                        </Link>
                    </div>
                    <div style={{ float: 'right' }}>
                        <StatusBtn
                            key={memoData.status}
                            status={memoData.status}
                            timeLineStatus
                            setModalShow={setModalShow}
                            setStatusSet={setStatusSet}
                        />
                    </div>
                </div>
                <div className="col-12">
                    {memoItemData &&
                        memoItemData.slice(0, 2).map((item) => {
                            const itemData = FindArr.find(item.productId);
                            console.log(item);
                            return (
                                <div className="row" key={item.id}>
                                    <div className="col-4 col-lg-2">
                                        <div className="user-orders-img">
                                            <img
                                                src={`https://www.optimizee.xyz/images/${btoa(
                                                    itemData?.default_image
                                                )},200,300.jpg`}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-8 col-lg-3 order-viewTitle">
                                        <b>{item.product_title}</b>
                                        <br />
                                        <ul className="variant-list">
                                            {item?.variation ? (
                                                <CartProductVariant
                                                    data={Object.values(item?.variation).slice(
                                                        0,
                                                        Object.keys(item?.variation).length - 2
                                                    )}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </div>
                                    <div className="d-lg-none d-xl-none col-4" />
                                    <div className="col-3 col-lg-2 order-viewQty">
                                        Qty:{item.quantity}
                                    </div>
                                    <div className="col-5 col-lg-3">
                                        Amount:
                                        {item.price && (item.price * item.quantity).toFixed(2)}
                                        <br />
                                        Paid:{paidAmount && paidAmount}
                                        <br />
                                        Discount:{(item?.price - item?.price_offer).toFixed(2)}
                                    </div>
                                    <div className="col-5 order-viewStatus">
                                        {statusSet && (
                                            <OrderConfirmModal
                                                modalShow={modalShow}
                                                handleModal={handleModal}
                                                memoData={memoData}
                                                memoItem={memoItemData}
                                                shopId={shopId}
                                                orStatus={statusSet}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>

                <div className="col-12">
                    <div className="form-row">
                        <div className="col-12 col-lg-4">
                            {memoItemData.length > 2 ? (
                                <Link to={`/${shopId}/orders/${memoData.id}/details`}>
                                    <Chip
                                        size="small"
                                        label={`More ${memoItemData.length - 2}`}
                                        clickable
                                        style={{
                                            fontSize: '10px',
                                            margin: '5px',
                                            color: 'rgb(27 149 138 / 97%)',
                                            background: 'rgb(0 150 136 / 38%)',
                                        }}
                                    />
                                </Link>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="col-12 col-lg-8">
                            {StatusData.map((value) => {
                                const timeLineStatus =
                                    memoData.timeLine &&
                                    memoData.timeLine.filter((e) => e === value).map((e) => e)[0];
                                return (
                                    <StatusBtn
                                        key={value}
                                        status={value}
                                        timeLineStatus={!!timeLineStatus}
                                        setModalShow={setModalShow}
                                        setStatusSet={setStatusSet}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OrderManageSection;
