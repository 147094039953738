import { Button, useTheme } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { createRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ImageUploading from 'react-images-uploading';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ShopInfoUpdateDispatch } from '../../../dispatches/ShopInfoDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const maxNumber = 1;

const updatingImagesProduct = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_SHOP_UPDATE_IMAGE.replace(':shopId', params.id), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

function ProductImagePrev({ image }) {
    return (
        <div className="image-item">
            <img src={image} alt="" width="100" />
        </div>
    );
}

export default function ShopImageSetModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const deviceClassName = `device-${deviceSize(useTheme())}`;
    const { shopInfo } = InitInfo();
    const [images, setImages] = useState(shopInfo.logo_url ? shopInfo.logo_url : '');
    const dispatch = useDispatch();
    const history = useHistory();
    const uploadRef = createRef();
    const onChange = (imageList) => {
        // data for submit
        setImages(imageList);
    };

    const handleNextClick = () => {
        // console.dir(next);
        updatingImagesProduct({ image: images, id: shopInfo.id }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                // --Update Store
                ShopInfoUpdateDispatch(dispatch, {
                    logo_url: images,
                });
                history.push(next);
            }
        });
    };

    // const handleFileUpload = () => {};

    const handleOnFileChange = async ({ target }) => {
        const { files } = target;
        const formData = new FormData();
        formData.append('image', files[0]);
        await AxiosAuth.post(ConfigApi.API_IMAGE_UPLOAD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                setImages(response.data.imageUrl);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={shopInfo.shop_name}
                    />
                    <div className="app-style-body">
                        <h4 className="mb-2 mt-2 field-title">Shop Logo</h4>
                        <div className="App">
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                            >
                                {({ imageList, onImageRemoveAll, isDragging, dragProps }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        {imageList.length === 0 ? (
                                            <div className="image-item">
                                                <p
                                                    role="presentation"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        uploadRef.current.click();
                                                    }}
                                                >
                                                    Please Upload Product Image
                                                </p>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <ProductImagePrev image={images} />

                                        <div className="upload-btn">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                onClick={() => {
                                                    uploadRef.current.click();
                                                }}
                                                {...dragProps}
                                                style={isDragging ? { color: 'red' } : null}
                                            >
                                                <PhotoCamera />
                                            </Button>
                                            &nbsp;
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                component="span"
                                                onClick={onImageRemoveAll}
                                            >
                                                <DeleteForeverIcon />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </ImageUploading>

                            <input
                                ref={uploadRef}
                                type="file"
                                name="file"
                                onChange={handleOnFileChange}
                                multiple
                                style={{ display: 'none' }}
                                accept="image/png, image/jpeg"
                            />
                        </div>
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={images}
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
