import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    MenuList,
    TextField,
    Typography,
    // eslint-disable-next-line prettier/prettier
    useTheme
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ImageIcon from '@material-ui/icons/Image';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import {
    ProductModalFooter,
    // eslint-disable-next-line prettier/prettier
    ProductModalHeader
} from '../../products/modals/comp/ProductModalHeaderFooter';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    spacing: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const addStockProduct = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_REWARD_PRODUCT_CREATE.replace(':shopId', params.shopId), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

const getProductCategory = (params, callback) => {
    AxiosAuth.get(
        ConfigApi.API_STOCK_PRODUCT_CATEGORY.replace(
            ':shopId',
            params.shopId,
            ':categoryId',
            params.categoryId
        ),
        params
    )
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

function RecentProductList({
    productList = [],
    classes,
    setTitleValue,
    productCategory = [],
    handleValue,
}) {
    return (
        <>
            <Typography variant="h6">Select a product</Typography>

            <List className={classes.root}>
                {Array.isArray(productCategory) &&
                    productCategory?.map((pCat) => {
                        const row = productList.filter(
                            (e) => e.id.toString() === pCat.productId.toString()
                        )[0];
                        console.log(row);
                        return (
                            <ListItem key={row?.id} onClick={() => handleValue(row.id)}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={row?.title} secondary="Jan 9, 2014" />

                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="comments">
                                        <MenuList />
                                    </IconButton>
                                </ListItemSecondaryAction>

                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => {
                                            setTitleValue(row?.title);
                                        }}
                                    >
                                        <FileCopyIcon value={row?.title} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
            </List>
        </>
    );
}

export default function StockAddProductModal(props) {
    const { prev, next, step, paths, exiturl, productData, categoryData } = props;
    const theme = useTheme();
    const classes = useStyles();
    const deviceClassName = `device-${deviceSize(theme)}`;
    const dispatch = useDispatch();
    const history = useHistory();
    const { shopId } = InitInfo();
    const [productId, setProductId] = useState('');
    const [percentValue] = useState('');
    const [categoryId, setCategoryId] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [titleValue, setTitleValue] = useState([]);

    const handleProducts = (event, newValue) => {
        setCategoryId(newValue ? newValue.categoryId : '');
        setCategoryList(categoryData.filter((e) => e.parentId === categoryId));
    };
    const handleProductsThree = (event, newValue) => {
        setCategoryId(newValue ? newValue.categoryId : '');
        setCategoryList(categoryData.filter((e) => e.parentId === categoryId));
        getProductCategory({ shopId, categoryId }, (response) => {
            setProductCategory(response.data);
        });
    };
    const handleValue = (event) => {
        setProductId(event);
        setCategoryId('');
        setProductCategory([]);
        setTitleValue(productData.filter((e) => e.id === event)[0].title);
    };

    const handleNextClick = () => {
        addStockProduct({ shopId, productId, percentValue }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                history.push(exiturl);
            }
        });
    };
    useEffect(() => {
        // Collect API Data
        setCategoryList(categoryData.filter((e) => e.parentId === categoryId));
        setProductList(productData);
    }, [categoryData, categoryId, dispatch, history, productData, shopId]);

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader prev={prev} step={step} exiturl={exiturl} />
                    <div className="app-style-body">
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/">
                                All
                            </Link>
                            <Link color="inherit" href="/getting-started/installation/">
                                Men
                            </Link>
                            <Link color="inherit" href="/getting-started/installation/">
                                Shirt
                            </Link>
                            <Link color="inherit" href="/getting-started/installation/">
                                Full Sleeve
                            </Link>
                        </Breadcrumbs>
                        <h4 className="mb-3 mt-2 field-title">Choose a product</h4>
                        {!productId && (
                            <>
                                <Autocomplete
                                    options={categoryList}
                                    getOptionLabel={(option) => option.category_name}
                                    getOptionSelected={(option) => option.categoryId}
                                    fullWidth
                                    selectOnFocus={false}
                                    clearOnBlur
                                    onChange={handleProducts}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value=""
                                            size="small"
                                            required
                                            label="Select Main Category"
                                            variant="outlined"
                                            fullWidth
                                            helperText="Please select main category"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    options={categoryList}
                                    getOptionLabel={(option) => option.category_name}
                                    getOptionSelected={(option) => option.categoryId}
                                    fullWidth
                                    selectOnFocus={false}
                                    clearOnBlur
                                    onChange={handleProducts}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value=""
                                            size="small"
                                            required
                                            label="Select Sub Category"
                                            variant="outlined"
                                            fullWidth
                                            helperText="Please Sub Category"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    options={categoryList}
                                    getOptionLabel={(option) => option.category_name}
                                    getOptionSelected={(option) => option.categoryId}
                                    fullWidth
                                    selectOnFocus={false}
                                    clearOnBlur
                                    onChange={handleProductsThree}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value=""
                                            size="small"
                                            required
                                            label="Select Sub Sub Category"
                                            variant="outlined"
                                            fullWidth
                                            helperText="Please select sub sub category"
                                        />
                                    )}
                                />
                            </>
                        )}
                        {productId && (
                            <>
                                <TextField
                                    required
                                    label="Product Name"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    helperText="Please enter the product name"
                                    value={titleValue}
                                    onChange={handleValue}
                                />
                                <TextField
                                    required
                                    label="Product Price"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    style={{ marginTop: '10px' }}
                                    readonly
                                />
                                <TextField
                                    required
                                    label="Reward Percent"
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    helperText="Please enter Percent"
                                    value={percentValue}
                                    onChange={handleValue}
                                    style={{ marginTop: '10px' }}
                                />
                            </>
                        )}
                        <div className="mt-3">
                            {Array.isArray(productData) && (
                                <RecentProductList
                                    productList={productList}
                                    classes={classes}
                                    setTitleValue={productData}
                                    productCategory={productCategory}
                                    handleValue={handleValue}
                                />
                            )}
                        </div>
                    </div>
                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data="dddjjdj"
                        handleNextClick={handleNextClick}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
