import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '../../../../../assets/images/Edit.svg';
import PhotoCam from '../../../../../assets/images/photo-camera.svg';
import shopCover from '../../../../../assets/images/shop-cover.jpg';
import ShopModalUpdateMenu from '../../modals/comp/ShopModalUpdateMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    rootTab: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '20px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    tabStyle: {
        color: 'black',
    },
}));
export default function ShopDetailOverViewInfo({ shopInfo }) {
    const classes = useStyles();
    console.log(shopInfo);
    return (
        <>
            <Grid item xs={12}>
                <div className="shop-detail-cover">
                    <div className="shop-detail-overly" />
                    <div className="shop-detail-logo">
                        <img src={shopInfo.logo_url} alt="Logo" />
                        <button type="button">
                            <Link to={`/${shopInfo.id}/update/banner/`}>
                                <img src={PhotoCam} alt="" />
                                Change Banner
                            </Link>
                        </button>
                    </div>
                    <div className="shop-detail-title">
                        <div>
                            <Avatar
                                className={classes.large}
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: 'red',
                                    paddingTop: 2,
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                {shopInfo.shop_name.slice(0, 1)}
                            </Avatar>
                            <div className="shop-detail-live" />
                        </div>
                        <div>
                            <h6>
                                {shopInfo.shop_name}{' '}
                                <Link to={`/${shopInfo.id}/update/title/`}>
                                    {' '}
                                    <img src={EditIcon} alt="" />
                                </Link>
                            </h6>
                            <p>@{shopInfo.id}</p>
                        </div>
                        <div className="shop-detail-title-menu">
                            <button type="button" className="shop-detail-social-menu">
                                <Link to={`/${shopInfo.id}/update/social/`}>Update Social</Link>
                            </button>
                            <button type="button" className="shop-detail-contact-menu">
                                <Link to={`/${shopInfo.id}/update/contact/`}>Update Contact</Link>
                            </button>
                            <button type="button" className="shop-detail-locale-menu">
                                <Link to={`/${shopInfo.id}/update/locale/`}>Update Locale</Link>
                            </button>

                            <ShopModalUpdateMenu id={shopInfo.id} />
                        </div>
                    </div>

                    <img
                        src={shopInfo.banner_url ? shopInfo.banner_url : shopCover}
                        alt="Shop Cover"
                    />
                </div>
            </Grid>
        </>
    );
}
