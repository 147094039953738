import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import RewardUpdateModal from '../modals/RewardUpdateModal';

export default function RewardUpdateModals() {
    const location = useLocation();
    const { shopId } = InitInfo();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopId}/reward/`}>
            <RewardUpdateModal path={`/${shopId}/reward/update`} />
        </StepHandler>
    );
}
