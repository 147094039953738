import { Button, makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfigApi from '../../../../configs/ConfigApi';
import BreadcrumbsCommon from '../../../components/basic/BreadcrumbsCommon';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import PageSetProperties from '../../../utils/PageSetProperties';
import Preview from '../../../utils/Preview';
import FeatureProductModal from '../modals/FeatureProductModal';
import ProductFeatureSection from '../sections/ProductFeatureSection';

const pageTitle = 'List of Products/Manage Products';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

function ProductFeatureHeaderSection({ shopInfo = {}, setShowFModal }) {
    return (
        <div style={{ overflow: 'hidden' }}>
            <Button
                variant="contained"
                color="secondary"
                style={{ float: 'right' }}
                onClick={() => setShowFModal(true)}
            >
                <AddIcon style={{ marginRight: '8px' }} />
                Create New
            </Button>

            <div className="page-common-title">Feature Products List</div>
            <BreadcrumbsCommon
                options={[
                    {
                        href: `/${shopInfo.id}/products/`,
                        title: 'Products',
                    },
                    {
                        href: `/${shopInfo.id}/products/manage/`,
                        title: 'Manage',
                    },
                ]}
            />
        </div>
    );
}
const getPaymentConfigData = ({ shopId }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_PRODUCT_FEATURE.replace(':shopId', shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

async function initData(dispatch, shopId, setFeatureProducts) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getPaymentConfigData({ shopId });
        setFeatureProducts(response.data);
    } catch (err) {
        console.log(err);
    }
}

export default function ProductFeaturePage() {
    const dispatch = useDispatch();
    PageSetProperties(dispatch, pageTitle);

    const classes = useStyles();
    const { show, shopId, shopInfo, categories } = InitInfo();
    const [featureProducts, setFeatureProducts] = useState();
    const [showFModal, setShowFModal] = useState(false);
    const [selectFProduct, setSelectFProduct] = useState('');

    useEffect(() => {
        initData(dispatch, shopId, setFeatureProducts);
    }, [dispatch, shopId, showFModal]);

    if (!featureProducts) {
        return null;
    }
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <ProductFeatureHeaderSection
                        shopInfo={shopInfo}
                        setShowFModal={setShowFModal}
                    />

                    <Preview placeholderModel="table" show={!!featureProducts}>
                        <ProductFeatureSection
                            categories={categories}
                            featureProducts={featureProducts}
                            setFeatureProducts={setFeatureProducts}
                            setSelectFProduct={setSelectFProduct}
                            setShowFModal={setShowFModal}
                            shopId={shopId}
                        />
                        <FeatureProductModal
                            show={showFModal}
                            setShowFModal={setShowFModal}
                            categories={categories}
                            selectFProduct={selectFProduct}
                        />
                    </Preview>
                </MainSection>
            </div>
        </Preview>
    );
}
