import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DraftsIcon from '@material-ui/icons/Drafts';
import LanguageIcon from '@material-ui/icons/Language';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PhoneIcon from '@material-ui/icons/Phone';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../../assets/css/store-select-section.css';
import ShopModalUpdateMenu from '../../app/shops/modals/comp/ShopModalUpdateMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 525,
    },
    rootTwo: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(7),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function DashboardStoreSelectSection({ shopListData: data, shopInfo }) {
    const classes = useStyles();
    return (
        <div className={classes.rootTwo}>
            <Grid container spacing={3}>
                {data.map((store) => (
                    <Grid key={store.id} item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div className="prev-store">
                            <List className={classes.root}>
                                <ListItem>
                                    <Link to={`/${store.id}/overview/ `} className="link">
                                        <ListItemAvatar>
                                            <Avatar>{store.shop_name.slice(0, 1)}</Avatar>
                                        </ListItemAvatar>
                                    </Link>
                                    <Link to={`/${store.id}/overview/ `} className="link">
                                        <ListItemText
                                            primary={store.shop_name}
                                            secondary={`@${store.id}`}
                                        />
                                    </Link>
                                    <div
                                        style={{
                                            flexGrow: 1,
                                        }}
                                    />

                                    <ShopModalUpdateMenu id={store.id} />
                                </ListItem>
                            </List>

                            <hr />

                            <List className={classes.root}>
                                <ListItem button key={1}>
                                    <ListItemIcon>
                                        <NotificationsNoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Notification" />
                                    <IconButton aria-label="delete" color="primary">
                                        <Badge edge="end" badgeContent={4} color="error" />
                                    </IconButton>
                                </ListItem>
                                <ListItem button key={2}>
                                    <ListItemIcon>
                                        <DraftsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Messages" />
                                    <IconButton aria-label="delete" color="primary">
                                        <Badge badgeContent={5} color="error" />
                                    </IconButton>
                                </ListItem>
                                <ListItem button key={3}>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={shopInfo.contact_number} />
                                </ListItem>
                                <ListItem button key={4}>
                                    <ListItemIcon>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <Link to="." className="link">
                                        <ListItemText primary="www.rupkotha.com" />
                                    </Link>
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}
