import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrderManageDetailPage from '../app/orders/pages/OrderManageDetailPage';
import OrderManagePage from '../app/orders/pages/OrderManagPage';

export default function OrderRouters() {
    return (
        <Switch>
            <Route path="/:shopId/orders/:orderId/details">
                <OrderManageDetailPage />
            </Route>
            <Route path="/:shopId/orders/recent">
                <OrderManagePage type="recent" />
            </Route>
            <Route path="/:shopId/orders/pending">
                <OrderManagePage type="pending" />
            </Route>
            <Route path="/:shopId/orders/active">
                <OrderManagePage type="active" />
            </Route>
            <Route path="/:shopId/orders/processing">
                <OrderManagePage type="processing" />
            </Route>
            <Route path="/:shopId/orders/prepared">
                <OrderManagePage type="prepared" />
            </Route>
            <Route path="/:shopId/orders/shipped">
                <OrderManagePage type="shipped" />
            </Route>
            <Route path="/:shopId/orders/delivered">
                <OrderManagePage type="delivered" />
            </Route>
            <Route path="/:shopId/orders/canceled">
                <OrderManagePage type="canceled" />
            </Route>
            <Route path="/:shopId/orders/refunded">
                <OrderManagePage type="refunded" />
            </Route>
        </Switch>
    );
}
