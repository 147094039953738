import Avatar from '@material-ui/core/Avatar';
import { deepPurple } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import AppBarUserOptions from './AppBarUserOptions';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
        textAlign: 'center',
        width: '300px',
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    avatar: {
        margin: '0px auto',
        marginTop: '30px',
        marginBottom: '15px',
    },
}));

export default function TemplateInstantPop({ open, anchorEl, handleClose }) {
    const classes = useStyles();

    return (
        <Popover
            id="user-options"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <div className={classes.typography}>
                <Avatar className={[classes.purple, classes.large, classes.avatar].join(' ')}>
                    KB
                </Avatar>

                <div style={{ marginBottom: '15px' }}>
                    <b style={{ textTransform: 'uppercase' }}>Kumar Bishojit Paul</b>
                    <br />
                    <span style={{ fontSize: '80%', color: '#666' }}>8801925363333</span>
                </div>

                <Divider />

                <AppBarUserOptions handleClose={handleClose} />
            </div>
        </Popover>
    );
}
