/* eslint-disable react/no-unknown-property */
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import DetectDevice from '../../../utils/DetectDevice';
import ProductListDesktopView from './ProductListDesktopView';
import ProductListMobileView from './ProductListMobileView';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function ProductManageSection({ productData = [], shopInfo = {}, categories }) {
    const classes = useStyles();

    return (
        <DetectDevice>
            <div type="lg|xl|md" className={classes.tableContainer} component={Paper}>
                <ProductListDesktopView
                    productData={productData}
                    categoryList={categories}
                    shopInfo={shopInfo}
                />
            </div>

            <div className="row" type="xs|sm">
                <ProductListMobileView
                    productData={productData}
                    categoryList={categories}
                    shopInfo={shopInfo}
                />
            </div>
        </DetectDevice>
    );
}
