const SmsTemplateConfig = {
    onCreateAccount: {
        detail: '12222',
        hint: '',
    },

    onChangePassword: {
        detail: '12222',
        hint: '',
    },

    onPaymentCreate: {
        detail: '12222',
        hint: '',
    },

    onPaymentSuccess: {
        detail: '12222',
        hint: '',
    },

    onRefundCreate: {
        detail: '12222',
        hint: '',
    },

    onRefundSuccess: {
        detail: '12222',
        hint: '',
    },

    onCreateOrder: {
        detail: '12222',
        hint: 'Hello {{name}}, your order #{{orderNo}} has been created. Order amount {{amount}}.\n\n{{shopName}},\n{{defaultContract}}',
    },

    onConfirmOrder: {
        detail: '12222',
        hint: '',
    },

    onShippedOrder: {
        detail: '12222',
        hint: '',
    },

    onDeliverOrder: {
        detail: '12222',
        hint: '',
    },

    onCancelOrder: {
        detail: '12222',
        hint: '',
    },
};
export default SmsTemplateConfig;
