import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NoProductImg from '../../../../assets/images/NoProduct2.png';
import ConfigApi from '../../../../configs/ConfigApi';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import CampaignManageHeaderSection from '../section/CampaignManageHeaderSection';
import CampaignManageModals from '../section/CampaignManageModals';
import CampaignManageSection from '../section/CampaignManageSection';

const pageTitle = 'Campaign Manage';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
const getShopCampaignData = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_CAMPAIGN.replace(':shopId', params.shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
async function initData(dispatch, shopId, params, setCampDataAr) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getShopCampaignData(params);

        setCampDataAr(response.data);
    } catch (err) {
        console.log(err);
    }
}
export default function CampaignManagePage() {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [campDataAr, setCampDataAr] = useState([]);
    const { show, shopId, shopInfo } = InitInfo();

    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, { shopId }, setCampDataAr);
    }, [dispatch, history, shopId]);

    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <CampaignManageHeaderSection shopId={shopId} />
                    {campDataAr.length !== 0 ? (
                        <CampaignManageSection campDataAr={campDataAr} shopId={shopId} />
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItem: 'center',
                                flexWrap: 'wrap',
                                textAlign: 'center',
                            }}
                        >
                            <img
                                src={NoProductImg}
                                alt="No Product"
                                width="300px"
                                style={{ marginTop: '10px' }}
                            />
                            <p style={{ width: '100%', fontSize: '20px' }}>No Campaign</p>
                        </div>
                    )}
                </MainSection>
                <CampaignManageModals />
            </div>
        </Preview>
    );
}
