import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import { RewardDailyUse } from '../../../../configs/RewardType';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import RewardDailyUseModal from '../modals/RewardDailyUseModal';
import RewardManageHeaderSection from '../Section/RewardManageHeaderSection';

const pageTitle = 'Report Manage';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));
function RewardDailyUseSection({ showModal, setShowModal, shopId, optionData }) {
    const [selectedData, setSelectedData] = useState([]);
    let serial = 0;
    return (
        <>
            {/* <button
                type="button"
                className="btn btn-xs btn-primary float-right m-4"
                onClick={() => setShowModal(true)}
            >
                Add New
            </button> */}
            <table className="w-100 setting-payment" style={{ marginTop: 20 }}>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Option Title</th>
                        <th>Reward Point</th>
                        <th>Expire Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(RewardDailyUse)?.map((row) => {
                        // eslint-disable-next-line no-unreachable
                        serial += 1;
                        const rType = optionData.filter((e) => e.type === row.value)[0];
                        return (
                            <tr key={rType?.sl}>
                                <td>{serial}</td>
                                <td>{row.title}</td>
                                <td>{rType?.reward_percent ? rType?.reward_percent : '0'}</td>
                                <td>{rType?.expired_date}</td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-xs btn-info"
                                        onClick={() => {
                                            setSelectedData(rType);
                                            setShowModal(true);
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <RewardDailyUseModal
                show={showModal}
                exiturl={`/${shopId}/reward/daily-uses`}
                selectedData={selectedData}
            />
        </>
    );
}
const getRewardOptionData = ({ shopId }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.get(ConfigApi.API_REWARD_OPTION.replace(':shopId', shopId))
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
async function initData(dispatch, shopId, setOptionData) {
    try {
        await AppInitDispatch(dispatch, shopId);
        const response = await getRewardOptionData({ shopId });
        setOptionData(response.data);
    } catch (err) {
        console.log(err);
    }
}
export default function RewardDailyUses() {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();
    const [optionData, setOptionData] = useState([]);

    const [showModal, setShowModal] = useState(false);
    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, setOptionData);
    }, [dispatch, history, setOptionData, shopId]);

    document.title = `${pageTitle} || ${process.env.REACT_APP_COMPANY_TITLE}`;
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <RewardManageHeaderSection shopInfo={shopInfo} title="Daily Uses Reward" />
                    <RewardDailyUseSection
                        showModal={showModal}
                        setShowModal={setShowModal}
                        shopId={shopId}
                        optionData={optionData}
                    />
                </MainSection>
            </div>
        </Preview>
    );
}
