import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';

const orderConfirm = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_ORDERS_CONFIRM.replace(':shopId', params.shopId).replace(
                ':orderId',
                params.orderId
            ),
            params
        )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
export default function OrderConfirmModal({
    modalShow,
    handleModal,
    memoData,
    memoItem,
    shopId,
    orStatus,
}) {
    const history = useHistory();
    const orderId = memoData.id;
    const dispatch = useDispatch();
    const [status] = useState(orStatus);
    const [comment, setComment] = useState('');

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };
    const handleOrderConfirm = () => {
        orderConfirm({ shopId, orderId, status, comment })
            .then((response) => {
                handelSnackbarOpen(dispatch, response.data.message);
                if (response.data.error === 0) {
                    history.push(`/${shopId}/orders/${status}`);
                    handleModal();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <Modal
            show={modalShow}
            onHide={handleModal}
            size="md"
            className="contained-modal-title-vcenter"
            centered
            scrollable
            style={{ zIndex: 1200, background: '#000000bd' }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <small>ORDER INV {memoData.id}</small>
                    <br />
                    {memoItem.product_title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-4">
                        <div className="user-orders-img">
                            <img
                                src={`https://www.optimizee.xyz/images/${btoa(
                                    memoItem[0].product_image
                                )},200,300.jpg`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="col-8">
                        Qty:{memoItem[0].quantity}
                        <br />
                        Price:{memoData.total_amount.toFixed(2)}
                        <br />
                        Discount:{memoData.total_product_discount.toFixed(2)}
                    </div>
                    <div className="col-12 mt-2">
                        <h6>Shipping Info</h6>
                        <table>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>: {memoData.address_shipping_name}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>: {memoData.address_shipping_email}</td>
                                </tr>
                                <tr>
                                    <td>Contact</td>
                                    <td>: {memoData.address_shipping_phone}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        : {memoData.address_shipping_json.line1},
                                        {memoData.address_shipping_json.line2}
                                    </td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td>: {memoData.address_shipping_json.city}</td>
                                </tr>
                                <tr>
                                    <td>State/Region</td>
                                    <td>: {memoData.address_shipping_json.state}</td>
                                </tr>
                                <tr>
                                    <td>Country</td>
                                    <td>: {memoData.address_shipping_json.country}</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12 mt-2">
                        <h6>Billing Info</h6>
                        <table>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>: {memoData.address_billing_name}</td>
                                </tr>
                                <tr>
                                    <td>Contact</td>
                                    <td>: {memoData.address_billing_phone}</td>
                                </tr>
                                <tr>
                                    <td>Contact</td>
                                    <td>: {memoData.address_billing_email}</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12 mt-2">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Comment
                        </Typography>
                        <TextField
                            required
                            label="Comment"
                            variant="outlined"
                            fullWidth
                            size="medium"
                            value={comment}
                            helperText="Please enter comment"
                            name="comment"
                            onChange={handleCommentChange}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleOrderConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
