import { useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export function deviceSize(theme) {
    const { keys, values } = theme.breakpoints;
    const windowWidth = window.innerWidth;

    for (let i = 0; i <= keys.length; i += 1) {
        const key = keys[i];
        const val = values[key];
        if (val > windowWidth) {
            return keys[i - 1];
        }
    }
    return keys[keys.length - 1];
}

export default function DetectDevice({ children }) {
    const theme = useTheme();
    const size = deviceSize(theme);

    const setResize = useState(0)[1];
    useEffect(() => {
        let timer;
        const onResize = (event) => {
            timer = setTimeout(() => {
                setResize(event.target.outerWidth);
            }, 200);
        };
        window.addEventListener('resize', onResize);
        return () => {
            // cleanup;
            window.removeEventListener('resize', onResize);
            clearTimeout(timer);
        };
    }, [setResize]);

    for (let i = 0; i <= children.length; i += 1) {
        const sizeAr = children[i]?.props?.type.split('|');
        if (sizeAr && sizeAr.indexOf(size) !== -1) {
            return children[i];
        }
    }

    return <></>;
}
