const initialState = {};

const ShopInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOP_INFO_SET': {
            return action.payload;
        }

        default:
            return state;
    }
};

export default ShopInfoReducer;
