import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function CustomSeparator({ options }) {
    const classes = useStyles();

    const elementAr = [
        <Link color="inherit" to="/" key="Breadcrumbs_Dashboard">
            Dashboard
        </Link>,
    ];

    if (options) {
        options.forEach((element) => {
            elementAr.push(
                <Link color="inherit" to={element.href} key={`Breadcrumbs_${element.title}`}>
                    {element.title}
                </Link>
            );
        });
    }

    return (
        <div className={classes.root}>
            <Breadcrumbs
                style={{ margin: 'none' }}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {elementAr.map((item) => item)}
            </Breadcrumbs>
        </div>
    );
}
