import React, { useEffect, useRef } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

const setPaymentConfig = (params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SETTING_PAYMENT_SET.replace(':shopId', params.shopId), {
            params,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function BkashInputConfig({ refAppKey, refAppSecret, refUsername, refPassword, refToken }) {
    return (
        <>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">App Key</Form.Label>
                <input ref={refAppKey} className="form-control" type="text" placeholder="App Key" />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">App Secret</Form.Label>
                <input
                    ref={refAppSecret}
                    className="form-control"
                    type="text"
                    placeholder="App Secret"
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">Username</Form.Label>
                <input
                    ref={refUsername}
                    className="form-control"
                    type="text"
                    placeholder="Username"
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">Password</Form.Label>
                <input
                    ref={refPassword}
                    className="form-control"
                    type="text"
                    placeholder="Password"
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">Token</Form.Label>
                <textarea
                    ref={refToken}
                    className="form-control"
                    type="text"
                    rows={5}
                    placeholder="Token"
                />
            </Form.Group>
        </>
    );
}
function BankInputConfig({ refGatewayName, refStoreId, refStorePassword, refApiUrl }) {
    return (
        <>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">Gateway Name</Form.Label>
                <input
                    ref={refGatewayName}
                    className="form-control"
                    type="text"
                    placeholder="Gateway Name"
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">Store ID</Form.Label>
                <input
                    ref={refStoreId}
                    className="form-control"
                    type="text"
                    placeholder="Store ID"
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">Store Password</Form.Label>
                <input
                    ref={refStorePassword}
                    className="form-control"
                    type="text"
                    placeholder="Store Password"
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                <Form.Label className="custom-label">Api URL</Form.Label>
                <input ref={refApiUrl} className="form-control" type="text" placeholder="Api URL" />
            </Form.Group>
        </>
    );
}
function PaymentConfigModal({
    show,
    setShow,
    shopId,
    mode,
    type,
    setPaymentConfigAr,
    title,
    configData,
}) {
    const refGatewayName = useRef();
    const refStoreId = useRef();
    const refStorePassword = useRef();
    const refApiUrl = useRef();

    const refAppKey = useRef();
    const refAppSecret = useRef();
    const refUsername = useRef();
    const refPassword = useRef();
    const refToken = useRef();

    const handlePaymentConfig = () => {
        setPaymentConfig({
            GatewayName: refGatewayName?.current?.value,
            StoreId: refStoreId?.current?.value,
            StorePassword: refStorePassword?.current?.value,
            ApiUrl: refApiUrl?.current?.value,
            AppKey: refAppKey?.current?.value,
            AppSecret: refAppSecret?.current?.value,
            Username: refUsername?.current?.value,
            Password: refPassword?.current?.value,
            Token: refToken?.current?.value,
            shopId,
            type,
            mode,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setShow(false);
                    setPaymentConfigAr(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        console.log(type);
        if (configData) {
            if (type === 'bkash') {
                refAppKey.current.value = configData?.AppKey;
                refAppSecret.current.value = configData?.AppSecret;
                refUsername.current.value = configData?.Username;
                refPassword.current.value = configData?.Password;
                refToken.current.value = configData?.Token;
            } else {
                refGatewayName.current.value = configData?.gateway_name;
                refStoreId.current.value = configData?.store_id;
                refStorePassword.current.value = configData?.store_password;
                refApiUrl.current.value = configData?.api_url;
            }
        }
    }, [configData, mode, title, type]);
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {title} Payment Config ({mode === 'production' ? 'Production' : 'Development'})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {type === 'bkash' ? (
                        <BkashInputConfig
                            refAppKey={refAppKey}
                            refAppSecret={refAppSecret}
                            refUsername={refUsername}
                            refPassword={refPassword}
                            refToken={refToken}
                        />
                    ) : (
                        <BankInputConfig
                            refGatewayName={refGatewayName}
                            refStoreId={refStoreId}
                            refStorePassword={refStorePassword}
                            refApiUrl={refApiUrl}
                        />
                    )}
                    <button
                        type="button"
                        onClick={handlePaymentConfig}
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                    >
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default PaymentConfigModal;
