/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';

const setGateWayConfig = ({ sender, apiId, apiKey, country, domain, priority, shopId }) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_SETTING_GATEWAY_SET.replace(':shopId', shopId), {
            sender,
            apiId,
            apiKey,
            country,
            domain,
            priority,
            shopId,
        })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function GatewayConfigModal({ show, setShow, shopId, gateWayAr, setGateWayAr }) {
    const refSender = useRef();
    const refApiKey = useRef();
    const refApiId = useRef();
    const refCountry = useRef();
    const refDomain = useRef();
    const refPriority = useRef();

    const handleTempConfig = () => {
        setGateWayConfig({
            sender: refSender.current.value,
            apiId: refApiId.current.value,
            apiKey: refApiKey.current.value,
            country: refCountry.current.value,
            domain: refDomain.current.value,
            priority: refPriority.current.value,
            shopId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setGateWayAr(response.data.configGetWay_ar);
                    setShow(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        if (show) {
            refSender.current.value = gateWayAr.sender;
            refApiKey.current.value = gateWayAr['api-key'];
            refApiId.current.value = gateWayAr['api-id'];
            refCountry.current.value = gateWayAr['country-code'];
            refDomain.current.value = gateWayAr.domain;
            refPriority.current.value = gateWayAr.priority;
        }
    }, [gateWayAr, show]);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            className="app-style2"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Config SMS Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Sender</Form.Label>
                        <input
                            ref={refSender}
                            className="form-control"
                            type="text"
                            placeholder="Set Sender"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">API ID</Form.Label>
                        <input
                            ref={refApiId}
                            className="form-control"
                            type="text"
                            placeholder="Set API ID"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">API KEY</Form.Label>
                        <input
                            ref={refApiKey}
                            className="form-control"
                            type="text"
                            placeholder="Set API KEY"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Country Code</Form.Label>
                        <input
                            ref={refCountry}
                            className="form-control"
                            type="text"
                            placeholder="Set Country Code"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Domain</Form.Label>
                        <input
                            ref={refDomain}
                            className="form-control"
                            type="text"
                            placeholder="Set Domain"
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">priority</Form.Label>
                        <input
                            ref={refPriority}
                            className="form-control"
                            type="text"
                            placeholder="Set Priority"
                        />
                    </Form.Group>
                    <button
                        type="button"
                        onClick={handleTempConfig}
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                    >
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default GatewayConfigModal;
