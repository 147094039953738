function BackdropOpen(isOpen) {
    return {
        type: 'SET_BACKDROP',
        payload: {
            isOpen,
        },
    };
}

export default function BackdropOpenDispatch(dispatch, isOpen) {
    dispatch(BackdropOpen(isOpen));
}
