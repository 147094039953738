import React, { useRef } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import AxiosAuth from '../../../utils/AxiosAuth';
import InitInfo from '../../../utils/InitInfo';

const createProductCategory = (dispatch, params) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(ConfigApi.API_CATEGORY_CREATE.replace(':shopId', params.shopId), params)
            .then((response) => {
                if (response.data.error === 0) {
                    console.log(response.data.message);
                }
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

function ProductCategoryCreateModal({ show, setCatShow, selectedCat, cateName }) {
    const dispatch = useDispatch();
    const { shopId } = InitInfo();

    const refCatName = useRef();

    const handleVariantPrice = () => {
        createProductCategory(dispatch, {
            productCat: refCatName.current.value,
            selectedCat,
            shopId,
        })
            .then((response) => {
                if (response.data.error === 0) {
                    setCatShow(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal
            show={show}
            onHide={() => setCatShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Category Manage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This category will be under {cateName} </p>
                <Form>
                    <Form.Group controlId="formBasicEmail" className="custom-login-input ">
                        <Form.Label className="custom-label">Category Name</Form.Label>
                        <input
                            ref={refCatName}
                            className="form-control"
                            type="text"
                            placeholder="Category Name"
                        />
                    </Form.Group>
                    <button
                        type="button"
                        onClick={handleVariantPrice}
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                    >
                        Save Info
                    </button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ProductCategoryCreateModal;
