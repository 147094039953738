import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ConfigApi from '../../../../configs/ConfigApi';
import SnackbarOpenDispatch from '../../../dispatches/SnackbarOpenDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        borderBottom: 'none',
    },

    image: {
        width: 64,
        height: 64,
        borderRadius: '10px',
        objectFit: 'cover',
        objectPosition: 'top',
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
    },
    tableRowHover: {
        '&:hover': {
            background: 'rgba(145, 158, 171, 0.08);',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};
function UserDataRow({ userData, shopId, roleList, setDeletedRole }) {
    const dispatch = useDispatch();

    const classes = useStyles();
    let serial = 0;

    const handleDelete = (ev) => {
        AxiosAuth.post(
            ConfigApi.API_SETTING_USER_REMOVE_ROLE.replace(':shopId', shopId).replace(
                ':userId',
                ev.user_sl
            ),
            {
                role: ev.role_title,
            }
        )
            .then((response) => {
                console.log(response);
                setDeletedRole(ev.role_title);
                handelSnackbarOpen(dispatch, response.data.message);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <TableBody>
            {userData.map((row) => {
                serial += 1;

                return (
                    <TableRow key={row.id} className={[classes.tableRowHover, 'tableTr'].join(' ')}>
                        <TableCell align="center">{serial}</TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.default_email}</TableCell>
                        <TableCell align="left">{row.default_contact}</TableCell>
                        <TableCell align="left">
                            {row?.roles?.map((rItem) => (
                                <Chip
                                    label={roleList[rItem.role_title]}
                                    onDelete={() => handleDelete(rItem)}
                                    color="primary"
                                    variant="outlined"
                                    key={rItem.role_title}
                                    style={{ marginRight: 5 }}
                                />
                            ))}
                        </TableCell>
                        <TableCell align="center">
                            <ButtonGroup size="small" aria-label="small button group">
                                <Button data-tip="Edit User">
                                    <Link to={`/${shopId}/settings/user/${row.id}/update/`}>
                                        <EditIcon />
                                    </Link>
                                </Button>

                                <Button data-tip="Role Change">
                                    <Link to={`/${shopId}/settings/user/${row.id}/update/role/`}>
                                        <PermIdentityIcon />
                                    </Link>
                                </Button>

                                <Button data-tip="Password Change">
                                    <Link
                                        to={`/${shopId}/settings/user/${row.id}/update/password/`}
                                    >
                                        <LockOpenIcon />
                                    </Link>
                                </Button>
                                <ReactTooltip />
                            </ButtonGroup>
                        </TableCell>
                    </TableRow>
                );
            })}{' '}
            <ReactTooltip />
        </TableBody>
    );
}

export default function SettingManageUserSection({ userData, shopId, roleList, setDeletedRole }) {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow className="tableTr">
                        <TableCell align="center" width="2%">
                            SL
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell width="8%" align="center">
                            Contact
                        </TableCell>
                        <TableCell width="8%" align="center">
                            Email
                        </TableCell>

                        <TableCell width="8%" align="center">
                            Role
                        </TableCell>

                        <TableCell width="8%" align="center">
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>

                <UserDataRow
                    userData={userData}
                    shopId={shopId}
                    roleList={roleList}
                    setDeletedRole={setDeletedRole}
                />
            </Table>
        </TableContainer>
    );
}
