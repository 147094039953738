import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function ShopModalUpdateMenu({ id }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <button
                type="button"
                className="shop-detail-more-menu"
                aria-label="delete"
                style={{ float: 'right' }}
                onClick={handleClick}
                color="primary"
            >
                <MoreVertIcon />
            </button>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/title/`}>Update Title </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/contact/`}>Update Contact</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/description/`}>Update Description</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/address/`}>Update Address</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/images/`}>Update Logo</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/banner/`}>Update Banner</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/social/`}>Update Social</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/locale/`}>Update Locale</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to={`/${id}/update/live/`}>Update Live</Link>
                </MenuItem>
            </Menu>
        </>
    );
}
