import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabList from '@material-ui/lab/TabList';
import React from 'react';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function ProductPropertyAppBar({ value, handleChange, classes }) {
    return (
        <AppBar position="static" style={{ background: '#fff', boxShadow: 'none' }}>
            <TabList
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab className={classes.tabStyle} label="Description" value="1" {...a11yProps(0)} />
                <Tab
                    className={classes.tabStyle}
                    label="Specification"
                    value="2"
                    {...a11yProps(1)}
                />
                <Tab className={classes.tabStyle} label="Reviews" value="3" {...a11yProps(2)} />
                <Tab
                    className={classes.tabStyle}
                    label="Variant Stock"
                    value="4"
                    {...a11yProps(3)}
                />
            </TabList>
        </AppBar>
    );
}
