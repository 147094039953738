function StatusText({ status }) {
    let text = '';
    if (status === 'processing') {
        text = 'Processing';
    }
    if (status === 'pending') {
        text = 'Pending';
    }
    if (status === 'prepared') {
        text = 'Prepared';
    }
    if (status === 'delivered') {
        text = 'Delivered';
    }
    if (status === 'shipped') {
        text = 'Shipped';
    }
    if (status === 'canceled') {
        text = 'Canceled';
    }
    if (status === 'refunded') {
        text = 'Refunded';
    }
    return text;
}
export default StatusText;
