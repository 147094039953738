import React from 'react';
import InitInfo from '../../../utils/InitInfo';
import ProductOverviewCard from './productdetails/ProductOverviewCard';
import ProductPropertyCard from './productdetails/ProductPropertyCard';

export default function ProductDetailsSection({ productDetails = {}, shopInfo = {} }) {
    const { categories } = InitInfo();
    const thumbImages = productDetails?.images || [];
    return (
        <>
            <ProductOverviewCard
                key={productDetails.id}
                productDetails={productDetails}
                shopInfo={shopInfo}
                categories={categories}
            />

            <ProductPropertyCard
                data={productDetails}
                categories={categories}
                thumbImages={thumbImages}
            />
        </>
    );
}
