import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import ProductCreateModal from '../modals/ProductCreateModal';

export default function ProductManageModals() {
    const location = useLocation();
    const { shopInfo } = InitInfo();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/products/manage/`}>
            <ProductCreateModal path={`/${shopInfo.id}/products/manage/create/`} />
            <div path={`/${shopInfo.id}/products/:id/update/category/`} />
            <div path={`/${shopInfo.id}/products/:id/update/sku/`} />
            <div path={`/${shopInfo.id}/products/:id/update/description/`} />
            <div path={`/${shopInfo.id}/products/:id/update/prices/`} />
            <div path={`/${shopInfo.id}/products/:id/update/offer-prices/`} />
            <div path={`/${shopInfo.id}/products/:id/update/images/`} />
            <div path={`/${shopInfo.id}/products/:id/update/variation/`} />
            <div path={`/${shopInfo.id}/products/:id/update/property/`} />
            <div path={`/${shopInfo.id}/products/:id/update/tag/`} />
        </StepHandler>
    );
}
