import React, { useState } from 'react';
import ProductCategoryCreateModal from '../modals/ProductCategoryCreateModal';

const MainMenuSubSub = ({ subBubItem }) => (
    <li className="link-area" key={4}>
        <button type="button" className="product-category-btn">
            {subBubItem.category_name}( {subBubItem.total_products})
        </button>
    </li>
);
const MainMenuSub = ({ subItem, menuData, handleCategoryCreate }) => {
    const [subItemClass, setSubItemClass] = useState(false);
    const isSubMenu = menuData?.filter((it) => it.parentId === subItem.categoryId).length;
    return (
        <li className="link-area" key={4}>
            {isSubMenu > 0 ? (
                <button
                    type="button"
                    className="product-category-btn"
                    onClick={() => {
                        setSubItemClass(!subItemClass);
                    }}
                >
                    {subItem.category_name} ( {subItem.total_products})
                </button>
            ) : (
                <button type="button" className="product-category-btn">
                    {subItem.category_name} ( {subItem.total_products})
                </button>
            )}
            <button
                type="button"
                className="product-category-btn"
                onClick={() => {
                    handleCategoryCreate(subItem.categoryId);
                }}
            >
                +
            </button>

            <ul style={{ display: subItemClass ? 'block' : 'none' }}>
                {menuData?.map((subBubItem) => {
                    if (subBubItem.parentId === subItem.categoryId) {
                        return (
                            <MainMenuSubSub
                                subBubItem={subBubItem}
                                menuData={menuData}
                                key={subBubItem.categoryId}
                                handleCategoryCreate={handleCategoryCreate}
                            />
                        );
                    }
                    return null;
                })}
            </ul>
        </li>
    );
};
const MainMenu = ({ mainItem, menuData, handleCategoryCreate }) => {
    const [itemClass, setitemClass] = useState(false);
    const isSubMenu = menuData?.filter((it) => it.parentId === mainItem.categoryId).length;
    return (
        <li className="link-area" key={4}>
            {isSubMenu > 0 ? (
                <button
                    type="button"
                    className="product-category-btn"
                    onClick={() => {
                        setitemClass(!itemClass);
                    }}
                >
                    {mainItem.category_name}( {mainItem.total_products})
                </button>
            ) : (
                <button type="button" className="product-category-btn">
                    {mainItem.category_name} ( {mainItem.total_products})
                </button>
            )}
            <button
                type="button"
                className="product-category-btn"
                onClick={() => {
                    handleCategoryCreate(mainItem.categoryId);
                }}
            >
                +
            </button>
            <ul style={{ display: itemClass ? 'block' : 'none' }}>
                {menuData?.map((subItem) => {
                    if (subItem.parentId === mainItem.categoryId) {
                        return (
                            <MainMenuSub
                                subItem={subItem}
                                menuData={menuData}
                                key={subItem.categoryId}
                                handleCategoryCreate={handleCategoryCreate}
                            />
                        );
                    }
                    return null;
                })}
            </ul>
        </li>
    );
};
export default function ProductManageCategorySection({ categoryList }) {
    const [catShow, setCatShow] = useState(false);
    const [selectedCat, setSelectedCat] = useState(false);

    const handleCategoryCreate = (ev) => {
        setCatShow(true);
        setSelectedCat(ev);
    };
    return (
        <>
            <div className="product-category">
                <ul>
                    {categoryList?.map((mainItem) => {
                        if (mainItem.parentId === 0) {
                            return (
                                <MainMenu
                                    mainItem={mainItem}
                                    menuData={categoryList}
                                    key={mainItem.categoryId}
                                    handleCategoryCreate={handleCategoryCreate}
                                />
                            );
                        }
                        return null;
                    })}
                </ul>
            </div>
            <ProductCategoryCreateModal
                show={catShow}
                setCatShow={setCatShow}
                selectedCat={selectedCat}
                cateName={
                    categoryList?.filter((ct) => ct.categoryId === selectedCat)[0]?.category_name
                }
            />
        </>
    );
}
