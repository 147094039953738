import SnackbarOpenDispatch from '../dispatches/SnackbarOpenDispatch';

// --Handler
const handelSnackbarOpen = (dispatch, message) => {
    SnackbarOpenDispatch(dispatch, message);
};

export default function DataErrors(response, dispatch) {
    if (response.data.error) {
        handelSnackbarOpen(dispatch, response.data.message);
    }
}
