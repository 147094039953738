import { Badge, Tooltip } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function LabelOneItem({ data, keyName, handleToggle, variants = [] }) {
    const labelId = `checkbox-list-label-${keyName}`;

    const titleWithBadge = (
        <Badge
            edge="start"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            badgeContent={variants[keyName]?.length}
            color="primary"
        >
            <span>{`${data[keyName]?.title}`}</span>
        </Badge>
    );

    return (
        <ListItem role={undefined} dense button onClick={handleToggle(keyName)}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={!!variants[keyName]}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                />
            </ListItemIcon>
            <ListItemText
                id={labelId}
                primary={titleWithBadge}
                primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '120%' } }}
            />
            <Badge
                edge="end"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                badgeContent={data[keyName]?.values?.length}
                color="secondary"
            />
        </ListItem>
    );
}

function LabelTwoList({ open, classes, variants, keyName, handleToggleSub, data }) {
    const labelId = `checkbox-list-label-${keyName}`;
    if (keyName === 'color') {
        return (
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className="colors">
                    {data.map((item) => (
                        <ColorsItem
                            labelId={labelId}
                            handleToggleSub={handleToggleSub}
                            item={item}
                            key={item}
                            variants={variants}
                            keyName={keyName}
                        />
                    ))}
                </div>
            </Collapse>
        );
    }

    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding dense>
                {data.map((item) => (
                    <LabelTwoItem
                        classes={classes}
                        labelId={labelId}
                        handleToggleSub={handleToggleSub}
                        item={item}
                        key={item}
                        variants={variants}
                        keyName={keyName}
                    />
                ))}
            </List>
        </Collapse>
    );
}

function LabelTwoItem({ classes, variants, labelId, keyName, handleToggleSub, item }) {
    return (
        <ListItem button className={classes.nested} onClick={handleToggleSub(keyName, item)}>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={variants[keyName] ? variants[keyName].indexOf(item) !== -1 : false}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                />
            </ListItemIcon>
            <ListItemText primary={item} />
        </ListItem>
    );
}

function ColorsItem({ variants, keyName, handleToggleSub, item }) {
    const checked = variants[keyName] ? variants[keyName]?.indexOf(item) !== -1 : false;

    return (
        <Tooltip title={item}>
            <div className={['color-item', checked ? 'checked' : ''].join(' ')}>
                <button
                    type="button"
                    style={{ backgroundColor: item }}
                    onClick={handleToggleSub(keyName, item)}
                />
            </div>
        </Tooltip>
    );
}

export default function VariationOption({ data, handleToggle, handleToggleSub, variants }) {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {Object.keys(data).map((keyName) => (
                <div key={keyName}>
                    <LabelOneItem
                        data={data}
                        keyName={keyName}
                        handleToggle={handleToggle}
                        variants={variants}
                    />
                    {data[keyName] && variants && (
                        <LabelTwoList
                            keyName={keyName}
                            open={!!variants[keyName]}
                            classes={classes}
                            data={data[keyName].values}
                            handleToggleSub={handleToggleSub}
                            variants={variants}
                        />
                    )}
                </div>
            ))}
        </List>
    );
}
