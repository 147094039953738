import Chip from '@material-ui/core/Chip';
import React from 'react';
import { Button } from 'react-bootstrap';
import {
    FacebookShareButton,
    InstapaperShareButton,
    PinterestShareButton,
    // eslint-disable-next-line prettier/prettier
    TwitterShareButton
} from 'react-share';
import ExternalIcon from '../../../../../assets/icons/arrow-up-right-from-square-solid.svg';
import ProductModalUpdateMenu from '../../modals/comp/ProductModalUpdateMenu';

function ProductSize({ size }) {
    return (
        <Button variant="outline-info" className="product-detail-sizeBtn">
            {size}
        </Button>
    );
}
function ProductAgeRange({ age }) {
    return (
        <Button variant="outline-info" className="product-detail-ageBtn">
            {age}
        </Button>
    );
}
function ProductColor({ color }) {
    return (
        <Button style={{ background: color, border: 'none' }} className="product-detail-colorBtn" />
    );
}

function ShowProductDetailsHeader({ productDetails = {}, shopInfo }) {
    return (
        <div className="product-detail-header">
            <strong className="product-detail-title">
                {productDetails.title}
                <ProductModalUpdateMenu productId={productDetails.id} shopId={shopInfo.id} />
            </strong>

            <div className="ProductDetailBorder" />
        </div>
    );
}

function ShowCategories({ productDetails = {}, productCategoryList = [] }) {
    return (
        <div className="product-detail-price">
            <h6>Category :</h6>

            {productDetails.categories
                ? productDetails.categories.map((cat) => (
                      <Chip
                          label={
                              productCategoryList
                                  .filter((e) => e.categoryId === cat)
                                  .map((en) => en.view)[0]
                          }
                          color="secondary"
                          size="small"
                          variant="outlined"
                          className="chip-style-category"
                          style={{ margin: '5px' }}
                          key={cat}
                      />
                  ))
                : 'Not Set'}
        </div>
    );
}

function ShowPrices({ productDetails = {} }) {
    return (
        <div className="product-detail-price">
            <h6>Price :</h6>
            <strong>৳ {productDetails.priceOffer.toFixed(2)}</strong>
            {productDetails.price !== productDetails.priceOffer ? (
                <span>৳ {productDetails.price.toFixed(2)}</span>
            ) : (
                ''
            )}
        </div>
    );
}

function ProductVariant({ productDetails = {} }) {
    return (
        productDetails &&
        Object.keys(productDetails).map((items) => (
            <div key={items}>
                {items === 'color' && (
                    <div className="product-detail-color">
                        <h6 className="product-detail-size-title">Color:</h6>
                        {productDetails[items].map((item) => (
                            <ProductColor color={item} key={item} />
                        ))}
                    </div>
                )}
                {items === 'ageRange' && (
                    <div className="product-detail-age">
                        <h6 className="product-detail-size-title">Age:</h6>
                        {productDetails[items].map((item) => (
                            <ProductAgeRange age={item} key={item} />
                        ))}
                    </div>
                )}
                {items === 'size' && (
                    <div className="product-detail-size">
                        <h6 className="product-detail-size-title">Size:</h6>
                        {productDetails[items].map((item) => (
                            <ProductSize size={item} key={item} />
                        ))}
                    </div>
                )}
            </div>
        ))
    );
}

function ShowShareButtons({ articleLink }) {
    return (
        <div className="product-detail-social-link">
            <ul>
                <li>
                    <FacebookShareButton url={articleLink} className="detail-share-fb" />
                </li>
                <li>
                    <TwitterShareButton url={articleLink} className="detail-share-tw" />
                </li>
                <li>
                    <PinterestShareButton url={articleLink} className="detail-share-pin" />
                </li>
                <li>
                    <InstapaperShareButton url={articleLink} className="detail-share-ins" />
                </li>
            </ul>
        </div>
    );
}

// function ShowShareButtons() {
//     return (
//         <div className="product-detail-social-link">
//             <FacebookIcon className="link" />
//             <InstagramIcon className="link" />
//             <TwitterIcon className="link" />
//             <LinkedInIcon className="link" />
//         </div>
//     );
// }

export default function ProductOverviewInfo({ productDetails = {}, shopInfo = [], categories }) {
    return (
        <>
            <ShowProductDetailsHeader productDetails={productDetails} shopInfo={shopInfo} />

            <ShowCategories productDetails={productDetails} productCategoryList={categories} />

            <ShowPrices productDetails={productDetails} />

            <ProductVariant productDetails={productDetails.variation_header} />
            <div className="product-detail-social-link">
                <a
                    target="_blank"
                    href={`https://${shopInfo?.domain_ids}/products/${productDetails.id}/${productDetails?.title}`}
                    rel="noreferrer"
                >
                    <img src={ExternalIcon} style={{ width: 20, marginRight: 10 }} alt="" />
                    <b style={{ marginTop: 3, position: 'absolute' }}>View Live</b>
                </a>
            </div>
            <ShowShareButtons
                articleLink={`https://${shopInfo?.domain_ids}/products/${productDetails.id}/${productDetails?.title}`}
            />
            {/* <ShowShareButtons /> */}
            {/* {`https://${shopInfo?.domain_ids}/products/${productDetails.id}/${productDetails?.title}`} */}
        </>
    );
}
