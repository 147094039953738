import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AppBarSection from '../../../components/section/AppBarSection';
import MainSection from '../../../components/section/MainSection';
import NavigationDrawerSection from '../../../components/section/NavigationDrawerSection';
import AppInitDispatch from '../../../dispatches/AppInitDispatch';
import InitInfo from '../../../utils/InitInfo';
import Preview from '../../../utils/Preview';
import SettingManagePermissionSection from '../section/SettingManagePermissionSection';
import SettingPermissionHeaderSection from '../section/SettingPermissionHeaderSection';
import UserModals from '../section/UserModals';
import UserUpdateModals from '../section/UserUpdateModals';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

async function initData(dispatch, shopId) {
    try {
        await AppInitDispatch(dispatch, shopId);
    } catch (err) {
        console.log(err);
    }
}
export default function SettingManagePermissionPage() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { show, shopId, shopInfo } = InitInfo();

    // --Page Build
    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId);
    }, [dispatch, shopId]);

    return (
        <Preview placeholderModel="table" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />

                <NavigationDrawerSection shopInfo={shopInfo} />
                <MainSection>
                    <SettingPermissionHeaderSection shopId={shopId} />
                    <SettingManagePermissionSection />
                </MainSection>
            </div>
            <UserModals />
            <UserUpdateModals />
        </Preview>
    );
}
