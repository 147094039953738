import React from 'react';
import { useLocation } from 'react-router-dom';
import InitInfo from '../../../utils/InitInfo';
import StepHandler from '../../../utils/StepHandler';
import CustomerCreateModal from '../modals/CustomerCreateModal';

export default function CustomerModals() {
    const location = useLocation();
    const { shopInfo } = InitInfo();

    return (
        <StepHandler pathname={location.pathname} exiturl={`/${shopInfo.id}/customers/manage/`}>
            <CustomerCreateModal path={`/${shopInfo.id}/customers/manage/create/`} />
        </StepHandler>
    );
}
