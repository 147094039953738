import { Card, CardContent } from '@material-ui/core';
import React, { useState } from 'react';
import placeHolderImage from '../../../../../assets/images/placeholder-image.png';
import ProductOverviewImages from './ProductOverviewImages';
import ProductOverviewInfo from './ProductOverviewInfo';

export default function ProductOverviewCard({ productDetails = {}, shopInfo = {}, categories }) {
    const thumbImages = productDetails?.images || [];
    const [thumbImg, setThumbImg] = useState(
        productDetails?.default_image ? productDetails.default_image : placeHolderImage
    );

    const handleThumbImage = (img) => {
        setThumbImg(img);
    };

    return (
        <Card style={{ borderRadius: '16px' }} variant="outlined">
            <CardContent>
                <div className="row">
                    <div className="col-12 col-lg-5">
                        <ProductOverviewImages
                            thumbImg={thumbImg}
                            thumbImages={thumbImages}
                            handleThumbImage={handleThumbImage}
                        />
                    </div>
                    <div className="col-12 col-lg-7">
                        <ProductOverviewInfo
                            productDetails={productDetails}
                            shopInfo={shopInfo}
                            categories={categories}
                        />
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
