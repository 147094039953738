/* eslint-disable no-unreachable */
import ConfigApi from '../../configs/ConfigApi';
import store from '../store';
import AxiosAuth from '../utils/AxiosAuth';

function initSet(payload) {
    return {
        type: 'INIT_SET',
        payload,
    };
}

function shopInitSet(payload) {
    return {
        type: 'SHOP_INIT_SET',
        payload,
    };
}

function userInfoInitSet(payload) {
    return {
        type: 'USER_INIT_SET',
        payload,
    };
}
function userLoginInitSet(payload) {
    return {
        type: 'LOGIN_INIT_SET',
        payload,
    };
}
function userAddressInitSet(payload) {
    return {
        type: 'ADDRESS_INIT_SET',
        payload,
    };
}
function cartProductInitSet(payload) {
    return {
        type: 'CART_INIT_SET',
        payload,
    };
}

function rewardInitSet(payload) {
    return {
        type: 'REWARD_INFO_SET',
        payload,
    };
}

export default function AppInitDispatch(dispatch, shopId) {
    return new Promise((resolve, reject) => {
        if (!store.getState().initData?.initId) {
            AxiosAuth.get(ConfigApi.API_APP_INIT.replace(':shopId', shopId), {
                params: {
                    shopId: process.env.REACT_APP_SHOP_ID,
                    initId: localStorage.getItem('init-id'),
                },
            })
                .then((response) => {
                    if (response.data.isLoggedIn === true) {
                        dispatch(initSet(response.data.initData));
                        localStorage.setItem('init-id', response.data.initData.initId);

                        dispatch(shopInitSet(response.data.shopData));
                        dispatch(userLoginInitSet(response.data.isLoggedIn));
                        dispatch(userInfoInitSet(response.data.userInformation));
                        dispatch(userAddressInitSet(response.data.userAddresses));
                        dispatch(cartProductInitSet(response.data.cartProducts));
                        dispatch(rewardInitSet(response.data.rewardInfo));

                        resolve(true);
                    } else {
                        reject(response);
                    }
                    // Init Info
                })
                .catch((err) => {
                    // console.log(err);
                    reject(err);
                });
            // eslint-disable-next-line no-unreachable
        } else {
            resolve(true);
        }
    });
}
