import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../assets/css/store-select-section.css';
import ConfigApi from '../../../../configs/ConfigApi';
import RewardDataDispatch from '../../../dispatches/RewardDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import DataErrors from '../../../utils/DataErrors';
import DetectDevice from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import StockProductListDesktopView from './StockProductListDesktopView';
import StockProductListMobileView from './StockProductListMobileView';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    rootTwo: {
        flexGrow: 1,
    },
    nested: {
        paddingLeft: theme.spacing(7),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    tableContainer: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgb(33, 43, 54)',
        transition: ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        overflow: 'hidden',
        boxShadow:
            'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px',
        borderRadius: '16px',
        position: 'relative',
        zIndex: 0,
        marginTop: 15,
        padding: 10,
    },
}));

const updateRewardActive = (params, callback) => {
    AxiosAuth.post(ConfigApi.API_REWARD_ACTIVE.replace(':shopId', params.shopId), params)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

const getProductCategory = (params, callback) => {
    AxiosAuth.get(
        ConfigApi.API_STOCK_PRODUCT_CATEGORY.replace(':shopId', params.shopId).replace(
            ':categoryId',
            params.categoryId
        ),
        params
    )
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default function StockManageSection({ rewardproductData, productData, categoryData }) {
    const classes = useStyles();
    const { shopId, shopInfo, shopData } = InitInfo();
    const dispatch = useDispatch();
    const rewardInfo = useSelector((state) => state.rewardInfo);
    const [rewardStatus, setRewardStatus] = useState(rewardInfo.is_active);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [productCategory, setProductCategory] = useState([]);

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState(false);

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(!isCheck);
    };
    const handleSelect = () => {
        setIsCheck(!isCheck);
    };

    const handleProducts = (event, newValue) => {
        setCategoryId(newValue ? newValue.categoryId : '');
        setCategoryList(categoryData.filter((e) => e.parentId === categoryId));
    };
    const handleProductsThree = (event, newValue) => {
        setCategoryId(newValue ? newValue.categoryId : '');
        setCategoryList(categoryData.filter((e) => e.parentId === categoryId));
        getProductCategory({ shopId, categoryId: newValue?.categoryId }, (response) => {
            setProductCategory(response.data);
        });
    };
    const handleChange = () => {
        // console.dir(next);
        updateRewardActive({ shopId, rewardStatus: !rewardStatus }, (response) => {
            DataErrors(response, dispatch);
            if (response.data.error === 0) {
                // --Update Store
                RewardDataDispatch(dispatch, {
                    is_active: !rewardStatus,
                    percent: rewardInfo.percent,
                    exp_date: rewardInfo.exp_date,
                });
            }
        });
        setRewardStatus(!rewardStatus);
    };
    useEffect(() => {
        // Collect API Data
        setCategoryList(categoryData.filter((e) => e.parentId === categoryId));
    }, [categoryData, categoryId, dispatch, productData, shopId]);

    return (
        <div className={classes.rootTwo}>
            <DetectDevice>
                <div type="lg" className={classes.tableContainer}>
                    <Autocomplete
                        key="01"
                        options={categoryList}
                        getOptionLabel={(option) => option.category_name}
                        getOptionSelected={(option) => option.categoryId}
                        fullWidth
                        selectOnFocus={false}
                        clearOnBlur
                        onChange={handleProducts}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value=""
                                size="small"
                                required
                                label="Select Main Category"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    <Autocomplete
                        key="02"
                        options={categoryList}
                        getOptionLabel={(option) => option.category_name}
                        getOptionSelected={(option) => option.categoryId}
                        fullWidth
                        selectOnFocus={false}
                        clearOnBlur
                        onChange={handleProducts}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value=""
                                size="small"
                                required
                                label="Select Sub Category"
                                variant="outlined"
                                fullWidth
                                className="mt-3"
                            />
                        )}
                    />
                    <Autocomplete
                        key="03"
                        options={categoryList}
                        getOptionLabel={(option) => option.category_name}
                        getOptionSelected={(option) => option.categoryId}
                        fullWidth
                        selectOnFocus={false}
                        clearOnBlur
                        onChange={handleProductsThree}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value=""
                                size="small"
                                required
                                label="Select Sub Sub Category"
                                variant="outlined"
                                fullWidth
                                className="mt-3 mb-3"
                            />
                        )}
                    />
                    <StockProductListDesktopView
                        productData={productData}
                        rewardproductData={rewardproductData}
                        categoryList={shopData.categories}
                        shopInfo={shopInfo}
                        handleChange={handleChange}
                        productCategory={productCategory}
                        handleSelectAll={handleSelectAll}
                        isCheckAll={isCheckAll}
                        isCheck={isCheck}
                        handleSelect={handleSelect}
                    />
                </div>

                <div className="row" type="xs|sm" style={{ marginTop: 10 }}>
                    <StockProductListMobileView
                        productData={productData}
                        categoryList={shopData.categories}
                        rewardproductData={rewardproductData}
                        shopInfo={shopInfo}
                    />
                </div>
            </DetectDevice>
        </div>
    );
}
