import { makeStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import SettingManageAccountPage from '../app/settings/pages/SettingManageAccountPage';
import SettingManagePasswordPage from '../app/settings/pages/SettingManagePasswordPage';
import AppBarSection from '../components/section/AppBarSection';
import MainSection from '../components/section/MainSection';
import NavigationDrawerSection from '../components/section/NavigationDrawerSection';
import AppInitDispatch from '../dispatches/AppInitDispatch';
import InitInfo from '../utils/InitInfo';
import Preview from '../utils/Preview';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
}));

async function initData(dispatch, shopId, setInitOk) {
    try {
        const st = await AppInitDispatch(dispatch, shopId);
        setInitOk(st);
    } catch (err) {
        console.log(err);
    }
}

export default function UserRoutes() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const setInitOk = useState(false)[1];
    const { show, shopId, shopInfo } = InitInfo();

    useEffect(() => {
        // Collect API Data
        initData(dispatch, shopId, setInitOk);
    }, [dispatch, setInitOk, shopId]);
    return (
        <Preview placeholderModel="app-init" show={show}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBarSection shopInfo={shopInfo} />
                <NavigationDrawerSection shopInfo={shopInfo} />

                <MainSection>
                    <Switch>
                        <Route path="/user/info" exact>
                            <SettingManageAccountPage />
                        </Route>
                        <Route path="/user/change-password" exact>
                            <SettingManagePasswordPage />
                        </Route>
                    </Switch>
                </MainSection>
            </div>
        </Preview>
    );
}
