import { TextField, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../../assets/css/modal-app-style.css';
import ConfigApi from '../../../../configs/ConfigApi';
import { ProductDataUpdateDispatch } from '../../../dispatches/ProductDataDispatch';
import AxiosAuth from '../../../utils/AxiosAuth';
import { deviceSize } from '../../../utils/DetectDevice';
import InitInfo from '../../../utils/InitInfo';
import { ProductModalFooter, ProductModalHeader } from './comp/ProductModalHeaderFooter';
import VariationOption from './comp/VariationOption';

const updatingVariantProduct = (dispatch, params, productId) =>
    new Promise((resolve, reject) => {
        AxiosAuth.post(
            ConfigApi.API_PRODUCT_VARIATION_HEAD_UPDATE.replace(':productId', productId),
            params
        )
            .then((response) => {
                if (response.data.error === 0) {
                    ProductDataUpdateDispatch(dispatch, {
                        variation_header: params.productVariant,
                    });
                }

                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });

const filter = (variationList, target, setVariationListFiltered) => {
    const newList = {};
    Object.keys(variationList).map((key) => {
        const newArray = [];
        newList[key] = {};
        for (let i = 0; i < variationList[key].values.length; i += 1) {
            if (variationList[key].values[i].toLowerCase().includes(target.value.toLowerCase())) {
                newArray.push(variationList[key].values[i]);
            }
        }

        newList[key].title = variationList[key].title;
        newList[key].values = newArray;
        return newArray;
    });

    setVariationListFiltered(newList);
};

export default function ProductVariationModal(props) {
    const { prev, next, step, paths, exiturl } = props;

    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { shopInfo, productDetails, productId, shopData } = InitInfo();
    const deviceClassName = `device-${deviceSize(theme)}`;

    const [variationList] = useState(shopData.shopVariation);
    const [variationListFiltered, setVariationListFiltered] = useState(shopData.shopVariation);
    const [variants, setVariants] = useState(productDetails?.variation_header || []);

    const handleSearch = (event) => {
        filter(variationList, event.target, setVariationListFiltered);
    };

    const handleToggle = (value) => () => {
        const newVariants = { ...variants };

        if (!variants[value]) {
            newVariants[value] = [];
        } else {
            delete newVariants[value];
        }

        setVariants(newVariants);
    };

    const handleToggleSub = (key, value) => () => {
        const currentIndex = variants[key]?.indexOf(value);
        const newVariants = { ...variants };

        if (currentIndex === -1) {
            newVariants[key].push(value);
        } else {
            newVariants[key].splice(currentIndex, 1);
        }

        setVariants(newVariants);
    };

    const handleNextClick = () => {
        updatingVariantProduct(
            dispatch,
            { productVariant: variants, shopId: shopInfo.id },
            productId
        )
            .then((response) => {
                if (response.data.error === 0) {
                    history.push(next);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // useEffect(() => {
    //     getVariationList({ shopId })
    //         .then((response) => {
    //             setVariationList(response.data);
    //             setVariationListFiltered(response.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }, [dispatch, productId, shopId, variants]);

    return (
        <Modal
            className={['app-style', deviceClassName].join(' ')}
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-variant"
            centered
            scrollable
        >
            <Modal.Body>
                <div className="app-style-content header-footer-fixed">
                    <ProductModalHeader
                        prev={prev}
                        step={step}
                        exiturl={exiturl}
                        title={productDetails.title}
                    />
                    <div className="app-style-body">
                        <Typography className="mb-3 mt-2" variant="h6">
                            Set Variation
                        </Typography>

                        <TextField
                            label="Search Variation"
                            variant="outlined"
                            fullWidth
                            type="text"
                            helperText="Search for Variation"
                            onChange={handleSearch}
                            size="small"
                        />

                        {Object.keys(variationListFiltered).length ? (
                            <VariationOption
                                data={variationListFiltered}
                                handleToggle={handleToggle}
                                handleToggleSub={handleToggleSub}
                                variants={variants}
                            />
                        ) : null}
                    </div>

                    <ProductModalFooter
                        next={next}
                        paths={paths}
                        step={step}
                        data={[variants]}
                        handleNextClick={handleNextClick}
                        skip
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}
