const StatusData = [
    'pending',
    'processing',
    'prepared',
    'shipped',
    'delivered',
    'canceled',
    'refunded',
];

export default StatusData;
